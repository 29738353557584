import { CalendarModeRangeType } from "../../HVDatePicker.props";
import { CALENDAR_MODE_RANGE_TYPE } from "../constants/constants";

export const locale = "en-US";

export const createDateTime = (
  date: Date,
  hour: number,
  minute: number,
  second: number,
  period: string,
  timeZone: string,
) => {
  let hoursIn24Format;
  if (period === "PM" && hour < 12) {
    hoursIn24Format = hour + 12;
  } else if (period === "AM" && hour === 12) {
    hoursIn24Format = 0;
  } else {
    hoursIn24Format = hour;
  }

  const dateTime = new Date(date);
  dateTime.setHours(hoursIn24Format, minute, second, 0);

  const formatter = new Intl.DateTimeFormat(locale, {
    timeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  const parts = formatter.formatToParts(dateTime);
  const dateTimeString = parts.reduce(
    (acc, part) => {
      if (part.type !== "literal") {
        acc[part.type] = part.value;
      }
      return acc;
    },
    {} as Record<string, string>,
  );

  return new Date(
    `${dateTimeString.year}-${dateTimeString.month}-${dateTimeString.day}T${dateTimeString.hour}:${dateTimeString.minute}:${dateTimeString.second}`,
  ).toISOString();
};

export const formatMinuteSecond = (minSec: number) =>
  minSec < 10 ? `0${minSec}` : minSec.toString();

export const formatMode = (string: string) => {
  return string
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/^\w/, (c) => c.toUpperCase());
};

export const getDateTime = (
  mode: CalendarModeRangeType,
  start: string,
  end?: string,
): {
  start: string;
  end: string;
  comparisonStart: string;
  comparisonEnd: string;
} => {
  const startDate = new Date(start);
  const endDate = end ? new Date(end) : new Date(start);
  const period = Math.floor(
    (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
  );

  const formatDate = (date: Date, timeZone: string) => {
    const formatter = new Intl.DateTimeFormat(locale, {
      timeZone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    const parts = formatter.formatToParts(date);
    const dateTimeString = parts.reduce(
      (acc, part) => {
        if (part.type !== "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      },
      {} as Record<string, string>,
    );

    return new Date(
      `${dateTimeString.year}-${dateTimeString.month}-${dateTimeString.day}T${dateTimeString.hour}:${dateTimeString.minute}:${dateTimeString.second}`,
    ).toISOString();
  };

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  switch (mode) {
    case CALENDAR_MODE_RANGE_TYPE.LAST_24_HOURS:
      return {
        start: formatDate(new Date(Date.now() - 24 * 60 * 60 * 1000), timeZone),
        end: formatDate(new Date(), timeZone),
        comparisonStart: formatDate(
          new Date(Date.now() - 72 * 60 * 60 * 1000),
          timeZone,
        ),
        comparisonEnd: formatDate(
          new Date(Date.now() - 48 * 60 * 60 * 1000),
          timeZone,
        ),
      };
    case CALENDAR_MODE_RANGE_TYPE.LAST_7_DAYS:
      return {
        start: formatDate(
          new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
          timeZone,
        ),
        end: formatDate(new Date(), timeZone),
        comparisonStart: formatDate(
          new Date(Date.now() - 13 * 24 * 60 * 60 * 1000),
          timeZone,
        ),
        comparisonEnd: formatDate(
          new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
          timeZone,
        ),
      };
    case CALENDAR_MODE_RANGE_TYPE.LAST_30_DAYS:
      return {
        start: formatDate(
          new Date(Date.now() - 29 * 24 * 60 * 60 * 1000),
          timeZone,
        ),
        end: formatDate(new Date(), timeZone),
        comparisonStart: formatDate(
          new Date(Date.now() - 59 * 24 * 60 * 60 * 1000),
          timeZone,
        ),
        comparisonEnd: formatDate(
          new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
          timeZone,
        ),
      };
    case CALENDAR_MODE_RANGE_TYPE.LAST_90_DAYS:
      return {
        start: formatDate(
          new Date(Date.now() - 89 * 24 * 60 * 60 * 1000),
          timeZone,
        ),
        end: formatDate(new Date(), timeZone),
        comparisonStart: formatDate(
          new Date(Date.now() - 179 * 24 * 60 * 60 * 1000),
          timeZone,
        ),
        comparisonEnd: formatDate(
          new Date(Date.now() - 90 * 24 * 60 * 60 * 1000),
          timeZone,
        ),
      };
    case CALENDAR_MODE_RANGE_TYPE.LAST_180_DAYS:
      return {
        start: formatDate(
          new Date(Date.now() - 179 * 24 * 60 * 60 * 1000),
          timeZone,
        ),
        end: formatDate(new Date(), timeZone),
        comparisonStart: formatDate(
          new Date(Date.now() - 359 * 24 * 60 * 60 * 1000),
          timeZone,
        ),
        comparisonEnd: formatDate(
          new Date(Date.now() - 180 * 24 * 60 * 60 * 1000),
          timeZone,
        ),
      };
    case CALENDAR_MODE_RANGE_TYPE.LAST_365_DAYS:
      return {
        start: formatDate(
          new Date(Date.now() - 364 * 24 * 60 * 60 * 1000),
          timeZone,
        ),
        end: formatDate(new Date(), timeZone),
        comparisonStart: formatDate(
          new Date(Date.now() - 729 * 24 * 60 * 60 * 1000),
          timeZone,
        ),
        comparisonEnd: formatDate(
          new Date(Date.now() - 365 * 24 * 60 * 60 * 1000),
          timeZone,
        ),
      };
    case CALENDAR_MODE_RANGE_TYPE.YESTERDAY:
      return {
        start: formatDate(new Date(Date.now() - 24 * 60 * 60 * 1000), timeZone),
        end: formatDate(new Date(Date.now() - 24 * 60 * 60 * 1000), timeZone),
        comparisonStart: formatDate(
          new Date(Date.now() - 48 * 60 * 60 * 1000),
          timeZone,
        ),
        comparisonEnd: formatDate(
          new Date(Date.now() - 48 * 60 * 60 * 1000),
          timeZone,
        ),
      };
    case CALENDAR_MODE_RANGE_TYPE.DAY_BEFORE_YESTERDAY:
      return {
        start: formatDate(new Date(Date.now() - 48 * 60 * 60 * 1000), timeZone),
        end: formatDate(new Date(Date.now() - 48 * 60 * 60 * 1000), timeZone),
        comparisonStart: formatDate(
          new Date(Date.now() - 72 * 60 * 60 * 1000),
          timeZone,
        ),
        comparisonEnd: formatDate(
          new Date(Date.now() - 72 * 60 * 60 * 1000),
          timeZone,
        ),
      };
    case CALENDAR_MODE_RANGE_TYPE.CUSTOM:
      return {
        start: formatDate(startDate, timeZone),
        end: formatDate(endDate, timeZone),
        comparisonStart: formatDate(
          new Date(
            startDate.getTime() - (period * 2 + 1) * 24 * 60 * 60 * 1000,
          ),
          timeZone,
        ),
        comparisonEnd: formatDate(
          new Date(startDate.getTime() - (period + 1) * 24 * 60 * 60 * 1000),
          timeZone,
        ),
      };
    default:
      return {
        start: formatDate(new Date(), timeZone),
        end: formatDate(new Date(), timeZone),
        comparisonStart: formatDate(
          new Date(Date.now() - 24 * 60 * 60 * 1000),
          timeZone,
        ),
        comparisonEnd: formatDate(
          new Date(Date.now() - 24 * 60 * 60 * 1000),
          timeZone,
        ),
      };
  }
};

export const classNames = (data: { [key: string]: boolean | undefined }) => {
  return Object.keys(data)
    .filter((key: string) => data[key])
    .join(" ");
};

export const formatDate = (date: Date, format: string) => {
  const options: Intl.DateTimeFormatOptions = {};
  if (format.includes("YYYY")) {
    options.year = "numeric";
  }
  if (format.includes("MM")) {
    options.month = "2-digit";
  }
  if (format.includes("DD")) {
    options.day = "2-digit";
  }
  return new Intl.DateTimeFormat(locale, options).format(date);
};

export const formatMonth = (date: Date) => {
  const formatter = new Intl.DateTimeFormat(locale, { month: "short" });
  return formatter.format(date);
};

export const startOf = (
  date: Date,
  type: "YEAR" | "MONTH" | "DAY" | "HOUR",
): Date => {
  switch (type) {
    case "YEAR":
      return new Date(date.getFullYear(), 0, 1, 0, 0, 0, 0);
    case "MONTH":
      return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
    case "DAY":
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0,
        0,
        0,
        0,
      );
    case "HOUR":
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        0,
        0,
        0,
      );
    default:
      throw new Error(`Unsupported type: ${type}`);
  }
};

export const endOf = (
  date: Date,
  type: "YEAR" | "MONTH" | "DAY" | "HOUR",
): Date => {
  switch (type) {
    case "YEAR":
      return new Date(date.getFullYear(), 11, 31, 23, 59, 59, 999);
    case "MONTH":
      return new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0,
        23,
        59,
        59,
        999,
      );
    case "DAY":
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59,
        59,
        999,
      );
    case "HOUR":
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        59,
        59,
        999,
      );
    default:
      throw new Error(`Unsupported type: ${type}`);
  }
};

export const isSame = (
  date1: Date,
  date2: Date,
  type: "YEAR" | "MONTH" | "DAY" | "HOUR",
) => {
  switch (type) {
    case "YEAR":
      return date1.getFullYear() === date2.getFullYear();
    case "MONTH":
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth()
      );
    case "DAY":
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    case "HOUR":
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate() &&
        date1.getHours() === date2.getHours()
      );
    default:
      return false;
  }
};

export const toDateWithTimezone = (
  timezone: string,
  date: Date,
  format?: Intl.DateTimeFormatOptions,
) => {
  if (format) {
    return new Intl.DateTimeFormat(locale, {
      timeZone: timezone,
      ...format,
    }).format(date);
  }

  return new Intl.DateTimeFormat(locale, {
    timeZone: timezone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(date);
};
