import { useMemo, useState } from "react";

import {
  DataTreeViewItemValue,
  FlatNodeItem
} from "@components/common/tree-view/Interfaces";
import _ from "lodash";
import useOrganization from "@hooks/useOrganization";
import { useGetPortalDevicesQuery } from "@queries/tanstack/device/DeviceQuery";
import { useLang } from "@hooks/useLang";
import { HVIcon } from "@repo/HVComponents";
import * as S from "../TopMenu.styled";
import { AnalyticsRuleLayer } from "../../rule/AnalyticsRuleLayer";
import { ICON } from "../../../../constants/icons";

interface RuleButtonProps {
  defaultRules: string[];
  onChange: (rule: FlatNodeItem[]) => void;
}
export const RuleButton = (props: RuleButtonProps) => {
  const { translate } = useLang();

  const [checkedRules, setCheckedRules] = useState<DataTreeViewItemValue[]>(
    props.defaultRules
  );
  const onCheckRule = (checkedNodes: FlatNodeItem[]) => {
    const rules = checkedNodes.filter((node) => !node.children);
    setCheckedRules(rules.map((node) => node.value));
  };
  const [isShowRuleLayer, setIsShowRuleLayer] = useState(false);

  const { orgId } = useOrganization();
  const { data: deviceData } = useGetPortalDevicesQuery(
    {
      orgId,
      sightmindStatus: true
    },
    { enabled: !!orgId, staleTime: 1000 * 60 * 60, cacheTime: 1000 * 60 * 60 }
  );

  const locations = useMemo(() => {
    if (deviceData) {
      const deviceIds = _.uniq(
        (checkedRules as string[]).map((rule) => {
          const [_, deviceId] = (rule as string).split(":");
          return deviceId;
        })
      );
      const groupByIds = _.groupBy(deviceData, "id");
      return _.groupBy(
        _.compact(
          _.map(deviceIds, (deviceId) => {
            const dinfo = groupByIds[deviceId]?.[0];
            if (dinfo) {
              return {
                deviceId,
                id: dinfo.locationId,
                name: dinfo.locationName
              };
            }
            return null;
          })
        ),
        "id"
      );
    }
    return [];
  }, [checkedRules, deviceData]);

  const locationCount = Object.keys(locations).length;

  return (
    <S.MenuButton
      data-id="root"
      style={{ padding: "0 11px", minWidth: "102px", position: "relative" }}
      onClick={() => setIsShowRuleLayer(true)}
    >
      <HVIcon name="location" svgProps={{ ...ICON.SIZE_24 }} />
      {translate("location")}
      {locationCount > 0 && (
        <>
          <S.Divider height={16} />
          <S.RuleCountBox>{`${locationCount} Selected`}</S.RuleCountBox>
        </>
      )}
      {isShowRuleLayer && (
        <S.LayerWrap onClick={(e) => e.stopPropagation()}>
          <AnalyticsRuleLayer
            onOK={() => setIsShowRuleLayer(false)}
            defaultRules={checkedRules.map(
              (rule) => ({ value: rule }) as FlatNodeItem
            )}
            onChecked={(v) => {
              onCheckRule(v);
              props.onChange(v);
            }}
          />
        </S.LayerWrap>
      )}
    </S.MenuButton>
  );
};
