export { default as IconCamera } from "./camera.svg";
export { default as IconEvents } from "./events.svg";
export { default as IconUser } from "./user.svg";
export { default as IconLog } from "./log.svg";
export { default as IconSystem } from "./system.svg";
export { default as IconPlus } from "./plus.svg";
export { default as IconStar } from "./star.svg";
export { default as IconDashboard } from "./dashboard.svg";
export { default as IconTick } from "./tick.svg";
export { default as IconCaution } from "./caution.svg";
export { default as IconInfo } from "./info.svg";
export { default as IconMinus } from "./minus.svg";
export { default as IconLogout } from "./logout.svg";
export { default as IconArrowsSort } from "./IconArrowsSort.svg";
export { default as IconSortAscending } from "./IconSortAscending.svg";
export { default as IconSortDescending } from "./IconSortDescending.svg";
export { default as IconMediumForEmptyState } from "./mediumIconEmptyState.svg";
export { default as IconLargeIconEmptyState } from "./largeIconEmptyState.svg";
export { default as IconTooltipHelp } from "./tooltipHelp.svg";
export { default as IconTooltipInfo } from "./tooltipInfo.svg";
export { default as BreadcrumbDivider } from "./breadcrumbDivider.svg";
export { default as IconOnlineSuccess } from "./online-success.svg";
export { default as IconWarningError } from "./warning-error.svg";
export { default as IconInformation } from "./information.svg";
export { default as IconFail } from "./fail.svg";
export { default as IconClose } from "./close.svg";
export { default as RightArrow } from "./rightArrow.svg";
export { default as IconSettings } from "./settings.svg";
export { default as IconReport } from "./report.svg";
export { default as IconEmpty } from "./empty.svg";
export { default as IconLink } from "./link.svg";
export { default as IconProfileLogout } from "./log-out.svg";
export { default as SightMindLogo } from "./sightMindLogo.svg";
export { default as CloudPortalLogo } from "./cloudPortal.svg";
export { default as HanwhaVisionLogo } from "./HanwhaVisionLogo.svg";
export { default as IconArrowUp } from "./arrow-up.svg";
export { default as IconArrowDown } from "./arrow-down.svg";
export { default as IconLeaf } from "./IconLeaf.svg";

// Below icon names as per new guideline
export { default as IconAdd } from "./add.svg";
export { default as IconUpSide } from "./arrow-up-side.svg";
export { default as IconDownSide } from "./arrow-down-side.svg";
export { default as IconLeftSide } from "./arrow-left-side.svg";
export { default as IconRightSide } from "./arrow-right-side.svg";
export { default as IconArrowChevronUp } from "./arrow-chevron-up.svg";
export { default as IconArrowChevronRight } from "./arrow-chevron-right.svg";
export { default as IconArrowChevronLeft } from "./arrow-chevron-left.svg";
export { default as IconArrowChevronDown } from "./arrow-chevron-down.svg";
export { default as IconArrowUpDown } from "./arrow-up-down.svg";
export { default as IconAlarm } from "./alarm.svg";
export { default as IconApps } from "./apps.svg";
export { default as IconArea } from "./area.svg";
export { default as IconAnalytics } from "./analytics.svg";
export { default as IconAI } from "./AI.svg";
export { default as IconBookmark } from "./bookmark.svg";
export { default as IconBuildingOffice1 } from "./building-office1.svg";
export { default as IconBuildingOffice2 } from "./building-office2.svg";
export { default as IconBuildingOffice3 } from "./building-office3.svg";
export { default as IconCheck } from "./check.svg";
export { default as IconCalendar } from "./calendar.svg";
export { default as IconCloseSign } from "./closeSign.svg";
export { default as IconCopy } from "./copy.svg";
export { default as IconCertification } from "./certification.svg";
export { default as IconCameraDome } from "./camera-dome.svg";
export { default as IconCameraDomeCloud } from "./camera-dome-cloud.svg";
export { default as IconCameraFisheye } from "./camera-fisheye.svg";
export { default as IconCameraExternal } from "./camera-external.svg";
export { default as IconCameraMDC } from "./camera-MDC.svg";
export { default as IconCrowd } from "./crowd.svg";
export { default as IconCpu } from "./cpu.svg";
export { default as IconCgiSender } from "./cgi-sender.svg";
export { default as IconCameraPTZ } from "./camera-PTZ.svg";
export { default as IconCameraTamp } from "./camera-tamp.svg";
export { default as IconCloudUpload } from "./cloud-upload.svg";
export { default as IconDevice } from "./device.svg";
export { default as IconDashboardSign } from "./dashboardSign.svg";
export { default as IconDocument } from "./document.svg";
export { default as IconDrag } from "./drag.svg";
export { default as IconDashboards } from "./dashboards.svg";
export { default as IconDirection } from "./direction.svg";
export { default as IconDefocus } from "./defocus.svg";
export { default as IconExternalLink } from "./external-link.svg";
export { default as IconExport } from "./export.svg";
export { default as IconEdit } from "./edit.svg";
export { default as IconEmail } from "./email.svg";
export { default as IconEvent } from "./event.svg";
export { default as IconPlainFail } from "./fail-no-fill.svg";
export { default as IconFavorite } from "./favorite.svg";
export { default as IconFilter } from "./filter.svg";
export { default as IconFolderClose } from "./folder-close.svg";
export { default as IconFolderOpen } from "./folder-open.svg";
export { default as IconFolklift } from "./folklift.svg";
export { default as IconFirmware } from "./firmware.svg";
export { default as IconForkliftCounting } from "./forklift counting.svg";
export { default as IconForkliftQueueManagement } from "./forklift-queue-management.svg";
export { default as IconForkliftHeatmap } from "./forklift-heatmap.svg";
export { default as IconFace } from "./face.svg";
export { default as IconFogDetection } from "./fog-detection.svg";
export { default as IconFocusNear } from "./focus-near.svg";
export { default as IconFocusFar } from "./focus-far.svg";
export { default as IconHelp } from "./help.svg";
export { default as IconHeatmap } from "./heatmap.svg";
export { default as IconHeaterHeat } from "./heater-heat.svg";
export { default as IconHeaterHeat1 } from "./heater-heat-1.svg";
export { default as IconInformationPlain } from "./information-plain.svg";
export { default as IconImageGallery } from "./image-gallery.svg";
export { default as IconLocation } from "./location.svg";
export { default as IconLive } from "./live.svg";
export { default as IconLockOrg } from "./lock.svg";
export { default as IconLockOpen } from "./lock-open.svg";
export { default as IconLinkClip } from "./link-clip.svg";
export { default as IconUnlinkClip } from "./unlink-clip.svg";
export { default as IconList } from "./list.svg";
export { default as IconLink1 } from "./link1.svg";
export { default as IconLogOut1 } from "./log-out1.svg";
export { default as IconLicense } from "./license.svg";
export { default as IconLog1 } from "./log1.svg";
export { default as IconLicensePlate } from "./license-plate.svg";
export { default as IconLineCrossing } from "./line-crossing.svg";
export { default as IconMore } from "./more.svg";
export { default as IconMotionDetection } from "./motion-detection.svg";
export { default as IconMap } from "./map.svg";
export { default as IconMenu } from "./menu.svg";
export { default as IconNotifications } from "./notification.svg";
export { default as IconNote } from "./note.svg";
export { default as IconNetwork } from "./network.svg";
export { default as IconOnlineSuccessPlain } from "./online-success-plain.svg";
export { default as IconObjectDetection } from "./object-detection.svg";
export { default as IconPlayback } from "./playback.svg";
export { default as IconPoE } from "./PoE.svg";
export { default as IconPeople } from "./people.svg";
export { default as IconPeopleVector } from "./people-vector.svg";
export { default as IconPeopleHeatmap } from "./people-heatmap.svg";
export { default as IconPTZ } from "./PTZ.svg";
export { default as IconQueueManagement } from "./queue-management.svg";
export { default as IconRefresh } from "./refresh.svg";
export { default as IconReset } from "./reset.svg";
export { default as IconRemove } from "./remove.svg";
export { default as IconReportsOld } from "./reports-old.svg";
export { default as IconRam } from "./ram.svg";
export { default as IconReports } from "./reports.svg";
export { default as IconRuleSetup } from "./rule-setup.svg";
export { default as IconSearch } from "./search.svg";
export { default as IconSetting } from "./setting.svg";
export { default as IconShare } from "./share.svg";
export { default as IconServer } from "./server.svg";
export { default as IconServerGateway } from "./server-gateway.svg";
export { default as IconStorage } from "./storage.svg";
export { default as IconSpeakerSoundVolume } from "./speaker-sound-volume.svg";
export { default as IconSpeakerSoundVolumeMute } from "./speaker-sound-volume-mute.svg";
export { default as IconSoftwareUpdate } from "./software-update.svg";
export { default as IconSlipFall } from "./slip-fall.svg";
export { default as IconShoppingCart } from "./shopping-cart.svg";
export { default as IconShoppingCartCounting } from "./shopping-cart-counting.svg";
export { default as IconShoppingCartQueueManagement } from "./shopping-cart-queue-management.svg";
export { default as IconShoppingCartHeatmap } from "./shopping-cart-heatmap.svg";
export { default as IconStoppedVehicle } from "./stopped-vehicle.svg";
export { default as IconShockDetection } from "./shock-detection.svg";
export { default as IconTime } from "./time.svg";
export { default as IconTopRank } from "./top-rank.svg";
export { default as IconTrafficJam } from "./traffic-jam.svg";
export { default as IconTemperature } from "./temperature.svg";
export { default as IconTamperingDetection } from "./tampering-detection.svg";
export { default as IconUserSingle } from "./user-single.svg";
export { default as IconUserProfile } from "./user-profile.svg";
export { default as IconUserGroup } from "./user-group.svg";
export { default as IconViewShow } from "./view-show.svg";
export { default as IconViewHide } from "./view-hide.svg";
export { default as IconVideoLoss } from "./video-loss.svg";
export { default as IconVehicle } from "./vehicle.svg";
export { default as IconVehicleMotorcycle } from "./vehicle-motorcycle.svg";
export { default as IconVehicleBicycle } from "./vehicle_bicycle.svg";
export { default as IconVehicleTruck } from "./vehicle-truck.svg";
export { default as IconVehicleBus } from "./vehicle-bus.svg";
export { default as IconVehicleVan } from "./vehicle-van.svg";
export { default as IconVehicleSedan } from "./vehicle-sedan.svg";
export { default as IconVehicleSuv } from "./vehicle-suv.svg";
export { default as IconVehicleCounting } from "./vehicle-counting.svg";
export { default as IconVehicleQueueManagement } from "./vehicle-queue-management.svg";
export { default as IconVehicleHeatmap } from "./vehicle-heatmap.svg";
export { default as IconWarning } from "./warning.svg";
export { default as IconWarranty } from "./warranty.svg";
export { default as IconWarrantyExpiration } from "./warranty-expiration.svg";
export { default as IconSortArrowUpDown } from "./sort-arrow-up-down.svg";
export { default as IconSortArrowDown } from "./sort-arrow-down.svg";
export { default as IconSortArrowUp } from "./sort-arrow-up.svg";
export { default as IconArrowRight } from "./IconArrowRight.svg";
export { default as IconCheckLine } from "./icon-check-line.svg";
export { default as IconArrowTimeInputChevronDown } from "./icon-arrow-time-input-chevron-down.svg";
export { default as IconArrowTimeInputChevronUp } from "./icon-arrow-time-input-chevron-up.svg";
