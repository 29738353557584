import { useRef } from "react";
import { useLayer } from "@components/common/layer/useLayer";
import { ReactComponent as IconOrdering } from "@resources/icons/svg/analytics/icon-ordering.svg";

import { useLang } from "@hooks/useLang";
import { getChartIcon } from "@hooks/analytics/useRuleGroupByLocation";
import { ANALYTICS_CHART_TYPE } from "@resources/Constants";
import ModalManager from "@utils/ModalManager";
import _ from "lodash";
import {
  Hide,
  Order
} from "@queries/tanstack/analytics-filter/AnalyticsFilterQuery";
import * as S from "../TopMenu.styled";
import { ChartOrderLayer } from "../../order/ChartOrderLayer";
import { OrderItem } from "../../order/OrderItem";

interface OrderingButtonProps {
  order: Order[];
  hide: Hide[];
  onSave: (params: { order: Order[]; hide: Hide[] }) => void;
  disabled?: boolean;
}
export const OrderingButton = (props: OrderingButtonProps) => {
  const { translate } = useLang();
  const changedOrder = useRef<Order[]>(props.order);
  const changedHide = useRef<Hide[]>(props.hide);
  const isEdited = () => {
    return !(
      _.isEqual(props.order, changedOrder.current) &&
      _.isEqual(props.hide, changedHide.current)
    );
  };

  const { open: openLayer, close: closeLayer } = useLayer(
    <ChartOrderLayer
      items={props.order.map((orderType) => {
        const chartType = _.snakeCase(orderType).toUpperCase();
        const ChartIcon = getChartIcon(
          orderType as keyof typeof ANALYTICS_CHART_TYPE
        );

        const isShow = !props.hide.includes(orderType);

        return (
          <OrderItem
            key={orderType}
            title={translate(`analytics_chart_name.${chartType.toLowerCase()}`)}
            icon={ChartIcon && <ChartIcon />}
            defaultIsShow={isShow}
            onChangeShow={(isShow) => {
              if (isShow) {
                changedHide.current = changedHide.current.filter(
                  (hideType) => hideType !== orderType
                );
              } else {
                changedHide.current = [...changedHide.current, orderType];
              }
            }}
          />
        );
      })}
      onCancel={() => {
        if (!isEdited()) {
          closeLayer();
          return;
        }

        ModalManager.confirm(translate("not_save_message"), {
          title: translate("cancel_edit"),
          labelConfirm: translate("ok"),
          labelCancel: translate("cancel"),
          onSubmit: () => {
            closeLayer();
          }
        });
      }}
      onOk={() => {
        if (!isEdited()) {
          closeLayer();
          return;
        }

        props.onSave({
          order: [...changedOrder.current],
          hide: [...changedHide.current]
        });
        closeLayer();
      }}
      onClose={() => {
        if (!isEdited()) {
          closeLayer();
          return;
        }

        ModalManager.confirm(translate("not_save_message"), {
          title: translate("cancel_edit"),
          labelConfirm: translate("ok"),
          labelCancel: translate("cancel"),
          onSubmit: () => {
            closeLayer();
          }
        });
      }}
      onChangeOrder={(order) => {
        changedOrder.current = order;
      }}
    />
  );

  return (
    <S.MenuButton
      style={{ padding: "0 15px 0 11px", position: "relative" }}
      onClick={() => openLayer()}
      disabled={props.disabled}
    >
      <IconOrdering />
      {translate("ordering")}
    </S.MenuButton>
  );
};
