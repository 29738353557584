import { IconSightMind } from "@resources/icons/Icons";
import * as S from "./Message.styled";

export const Message = ({
  own,
  message,
  state
}: {
  own: boolean;
  message: string;
  state: string;
}) => {
  return (
    <S.Container own={own}>
      {!own ? (
        <>
          <S.MessageImgae>
            <IconSightMind width={28} height={26} />
          </S.MessageImgae>
          {state === "loading" ? (
            <S.MessageContentLoad>
              <p>Loading</p>
              <S.MessageAILoading>
                <div />
                <div />
                <div />
                <div />
              </S.MessageAILoading>
            </S.MessageContentLoad>
          ) : (
            <S.MessageAIContent>{message}</S.MessageAIContent>
          )}
        </>
      ) : (
        <S.MessageUserContent>{message}</S.MessageUserContent>
      )}
    </S.Container>
  );
};
