import {
  ForwardedRef,
  InputHTMLAttributes,
  MouseEvent,
  useEffect,
  useId,
  useRef,
  forwardRef
} from "react";
import { HVIcon } from "@repo/HVComponents";
import * as S from "./Input.styled";
import { ICON } from "../../../constants/icons";

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onBlur"> {
  error?: string;
  hasClearBtn?: boolean;
  onClickClear?: () => void;
  onBlur?: (input: HTMLInputElement, target?: HTMLElement) => void;
  innerRef?: ForwardedRef<HTMLInputElement>;
}

export function Input({
  placeholder,
  error,
  hasClearBtn,
  onClickClear,
  innerRef,
  onBlur,
  ...props
}: InputProps) {
  const uniq = useId();
  const ref = useRef<HTMLDivElement | null>(null);
  const inputId = `input_${uniq}`;

  useEffect(() => {
    const handleClick = (ev: Event) => {
      const target = ev.target as HTMLElement;
      if (!ref.current?.contains(target)) {
        const inputEle = document.getElementById(inputId) as HTMLInputElement;
        onBlur && onBlur(inputEle, target);
      }
    };
    document.body.addEventListener("click", handleClick);

    return () => {
      document.body.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <S.InputStyled ref={ref}>
      <S.StyledInputWrapper hasClearBtn={hasClearBtn} error={!!error}>
        <input
          id={inputId}
          placeholder={placeholder}
          ref={innerRef}
          {...props}
        />
        {hasClearBtn && (
          <button
            aria-label="delete"
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              onClickClear && onClickClear();
            }}
          >
            <HVIcon name="fail" svgProps={{ ...ICON.SIZE_16 }} />
          </button>
        )}
      </S.StyledInputWrapper>
      {error && <S.StyledErrorMessage>{error}</S.StyledErrorMessage>}
    </S.InputStyled>
  );
}

export const InputRef = forwardRef(
  (props: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
    return <Input {...props} innerRef={ref} />;
  }
);
