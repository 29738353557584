"use client";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import { MantineProvider, MantineProviderProps } from "@mantine/core";
import { theme, cssResolver } from "../hvTheme";
import { Notifications } from "@mantine/notifications";

const HVThemeProvider = ({ children, ...rest }: MantineProviderProps) => {
  return (
    <MantineProvider theme={theme} cssVariablesResolver={cssResolver} {...rest}>
      <Notifications />
      {children}
    </MantineProvider>
  );
};

export default HVThemeProvider;
