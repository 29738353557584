"use client";

import IHVNavigationBarProps, {
  INavigationItem,
} from "./IHVNavigationBarProps";
import { Box } from "@mantine/core";
import { Image } from "@mantine/core";

import style from "./HVNavigationBar.module.css";
import HVTooltip from "../HVTooltip/HVTooltip";
import HVText from "../HVText/HVText";

const HVNavigationBar = ({
  menus,
  logoSrc,
  activeMenuId,
  title,
}: IHVNavigationBarProps) => {
  return (
    <Box component="div" className={style.mainNavigation}>
      <Box component="div" className={style.logoHeader}>
        <Image src={logoSrc} alt="logo" />
        {title && <HVText>{title}</HVText>}
      </Box>
      <Box component="div">
        {menus.map((item: INavigationItem) => {
          return (
            <HVTooltip
              label={item.title}
              isHoverEffectNeeded={false}
              position="right"
              key={item.id}
            >
              <Box
                component="a"
                onClick={() => {
                  if (item.link) {
                    return true;
                  }
                  item?.navigationItemClick && item?.navigationItemClick(item);
                }}
                href={item.link}
                className={style.menuItem}
              >
                <Box
                  component="div"
                  className={`${style.menuIcon} ${activeMenuId && item.id === activeMenuId && style.active}`}
                >
                  {item.icon}
                </Box>
              </Box>
            </HVTooltip>
          );
        })}
      </Box>
    </Box>
  );
};

export default HVNavigationBar;
