import styled from "styled-components";

export const InputStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  color: var(--text-primary);

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;

    & > path {
      fill: var(--icon-default);
    }

    &:hover {
      cursor: pointer;
    }
  }

  label {
    margin-bottom: 4px;
  }
`;

export const StyledInputWrapper = styled.div<{
  hasClearBtn?: boolean;
  error?: boolean;
}>`
  position: relative;
  input {
    width: 100%;
    height: 32px;
    font-size: 14px;
    border: 1px solid var(--border-emphasis);
    background-color: var(--background-ibb-default);
    color: var(--text-primary);
    padding: 6px;

    &:focus {
      border: 1px solid var(--border-emphasis);
    }

    &:hover,
    &:active {
      border: 1px solid var(--border-input-hover-active);
    }
    &:active {
      background-color: var(--background-ibb-select);
    }

    &:disabled {
      background-color: var(--background-ibb-disabled);
      border: 1px solid var(--border-input-disabled);

      &::placeholder {
        color: var(--text-disabled);
      }
    }

    &::placeholder {
      color: var(--text-secondary-2);
    }
  }

  ${({ error }) =>
    error &&
    ` input {
    border-color: var(--system-red);
  }`}

  ${({ hasClearBtn }) =>
    hasClearBtn &&
    `
  input {
    padding-right: 24px;
  }

  `}
`;

export const StyledErrorMessage = styled.span`
  display: inline-block;
  white-space: pre;
  width: 0;
  color: var(--system-red);
`;
