"use client";

import LoaderSVG from "./LoaderSVG";
import IHVLoaderProps from "./IHVLoaderProps";
import HVFlex from "../HVFlex/HVFlex";
import HVText from "../HVText/HVText";

const sizeMap: Partial<Record<IHVLoaderProps["size"], number>> = {
  xs: 20,
  sm: 24,
  md: 32,
  lg: 40,
  xl: 48,
  Huge: 60,
};

const getColors = (
  variant: IHVLoaderProps["variant"],
  primaryStroke: string | undefined,
  secondaryStroke: string | undefined,
) => {
  switch (variant) {
    case "Primary":
      return {
        primaryStroke: "var(--hv-brand-primary)",
        secondaryStroke: "var(--hv-loading-circuit-ring-default)",
      };
    case "Secondary":
      return {
        primaryStroke: "var(--hv-loading-spinner-secondary)",
        secondaryStroke: "var(--hv-loading-circuit-ring-default)",
      };
    default:
      return {
        primaryStroke: primaryStroke ?? "var(--hv-brand-primary)",
        secondaryStroke:
          secondaryStroke ?? "var(--hv-loading-circuit-ring-default)",
      };
  }
};

const HVLoader = ({
  containerProps,
  variant,
  size = "Huge",
  primaryStroke,
  secondaryStroke,
  strokeWidth,
  label,
}: IHVLoaderProps & { size?: IHVLoaderProps["size"] | number }) => {
  const loaderSize = sizeMap[size] ?? size;
  const colors = getColors(variant, primaryStroke, secondaryStroke);

  return (
    <HVFlex {...containerProps}>
      <HVFlex align={"center"}>
        <LoaderSVG
          size={loaderSize}
          primaryStroke={colors.primaryStroke}
          secondaryStroke={colors.secondaryStroke}
          strokeWidth={strokeWidth}
        />
        {label && <HVText type="Body-sm">{label}</HVText>}
      </HVFlex>
    </HVFlex>
  );
};

export default HVLoader;
