import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "@utils/Query";
import { devicesApis } from "@queries/Devices";
import useOrganization from "@hooks/useOrganization";

export const useThumbnail = ({
  deviceId,
  channel,
  disabled
}: {
  deviceId: string;
  channel: number;
  disabled?: boolean;
}) => {
  const { orgId } = useOrganization();
  const { data, isLoading, isError } = useQuery(
    queryKeys(`SUNAPI-${deviceId}-snapshot`).getAll(),
    ({ signal }) =>
      devicesApis.getSnapshot(
        orgId ?? "",
        deviceId ?? "",
        {
          channel: channel ?? 0
        },
        signal
      ),
    {
      enabled: !!orgId && !!deviceId && !disabled,
      retry: false
    }
  );

  return {
    data,
    isLoading,
    isError
  };
};
