import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 368px;
  height: calc(100vh - 84px);
  border-radius: 12px;
  background-color: var(--surface-widget);
`;
export const GenHeader = styled.header`
  height: 52px;
  padding: 14px 20px;
  display: flex;
  gap: 5px;
  border-bottom: 1px solid var(--border-light);
`;

export const GenTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  width: 304px;
  color: var(--text-primary);
  align-content: center;
`;

export const GenContent = styled.main`
  flex: 1;
  overflow-y: auto;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;
export const ContentTitle = styled.h2`
  margin: 80px 45px 0;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  line-height: 24px;
  color: var(--text-secondary-1);

  span {
    font-weight: 700;
  }
`;

export const CardContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ChatFooter = styled.form`
  align-items: baseline;
  border-top: 1px solid var(--border-light);
  padding: 20px;
  min-height: 112px;
`;

export const ChatInput = styled.textarea`
  flex: 1;
  width: 100%;
  background: transparent;
  color: var(--text-secondary-1);
  min-height: 20px;
  border: none;
  outline: none;
  font-size: 14px;
  overflow-y: hidden; /* 스크롤바 숨김 */
  resize: none; /* 사용자가 직접 크기를 조절하지 못하게 함 */
  line-height: normal;
`;
export const SendIcon = styled.button<{ active?: boolean }>`
  cursor: pointer;
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 12px 0 0 auto;
  background-color: var(--background-button-disabled);
  > svg {
    path {
      fill: var(--icon-disabled);
    }
  }

  ${(props) =>
    props.active &&
    css`
      background-color: var(--orange-primary);
      > svg {
        path {
          fill: var(--white);
        }
      }
      &:hover {
        background-color: var(--background-button-hover);
      }
    `}
`;

export const ScrollButton = styled.button`
  position: absolute;
  bottom: 135px; /* Footer 바로 위에 배치 */
  left: 50%;
  transform: translateX(-50%);
  background: var(--orange-primary);
  padding: 5px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease-in-out;
  opacity: 0.5;
  > svg {
    path {
      fill: var(--white);
    }
  }
  &:hover {
    background: var(--background-button-hover);
    opacity: 1;
  }
`;
