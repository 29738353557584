"use client";

import { Checkbox } from "@mantine/core";
import styles from "./HVCheckbox.module.css";
import HVText from "../HVText/HVText";
import IHVCheckboxProps from "./IHVCheckboxProps";

const HVCheckbox = ({
  checked,
  label,
  disabled,
  onChange,
  defaultChecked,
  labelProps,
  ...rest
}: IHVCheckboxProps) => {
  return (
    <Checkbox
      label={
        label ? (
          <HVText
            c={
              disabled
                ? "var(--hv-text-normal-disabled)"
                : "var(--hv-text-normal-primary)"
            }
            type="Body-sm"
            {...labelProps}
          >{`${label}`}</HVText>
        ) : null
      }
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      defaultChecked={defaultChecked}
      classNames={styles}
      {...rest}
    />
  );
};

export default HVCheckbox;
