import {
  IconAdd,
  IconAI,
  IconAlarm,
  IconAnalytics,
  IconApps,
  IconArea,
  IconArrowChevronDown,
  IconArrowChevronLeft,
  IconArrowChevronRight,
  IconArrowChevronUp,
  IconArrowUpDown,
  IconBookmark,
  IconBuildingOffice1,
  IconBuildingOffice2,
  IconBuildingOffice3,
  IconDownSide,
  IconLeftSide,
  IconRightSide,
  IconUpSide,
  IconCheck,
  IconCalendar,
  IconCopy,
  IconCertification,
  IconCameraDome,
  IconCameraDomeCloud,
  IconCameraFisheye,
  IconCameraExternal,
  IconCameraMDC,
  IconCrowd,
  IconCpu,
  IconCgiSender,
  IconCameraPTZ,
  IconCameraTamp,
  IconCloudUpload,
  IconCloseSign,
  IconDevice,
  IconDashboardSign,
  IconDocument,
  IconDrag,
  IconDashboards,
  IconDirection,
  IconDefocus,
  IconExternalLink,
  IconExport,
  IconEdit,
  IconEmail,
  IconEvent,
  IconPlainFail,
  IconFavorite,
  IconFilter,
  IconFolderClose,
  IconFolderOpen,
  IconFolklift,
  IconFirmware,
  IconForkliftCounting,
  IconForkliftQueueManagement,
  IconForkliftHeatmap,
  IconFace,
  IconFogDetection,
  IconFocusNear,
  IconFocusFar,
  IconHelp,
  IconHeatmap,
  IconHeaterHeat,
  IconHeaterHeat1,
  IconImageGallery,
  IconInformationPlain,
  IconLocation,
  IconLive,
  IconLockOrg,
  IconLockOpen,
  IconLinkClip,
  IconUnlinkClip,
  IconList,
  IconLink1,
  IconLogOut1,
  IconLicense,
  IconLog1,
  IconLicensePlate,
  IconLineCrossing,
  IconMore,
  IconMotionDetection,
  IconMap,
  IconMenu,
  IconNotifications,
  IconNote,
  IconNetwork,
  IconOnlineSuccessPlain,
  IconObjectDetection,
  IconPlayback,
  IconPoE,
  IconPeople,
  IconPeopleVector,
  IconPeopleHeatmap,
  IconPTZ,
  IconQueueManagement,
  IconRefresh,
  IconReset,
  IconRemove,
  IconReportsOld,
  IconRam,
  IconReports,
  IconRuleSetup,
  IconUserGroup,
  IconUserProfile,
  IconUserSingle,
  IconSearch,
  IconSetting,
  IconShare,
  IconServer,
  IconServerGateway,
  IconStorage,
  IconSpeakerSoundVolume,
  IconSpeakerSoundVolumeMute,
  IconSoftwareUpdate,
  IconSlipFall,
  IconShoppingCart,
  IconShoppingCartCounting,
  IconShoppingCartQueueManagement,
  IconShoppingCartHeatmap,
  IconStoppedVehicle,
  IconShockDetection,
  IconTime,
  IconTopRank,
  IconTrafficJam,
  IconTemperature,
  IconTamperingDetection,
  IconViewShow,
  IconViewHide,
  IconVideoLoss,
  IconVehicle,
  IconVehicleMotorcycle,
  IconVehicleBicycle,
  IconVehicleTruck,
  IconVehicleBus,
  IconVehicleVan,
  IconVehicleSedan,
  IconVehicleSuv,
  IconVehicleCounting,
  IconVehicleQueueManagement,
  IconVehicleHeatmap,
  IconWarning,
  IconWarranty,
  IconWarrantyExpiration,
  IconOnlineSuccess,
  IconInformation,
  IconFail,
  IconWarningError,
  IconTooltipInfo,
  IconTooltipHelp,
  IconSortArrowUpDown,
  IconSortArrowUp,
  IconSortArrowDown,
  IconEmpty,
  IconLeaf,
  IconLargeIconEmptyState,
  IconMediumForEmptyState,
  IconArrowRight,
} from "../../assets/icons";

import IHVIconProps from "./IHVIcon";

type typeIconNames = {
  [key in IHVIconProps["name"]]: React.FC<React.SVGProps<SVGSVGElement>>;
};

const iconList: typeIconNames = {
  add: IconAdd,
  "arrow-up": IconUpSide,
  "arrow-right": IconRightSide,
  "arrow-down": IconDownSide,
  "arrow-left": IconLeftSide,
  "arrow-chevron-up": IconArrowChevronUp,
  "arrow-chevron-right": IconArrowChevronRight,
  "arrow-chevron-left": IconArrowChevronLeft,
  "arrow-chevron-down": IconArrowChevronDown,
  "arrow-up-down": IconArrowUpDown,
  alarm: IconAlarm,
  apps: IconApps,
  area: IconArea,
  analytics: IconAnalytics,
  AI: IconAI,
  bookmark: IconBookmark,
  "building-office1": IconBuildingOffice1,
  "building-office2": IconBuildingOffice2,
  "building-office3": IconBuildingOffice3,
  check: IconCheck,
  calendar: IconCalendar,
  close: IconCloseSign,
  copy: IconCopy,
  certification: IconCertification,
  "camera-dome": IconCameraDome,
  "camera-dome-cloud": IconCameraDomeCloud,
  "camera-fisheye": IconCameraFisheye,
  "camera-external": IconCameraExternal,
  "camera-MDC": IconCameraMDC,
  crowd: IconCrowd,
  cpu: IconCpu,
  "cgi-sender": IconCgiSender,
  "camera-PTZ": IconCameraPTZ,
  "camera-tamp": IconCameraTamp,
  "cloud-upload": IconCloudUpload,
  device: IconDevice,
  dashboard: IconDashboardSign,
  document: IconDocument,
  drag: IconDrag,
  dashboards: IconDashboards,
  direction: IconDirection,
  defocus: IconDefocus,
  "external-link": IconExternalLink,
  export: IconExport,
  edit: IconEdit,
  email: IconEmail,
  empty: IconEmpty,
  event: IconEvent,
  fail: IconPlainFail,
  "fail-snackbar": IconFail,
  favorite: IconFavorite,
  filter: IconFilter,
  "folder-close": IconFolderClose,
  "Folder-open": IconFolderOpen,
  folklift: IconFolklift,
  firmware: IconFirmware,
  "forklift-counting": IconForkliftCounting,
  "forklift-queue-management": IconForkliftQueueManagement,
  "forklift-heatmap": IconForkliftHeatmap,
  face: IconFace,
  "fog-detection": IconFogDetection,
  "focus-near": IconFocusNear,
  "focus-far": IconFocusFar,
  help: IconHelp,
  heatmap: IconHeatmap,
  "heater-heat": IconHeaterHeat,
  "heater-heat-1": IconHeaterHeat1,
  information: IconInformationPlain,
  "image-gallery": IconImageGallery,
  "information-filled": IconInformation,
  location: IconLocation,
  live: IconLive,
  lock: IconLockOrg,
  "lock-open": IconLockOpen,
  "link-clip": IconLinkClip,
  list: IconList,
  link: IconLink1,
  "log-out": IconLogOut1,
  license: IconLicense,
  log: IconLog1,
  "license-plate": IconLicensePlate,
  "line-crossing": IconLineCrossing,
  more: IconMore,
  "motion-detection": IconMotionDetection,
  map: IconMap,
  menu: IconMenu,
  notification: IconNotifications,
  note: IconNote,
  network: IconNetwork,
  "online-success": IconOnlineSuccessPlain,
  "online-success-filled": IconOnlineSuccess,
  "object-detection": IconObjectDetection,
  playback: IconPlayback,
  PoE: IconPoE,
  people: IconPeople,
  "people-vector": IconPeopleVector,
  "people-heatmap": IconPeopleHeatmap,
  PTZ: IconPTZ,
  "queue-management": IconQueueManagement,
  refresh: IconRefresh,
  reset: IconReset,
  remove: IconRemove,
  "reports-old": IconReportsOld,
  ram: IconRam,
  reports: IconReports,
  "rule-setup": IconRuleSetup,
  search: IconSearch,
  setting: IconSetting,
  share: IconShare,
  server: IconServer,
  "server-gateway": IconServerGateway,
  storage: IconStorage,
  "speaker-sound-volume": IconSpeakerSoundVolume,
  "speaker-sound-volume-mute": IconSpeakerSoundVolumeMute,
  "software-update": IconSoftwareUpdate,
  "sort-arrow-down": IconSortArrowDown,
  "sort-arrow-up": IconSortArrowUp,
  "sort-arrow-up-down": IconSortArrowUpDown,
  "slip-fall": IconSlipFall,
  "shopping-cart": IconShoppingCart,
  "shopping-cart-counting": IconShoppingCartCounting,
  "shopping-cart-queue-management": IconShoppingCartQueueManagement,
  "shopping-cart-heatmap": IconShoppingCartHeatmap,
  "stopped-vehicle": IconStoppedVehicle,
  "shock-detection": IconShockDetection,
  time: IconTime,
  "top-rank": IconTopRank,
  "traffic-jam": IconTrafficJam,
  temperature: IconTemperature,
  "tooltip-help": IconTooltipHelp,
  "tooltip-info": IconTooltipInfo,
  "tampering-detection": IconTamperingDetection,
  user: IconUserSingle,
  "user-profile": IconUserProfile,
  "user-group": IconUserGroup,
  "unlink-clip": IconUnlinkClip,
  "view-show": IconViewShow,
  "view-hide": IconViewHide,
  "video-loss": IconVideoLoss,
  vehicle: IconVehicle,
  "vehicle-motorcycle": IconVehicleMotorcycle,
  vehicle_bicycle: IconVehicleBicycle,
  "vehicle-truck": IconVehicleTruck,
  "vehicle-bus": IconVehicleBus,
  "vehicle-van": IconVehicleVan,
  "vehicle-sedan": IconVehicleSedan,
  "vehicle-suv": IconVehicleSuv,
  "vehicle-counting": IconVehicleCounting,
  "vehicle-queue-management": IconVehicleQueueManagement,
  "vehicle-heatmap": IconVehicleHeatmap,
  warning: IconWarning,
  "warning-error": IconWarningError,
  warranty: IconWarranty,
  "warranty-expiration": IconWarrantyExpiration,
  leaf: IconLeaf,
  "empty-state-large": IconLargeIconEmptyState,
  "empty-state-medium": IconMediumForEmptyState,
  "icon-arrow-right": IconArrowRight
};
export default iconList;
