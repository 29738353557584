import { useAppSelector } from "@configs/Redux";
import { Router } from "@configs/Router";
import { flattenMessages, messages } from "@languages/index";
import { LANG } from "@resources/Constants";
import { GlobalStyles } from "@themes/GlobalStyles";

import { IntlProvider } from "react-intl";
import { LayerPortal } from "@components/common/layer/LayerPortal";
import { ThemeProvider } from "@themes/ThemeProvider";
import { PageWrapperStyled } from "./PageWrapper.styled";

export function PageWrapper(): JSX.Element {
  const { lang } = useAppSelector((state) => state.system);

  return (
    <IntlProvider
      locale={lang}
      defaultLocale={LANG.EN}
      messages={flattenMessages(messages[lang])}
    >
      <ThemeProvider>
        <GlobalStyles />

        <PageWrapperStyled>
          <Router />
          <LayerPortal />
        </PageWrapperStyled>
        <PageWrapperStyled>
          <Router />
          <LayerPortal />
        </PageWrapperStyled>

        <div style={{ display: "none" }}>
          Build Version: {process.env.BUILD_VERSION_DATE}
        </div>
      </ThemeProvider>
    </IntlProvider>
  );
}
