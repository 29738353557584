"use client";

import { Switch } from "@mantine/core";
import styles from "./HVToggle.module.css";
import IHVToggleProps from "./IHVToggleProps";
import HVText from "../HVText/HVText";

const HVSwitch = ({
  checked,
  onChange,
  onLabel,
  offLabel,
  defaultChecked,
  label,
  disabled,
  variant = "filled",
  ...rest
}: IHVToggleProps) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      onLabel={
        <HVText type="Body-xs" c={"inherit"}>
          {onLabel}
        </HVText>
      }
      offLabel={
        <HVText type="Body-xs" c={"inherit"}>
          {offLabel}
        </HVText>
      }
      defaultChecked={defaultChecked}
      label={label}
      disabled={disabled}
      variant={variant}
      classNames={styles}
      color="var(--hv-toggle-selected-orange)"
      {...rest}
    />
  );
};

export default HVSwitch;
