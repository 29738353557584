import { Tooltip, Box } from "@mantine/core";
import HVText from "../HVText/HVText";
import styles from "./HVTooltip.module.css";
import IHVTooltipProps from "./IHVTooltipProps";
import HVIcon from "../HVIcon/HVIcon";

function HVTooltip({
  label,
  tooltipProps,
  children,
  isHoverEffectNeeded = true,
  position = "top",
  variant = "Help",
}: IHVTooltipProps) {
  return (
    <Tooltip
      label={
        <HVText type="Body-xs" c={"var(--hv-text-on-brand-primary)"}>
          {label}
        </HVText>
      }
      multiline
      className={styles.tooltip}
      position={position}
      {...tooltipProps}
    >
      <Box
        className={`${styles.box} ${isHoverEffectNeeded && styles.boxHover}`}
      >
        {!children ? (
          variant === "Help" ? (
            <HVIcon name="tooltip-help" styleClass={styles.svg} />
          ) : (
            <HVIcon name="tooltip-info" styleClass={styles.svg} />
          )
        ) : (
          children
        )}
      </Box>
    </Tooltip>
  );
}

export default HVTooltip;
