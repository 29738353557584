import { useState, ReactNode, useMemo } from "react";
import { LayerContext, LayerType } from "./LayerContext";

interface LayerProviderProps {
  children: ReactNode;
}

export const LayerProvider = ({ children }: LayerProviderProps) => {
  const [layers, setLayers] = useState<LayerType[]>([]);

  const contextValue = useMemo(
    () => ({
      push: (layer: LayerType) => {
        setLayers((prev) => [
          ...prev.filter((layer) => !layer.options.autoHide),
          layer
        ]);
      },
      delete: (key: string) => {
        setLayers((prev) => {
          prev.find((layer) => layer.key === key)?.options.onClose?.();
          return prev.filter((layer) => layer.key !== key);
        });
      },
      clear: () => {
        setLayers((prev) => {
          prev.reverse().forEach((layer) => layer.options.onClose?.());
          return [];
        });
      },
      layers
    }),
    [layers]
  );

  return (
    <LayerContext.Provider value={contextValue}>
      {children}
    </LayerContext.Provider>
  );
};
