import { NormalStyled, SkeletonStyled } from "./Skeleton.styled";

interface SkeletonProps {
  isLoading?: boolean;
  children: React.ReactNode;
}

export const Skeleton = ({ isLoading, children }: SkeletonProps) => {
  if (isLoading) {
    return <SkeletonStyled>{children}</SkeletonStyled>;
  }
  return <NormalStyled>{children}</NormalStyled>;
};
