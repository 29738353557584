"use client";
import { Flex } from "@mantine/core";

import IHVFlexProps from "./IHVFlexProps";

const HVFlex = ({
  direction,
  gap,
  justify,
  children,
  ...rest
}: IHVFlexProps) => {
  return (
    <Flex
      direction={direction || "column"}
      gap={gap}
      justify={justify}
      wrap={"nowrap"}
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default HVFlex;
