"use client";

import { Image, Box } from "@mantine/core";
import IHVLogoProps from "./IHVLogoProps";
import style from "./HVLogo.module.css";
import HVText from "../HVText/HVText";

const HVLogo = ({
  imageSrc,
  altText = "",
  text,
  url,
  key,
  appContainerProps,
  imageSize,
  imageProps,
  textProps,
}: IHVLogoProps) => {
  return (
    <Box
      className={style.outerBox}
      component="a"
      href={url}
      key={key}
      {...appContainerProps}
    >
      <Image
        src={imageSrc}
        alt={altText}
        h={imageSize?.height || imageSize?.width || "80px"}
        w={imageSize?.width || imageSize?.width || "80px"}
        {...imageProps}
      />
      <HVText type={"Body-sm"} {...textProps}>
        {text}
      </HVText>
    </Box>
  );
};

export default HVLogo;
