"use client";

import IHVAvatarProps, { IHVAvatar } from "./IHVAvatarProps";
import { Avatar } from "@mantine/core";
import styles from "./HVAvatar.module.css";
import HVText from "../HVText/HVText";

const getTextType = (avatarSize: IHVAvatar["size"]) => {
  if (avatarSize === "lg") {
    return "Headline-xs";
  } else if (avatarSize === "xl") {
    return "Headline-lg";
  } else if (avatarSize === "md") {
    return "Body-sm";
  } else if (avatarSize === "sm") {
    return "Body-xs";
  }
};

const getAvatarSizeByType = (avatarSize: IHVAvatar["size"]) => {
  if (avatarSize === "lg") {
    return "72px";
  } else if (avatarSize === "xl") {
    return "120px";
  } else if (avatarSize === "md") {
    return "40px";
  } else if (avatarSize === "sm") {
    return "32px";
  }
};

const AvtarItem = ({ avatarData, ...rest }: { avatarData: IHVAvatar }) => {
  return (
    <Avatar
      size={getAvatarSizeByType(avatarData.size)}
      className={`${styles.root}`}
      src={avatarData.type !== "text" ? avatarData.imageSrc : null}
      {...rest}
    >
      {avatarData.type === "text" && (
        <HVText
          fw={700}
          c={"var( --hv-avatar-text-primary)"}
          type={getTextType(avatarData.size)}
          {...avatarData.textProps}
        >
          {avatarData.text}
        </HVText>
      )}
    </Avatar>
  );
};

const AvtarGroup = ({
  groupedAvtarData,
  groupLimit,
  ...rest
}: {
  groupedAvtarData: IHVAvatar[];
  groupLimit?: number;
}) => {
  const clonedAvatarData = groupedAvtarData;
  const maxAvatarIcons = groupLimit ? groupLimit : groupedAvtarData.length;
  return (
    <Avatar.Group {...rest}>
      {clonedAvatarData.map((avatarData: IHVAvatar, index: number) => {
        if (index < maxAvatarIcons) {
          return (
            <AvtarItem
              avatarData={avatarData as IHVAvatar}
              key={index.toString()}
              {...rest}
            />
          );
        }
      })}
      {maxAvatarIcons < groupedAvtarData.length && (
        <AvtarItem
          avatarData={{
            size: clonedAvatarData[0].size,
            textProps: { c: "var(--hv-avatar-text-secondary)" },
            type: "text",
            text: `+${clonedAvatarData.length - maxAvatarIcons}`,
          }}
        />
      )}
    </Avatar.Group>
  );
};

const HVAvatar = ({ group, groupLimit, item, ...rest }: IHVAvatarProps) => {
  return !group ? (
    <AvtarItem avatarData={item as IHVAvatar} {...rest} />
  ) : (
    <AvtarGroup
      groupLimit={groupLimit}
      groupedAvtarData={item as IHVAvatar[]}
      {...rest}
    />
  );
};

export default HVAvatar;
