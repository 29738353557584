import { ReactNode } from "react";
import { nanoid } from "@reduxjs/toolkit";
import { createRoot } from "react-dom/client";
import { Modal, ModalProps } from "@components/common/modal";
import { store, useAppSelector } from "@configs/Redux";
import { flattenMessages, messages } from "@languages/index";
import { LANG } from "@resources/Constants";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { queryClient } from "@configs/Queries";
import { QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@themes/ThemeProvider";

let zIndex = 9000;
let modalRoot: HTMLElement | null = null;

const IntlWrapper = ({ children }: { children: ReactNode }) => {
  const { lang } = useAppSelector((state) => state.system);

  return (
    <IntlProvider
      locale={lang}
      defaultLocale={LANG.EN}
      messages={flattenMessages(messages[lang])}
    >
      {children}
    </IntlProvider>
  );
};

const ModalWrapper = () => {
  const id = `modal-${nanoid()}`;
  modalRoot = document.getElementById(id);

  if (!modalRoot) {
    modalRoot = document.createElement("div");
    modalRoot.id = id;
    modalRoot.style.position = "fixed";
    modalRoot.style.width = "100vw";
    modalRoot.style.height = "100vh";
    modalRoot.style.top = "0";
    modalRoot.style.left = "0";
    modalRoot.style.zIndex = String(zIndex);
    document.body.appendChild(modalRoot);
  }

  zIndex += 1;
  return createRoot(modalRoot);
};

const open = (component?: ReactNode, opts?: ModalProps) => {
  const root = ModalWrapper();
  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <IntlWrapper>
              <Modal
                {...opts}
                onSubmit={() => {
                  opts?.onSubmit?.();
                  modalRoot?.remove();
                  root.unmount();
                }}
                onCancel={() => {
                  opts?.onCancel?.();
                  modalRoot?.remove();
                  root.unmount();
                }}
              >
                {component}
              </Modal>
            </IntlWrapper>
          </QueryClientProvider>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
};

const message = (msg?: string, opts?: ModalProps) => {
  open(msg, {
    ...opts,
    showModal: true,
    isConfirm: false
  });
};

const confirm = (component?: ReactNode, opts?: ModalProps) => {
  open(component, { ...opts, showModal: true, isConfirm: true });
};

const ModalManager = {
  message,
  confirm
};

export default ModalManager;
