import styled, { css } from "styled-components";

export const Container = styled.div<{ own?: boolean }>`
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
  gap: 12px;

  ${(props) =>
    props.own &&
    css`
      border-bottom-right-radius: 0;
      margin: 0 0 0 auto;
    `}
`;

export const MessageImgae = styled.div`
  width: 40px;
  height: 40px;
  background: var(--background-button-disabled);
  background-size: cover;
  border-radius: 50%;
  align-content: center;
  text-align: center;
`;

export const MessageContentLoad = styled.div`
  white-space: pre-wrap;
  overflow-wrap: break-word;
  background: transparent;
  color: var(--text-primary);
  line-height: 22px;
  font-size: 14px;
  flex-direction: row;
  display: flex;
`;
export const MessageAIContent = styled.div`
  white-space: pre-wrap;
  overflow-wrap: break-word;
  background: transparent;
  color: var(--text-primary);
  line-height: 22px;
  font-size: 14px;
  flex-direction: row;
  display: flex;
  opacity: 0;
  animation: fadeIn 2s ease-in forwards;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const MessageUserContent = styled.div`
  white-space: pre-wrap;
  overflow-wrap: break-word;
  border-radius: 15px;
  padding: 12px 13px;
  max-width: 260px;
  background: var(--background-tree-default);
  color: var(--text-secondary-1);
  line-height: 18px;
  font-size: 14px;
`;

export const MessageAILoading = styled.div`
  display: flex;
  position: relative;
  width: 80px;
  div {
    position: absolute;
    bottom: 5px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: var(--text-primary);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 4px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  div:nth-child(2) {
    left: 4px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(3) {
    left: 16px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(4) {
    left: 28px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(12px, 0);
    }
  }
`;
