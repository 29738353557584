import { ITreeNode } from "./IHVTreeView";

export const addChild = (
  nodes: ITreeNode[],
  parentId: string,
  newNode: ITreeNode,
): ITreeNode[] => {
  return nodes.map((node) => {
    if (node.id === parentId) {
      return {
        ...node,
        children: node.children ? [...node.children, newNode] : [newNode],
      };
    }
    if (node.children) {
      return { ...node, children: addChild(node.children, parentId, newNode) };
    }
    console.log(node);

    return node;
  });
};

export const updateLabel = (
  nodes: ITreeNode[],
  id: string,
  newLabel: string,
): ITreeNode[] => {
  return nodes.map((node) => {
    if (node.id === id) {
      return { ...node, itemProps: { ...node.itemProps, label: newLabel } };
    }
    if (node.children) {
      return { ...node, children: updateLabel(node.children, id, newLabel) };
    }
    return node;
  });
};

export const removeNode = (nodes: ITreeNode[], id: string): ITreeNode[] => {
  return nodes
    .filter((node) => node.id !== id)
    .map((node) => ({
      ...node,
      children: node.children ? removeNode(node.children, id) : node.children,
    }));
};
