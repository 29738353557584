import { useEffect, useMemo, useRef, useState } from "react";
import {
  CalendarModeRangeType,
  DateTime,
  UseDatePickerProps,
  SearchComparisonDateTime,
  TimePickerObj,
} from "./HVDatePicker.props";
import useSessionStorage from "../../hooks/useSessionStorage";
import {
  CALENDAR_MODE_RANGE_TYPE,
  FORMAT,
  SESSION_STORAGE_KEY,
} from "./resources/constants/constants";
import {
  getDateTime,
  createDateTime,
  formatMinuteSecond,
  formatMode,
  startOf,
  endOf,
  toDateWithTimezone,
} from "./resources/utils/utils";
import { HVText } from "../";
import "./DatePicker.css";
import styles from "./HVDatePicker.module.css";

const useDatePicker = <T extends SearchComparisonDateTime>({
  dateTime,
  setDateTime,
  availableModeList,
  onlyDisplayDateTime,
  showTimePicker = false,
  disabled = false,
  tabIndex,
  rangeSelectLimit = 90,
  usePreview = false,
}: UseDatePickerProps<T>) => {
  const datePickerRef = useRef<HTMLDivElement>(null);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(!usePreview);
  const [previousTimeZone, setPreviousTimeZone] = useState<string>("");
  const [selectedDateTime, setSelectedDateTime] = useState<DateTime>(dateTime);
  const [selectedTempDateTime, setSelectedTempDateTime] =
    useState<DateTime>(dateTime);
  const [currentTime, setCurrentTime] = useState<string>();
  const intervalCurrentTime = useRef<ReturnType<typeof setInterval> | null>(
    null,
  );

  const [sessionStorageUtc] = useSessionStorage(
    SESSION_STORAGE_KEY.TIME_ZONE,
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
  const currentTimeZone =
    sessionStorageUtc || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const {
    mode: selectedMode,
    start: selectedStart,
    end: selectedEnd,
  } = selectedTempDateTime;

  const [startHour, setStartHour] = useState<number>(12);
  const [startMinute, setStartMinute] = useState<number>(0);
  const [startSecond, setStartSecond] = useState<number>(0);
  const [startPeriod, setStartPeriod] = useState<string>("AM");

  const [endHour, setEndHour] = useState<number>(11);
  const [endMinute, setEndMinute] = useState<number>(59);
  const [endSecond, setEndSecond] = useState<number>(59);
  const [endPeriod, setEndPeriod] = useState<string>("PM");
  const [reRender, setReRender] = useState<boolean>(false);

  const timePicker: TimePickerObj = {
    start: {
      label: "from",
      hour: startHour,
      minute: startMinute,
      second: startSecond,
      period: startPeriod,
      setHour: setStartHour,
      setMinute: setStartMinute,
      setSecond: setStartSecond,
      setPeriod: setStartPeriod,
    },
    end: {
      label: "to",
      hour: endHour,
      minute: endMinute,
      second: endSecond,
      period: endPeriod,
      setHour: setEndHour,
      setMinute: setEndMinute,
      setSecond: setEndSecond,
      setPeriod: setEndPeriod,
    },
  };

  const classNames = useMemo(() => {
    const list = ["date-range-picker"];
    if (disabled) {
      list.push("disabled");
    }

    if (!usePreview) {
      list.push("disable-preview-mode");
    }
    return list.join(" ");
  }, [disabled, usePreview]);

  const onChangeMode = (currentMode: CalendarModeRangeType) => {
    const newDateTime = getDateTime(currentMode, new Date().toISOString());

    setEndHour(11);
    setEndMinute(59);
    setEndSecond(59);
    setEndPeriod("PM");

    setStartHour(12);
    setStartMinute(0);
    setStartSecond(0);
    setStartPeriod("AM");

    setSelectedTempDateTime({
      mode: currentMode,
      ...newDateTime,
    });
    setReRender(true);
    setTimeout(() => setReRender(false), 10);
  };

  const onClickOutside = (event: MouseEvent) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target as Node)
    ) {
      setShowDatePicker(false);
    }
  };

  const onChangeDate = (date: Date | null | [Date | null, Date | null]) => {
    if (date && Array.isArray(date)) {
      const [startDateTime, endDateTime] = date;
      let newDateTime: DateTime = {
        mode: CALENDAR_MODE_RANGE_TYPE.CUSTOM,
        start: startDateTime?.toISOString() || "",
        end: endDateTime?.toISOString() || "",
      };

      if (endDateTime) {
        newDateTime = {
          ...newDateTime,
          ...getDateTime(
            newDateTime.mode,
            startDateTime?.toISOString() || "",
            endDateTime?.toISOString() || "",
          ),
        };
      }
      setSelectedTempDateTime(newDateTime);
    }
  };

  const renderPeriod = useMemo(() => {
    const { mode, start, end } = selectedDateTime;

    const isSingleDateMode =
      mode === CALENDAR_MODE_RANGE_TYPE.YESTERDAY ||
      mode === CALENDAR_MODE_RANGE_TYPE.DAY_BEFORE_YESTERDAY;

    const formattedStartDate = toDateWithTimezone(
      currentTimeZone,
      new Date(start),
      {
        year: "numeric",
        month: "short",
        day: "2-digit",
      },
    );

    const formattedStartTime = showTimePicker
      ? `${startHour || 12}:${formatMinuteSecond(
          startMinute || 0,
        )}:${formatMinuteSecond(startSecond || 0)} ${startPeriod || "AM"}`
      : "";

    const formattedEndDate = toDateWithTimezone(
      currentTimeZone,
      new Date(end || start),
      {
        year: "numeric",
        month: "short",
        day: "2-digit",
      },
    );

    const formattedEndTime = showTimePicker
      ? `${endHour || 11}:${formatMinuteSecond(
          endMinute || 0,
        )}:${formatMinuteSecond(endSecond || 0)} ${endPeriod || "PM"}`
      : "";

    return (
      <>
        {mode === CALENDAR_MODE_RANGE_TYPE.TODAY && (
          <div className={styles["circle"]} />
        )}
        {!onlyDisplayDateTime && (
          <p className={styles["mode"]}>{formatMode(mode)}</p>
        )}
        <div className={styles["time-start"]}>
          {mode === CALENDAR_MODE_RANGE_TYPE.TODAY ? (
            <span>
              {formattedStartDate}&nbsp;{currentTime}
            </span>
          ) : (
            <>
              <span>
                {formattedStartDate} {formattedStartTime}
              </span>
              {(showTimePicker || !isSingleDateMode) && <span> - </span>}
              <span>
                {isSingleDateMode
                  ? ` ${formattedEndTime}`
                  : `${
                      formattedEndDate || formattedStartDate
                    } ${formattedEndTime}`}
              </span>
            </>
          )}
        </div>
      </>
    );
  }, [selectedDateTime, currentTime, currentTimeZone]);

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutside);
    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, []);

  useEffect(() => {
    return () => {
      if (intervalCurrentTime.current) {
        clearInterval(
          intervalCurrentTime.current as ReturnType<typeof setInterval>,
        );
        intervalCurrentTime.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!showDatePicker && selectedTempDateTime.mode !== dateTime.mode) {
      setSelectedTempDateTime(dateTime);
    }
  }, [dateTime.mode]);

  useEffect(() => {
    if (!showDatePicker) {
      const newDateTime = getDateTime(
        dateTime.mode,
        dateTime.start,
        dateTime.end,
      );
      setSelectedTempDateTime({
        ...dateTime,
        ...newDateTime,
      });
    }
  }, [showDatePicker]);

  const setCurrentDateTime = (_timeFormat: string) => {
    setCurrentTime(
      toDateWithTimezone(currentTimeZone, new Date(), {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }),
    );
  };

  useEffect(() => {
    if (
      dateTime.mode === CALENDAR_MODE_RANGE_TYPE.TODAY ||
      dateTime.mode === CALENDAR_MODE_RANGE_TYPE.LAST_24_HOURS
    ) {
      if (intervalCurrentTime.current === null) {
        setCurrentTime(
          toDateWithTimezone(currentTimeZone, new Date(), {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }),
        );
        intervalCurrentTime.current = setInterval(() => {
          setCurrentDateTime(` ${FORMAT.TIME_12H_2}`);
        }, 10 * 1000);
      }
    } else if (intervalCurrentTime.current) {
      clearInterval(
        intervalCurrentTime.current as ReturnType<typeof setInterval>,
      );
      intervalCurrentTime.current = null;
    }
  }, [dateTime.mode]);

  const maxDate = useMemo(() => {
    if (selectedTempDateTime.mode === CALENDAR_MODE_RANGE_TYPE.CUSTOM) {
      // The limit must be -1. Because it includes the start date
      // ex. 90 days -> 89 limit
      // ex. 365 days (1 year) -> 364 limit
      const customMaxDate = new Date(selectedStart);
      customMaxDate.setDate(customMaxDate.getDate() + (rangeSelectLimit - 1));

      const today = new Date();
      return customMaxDate > today ? today : customMaxDate;
    }

    return new Date();
  }, [selectedStart, selectedTempDateTime.mode]);

  useEffect(() => {
    if (currentTimeZone !== previousTimeZone) {
      if (intervalCurrentTime.current) {
        clearInterval(
          intervalCurrentTime.current as ReturnType<typeof setInterval>,
        );
        intervalCurrentTime.current = null;
      }
      if (intervalCurrentTime.current === null) {
        setCurrentTime(
          toDateWithTimezone(currentTimeZone, new Date(), {
            timeZone: currentTimeZone,
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }),
        );

        intervalCurrentTime.current = setInterval(
          () => setCurrentDateTime(` ${FORMAT.TIME_12H_2}`),
          10 * 1000,
        );
      }
      setPreviousTimeZone(currentTimeZone);
    }
  });

  const onClickSubmit = () => {
    const { mode, start, end } = selectedTempDateTime;
    const dateTime = getDateTime(mode, start, end);

    const startDateTime = createDateTime(
      startOf(
        new Date(toDateWithTimezone(currentTimeZone, new Date(start), {})),
        "DAY",
      ),
      startHour,
      startMinute,
      startSecond,
      startPeriod,
      currentTimeZone,
    );
    const endDateTime = createDateTime(
      end
        ? endOf(
            new Date(toDateWithTimezone(currentTimeZone, new Date(end))),
            "DAY",
          )
        : endOf(
            new Date(toDateWithTimezone(currentTimeZone, new Date(start))),
            "DAY",
          ),
      endHour,
      endMinute,
      endSecond,
      endPeriod,
      currentTimeZone,
    );

    setSelectedDateTime({
      ...selectedTempDateTime,
      ...dateTime,
      start: startDateTime,
      end: endDateTime,
    });

    setDateTime({
      ...selectedTempDateTime,
      ...dateTime,
      start: startDateTime,
      end: endDateTime,
      mode,
    } as T);

    setShowDatePicker(false);
  };

  const scrollBarChildren = () => {
    return Object.values(availableModeList || CALENDAR_MODE_RANGE_TYPE)
      .filter((item) => item !== CALENDAR_MODE_RANGE_TYPE.LAST_24_HOURS)
      .map((currentMode: CalendarModeRangeType) => (
        <button
          key={currentMode}
          aria-label={`Range ${currentMode.replaceAll("_", " ")} `}
          className={currentMode === selectedMode ? styles["active"] : ""}
          onClick={() => {
            onChangeMode(currentMode);
          }}
          tabIndex={tabIndex}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onChangeMode(currentMode);
            }
          }}
        >
          <HVText
            type="Body-sm"
            weight="Regular"
            style={{ lineHeight: "inherit", color: "inherit" }}
          >
            {formatMode(currentMode)}
          </HVText>
        </button>
      ));
  };
  return {
    showDatePicker,
    setShowDatePicker,
    classNames,
    renderPeriod,
    onChangeDate,
    scrollBarChildren,
    onClickSubmit,
    timePicker,
    reRender,
    maxDate,
    selectedMode,
    selectedStart,
    selectedEnd,
    selectedTempDateTime,
    currentTimeZone,
    onClickOutside,
    formatMinuteSecond,
    datePickerRef,
  };
};

export default useDatePicker;
