"use client";

import { Menu, Box } from "@mantine/core";
import styled from "./HVUserProfile.module.css";
import IHVUserProfile from "./IHVUserProfile";
import { HVFlex, HVText, HVAvatar, HVIcon } from "../..";
import HVPermissions from "./HVPermissions/HVPermissions";
import { IHVMenuItem } from "./IHVUserProfile";

const HVUserProfile = ({
  menuTargetProps,
  profileImageProps,
  menuDropDownProps,
  profileContainerProps,
  titleEmailContainerProps,
  title,
  titleTextProps,
  email,
  emailTextProps,
  permissions,
  menuItems,
  logoutMenuItem,
  ...rest
}: IHVUserProfile) => {
  return (
    <Menu shadow="md" width="322px" position="bottom-end" {...rest}>
      <Menu.Target {...menuTargetProps}>
        <Box className={styled.targetBox}>
          <HVAvatar item={{ size: "sm", ...profileImageProps }} group={false} />
        </Box>
      </Menu.Target>

      <Menu.Dropdown
        {...menuDropDownProps}
        className={`${styled.dropDown} ${menuDropDownProps?.className}`}
      >
        <HVFlex
          {...profileContainerProps}
          className={`${styled.profileContainer} ${profileContainerProps?.className}`}
        >
          <HVAvatar item={{ size: "lg", ...profileImageProps }} group={false} />
          <HVFlex
            {...titleEmailContainerProps}
            className={`${styled.titleEmailContainer} ${titleEmailContainerProps?.className}`}
          >
            <HVText type="Headline-2xs" weight="Bold" {...titleTextProps}>
              {title}
            </HVText>

            <HVText
              type="Body-sm"
              c="var(--hv-text-normal-tertiary)"
              {...emailTextProps}
            >
              {email}
            </HVText>
          </HVFlex>

          <HVPermissions permissions={permissions} />
        </HVFlex>

        <HVFlex h="1px" bg="var(--hv-border-default)" mx="8px" />
        {menuItems.map(
          (
            {
              link,
              leftSection,
              rightSection,
              label,
              labelProps,
              onClick,
              ...rest
            }: IHVMenuItem,
            index,
          ) => {
            return (
              <Menu.Item
                component="a"
                href={link}
                leftSection={leftSection || <HVIcon name="empty" />}
                rightSection={rightSection || <HVIcon name="link" />}
                classNames={styled}
                key={index.toString()}
                onClick={onClick}
                {...rest}
              >
                <HVText type="Body-sm" c="inherit" {...labelProps}>
                  {label}
                </HVText>
              </Menu.Item>
            );
          },
        )}
        <HVFlex h="1px" bg="var(--hv-border-default)" mx="8px" />
        <Menu.Item
          {...logoutMenuItem}
          leftSection={logoutMenuItem.leftSection || <HVIcon name="log-out" />}
          rightSection={logoutMenuItem.rightSection}
          classNames={styled}
          onClick={logoutMenuItem?.onClick}
        >
          <HVText type="Body-sm" c="inherit">
            {logoutMenuItem.label}
          </HVText>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default HVUserProfile;
