"use client";

import IHVPermission from "./IHVPermission";
import { HVFlex, HVText } from "../..";
import { Box, MantineColorScheme, useMantineColorScheme } from "@mantine/core";
import styles from "./HVPermission.module.css";

import {
  SightMind,
  OnCloud,
  CloudPortal,
  DMPro,
  ACaaS,
  FlexAI,
  SightMindDark,
  OnCloudDark,
  CloudPortalDark,
  DMProDark,
  ACaaSDark,
  FlexAIDark,
} from "../../../assets/images/Apps";

const getImageByType = (
  colorScheme: MantineColorScheme,
  appType: IHVPermission["appType"],
) => {
  switch (appType) {
    case "OC":
      return colorScheme === "dark" ? <OnCloudDark /> : <OnCloud />;
    case "DM":
      return colorScheme === "dark" ? <DMProDark /> : <DMPro />;
    case "AC":
      return colorScheme === "dark" ? <ACaaSDark /> : <ACaaS />;
    case "CP":
      return colorScheme === "dark" ? <CloudPortalDark /> : <CloudPortal />;
    case "FA":
      return colorScheme === "dark" ? <FlexAIDark /> : <FlexAI />;
    case "SM":
      return colorScheme === "dark" ? <SightMindDark /> : <SightMind />;
  }
};
const HVPermission = ({
  containerProps,
  appType,
  imgContainerProps,
  roleName,
  roleTextProps,
}: IHVPermission) => {
  let theme = useMantineColorScheme();
  return (
    <HVFlex
      direction="row"
      gap="var(--mantine-spacing-1)"
      align="center"
      className={styles.container}
      {...containerProps}
    >
      <Box h={"28px"} w={"28px"} {...imgContainerProps}>
        {getImageByType(theme.colorScheme, appType)}
      </Box>
      <HVText
        type="Body-xs"
        c="var(--hv-text-normal-secondary)"
        lineClamp={1}
        {...roleTextProps}
      >
        {roleName}
      </HVText>
    </HVFlex>
  );
};

export default HVPermission;
