import { TypeTimeInterval } from "@custom-types/Types";
import {
  DEFAULT_COUNT_FOR_DURATION_HOUR,
  TIME_INTERVAL,
  TYPE_TIME_INTERVAL
} from "@resources/Constants";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";

interface ChartIntervalParams {
  baseTime: {
    start: string;
    end: string;
    initial?: boolean;
  };
  isForReport?: boolean;
}
export const useChartFilterInterval = (params: ChartIntervalParams) => {
  const getType = (time: ChartIntervalParams["baseTime"]) => {
    const { start, end } = time;
    const period = moment(end).diff(start, "day");
    if (period >= DEFAULT_COUNT_FOR_DURATION_HOUR) {
      return TYPE_TIME_INTERVAL.DAY;
    }
    return TYPE_TIME_INTERVAL.HOUR;
  };
  const [type, setType] = useState<TypeTimeInterval>(getType(params.baseTime));
  const time = useMemo(() => {
    if (type === TYPE_TIME_INTERVAL.DAY) {
      return TIME_INTERVAL.DAY_1;
    }
    if (type === TYPE_TIME_INTERVAL.WEEK) {
      return TIME_INTERVAL.DAY_7;
    }

    if (type === TYPE_TIME_INTERVAL.MONTH) {
      return TIME_INTERVAL.MONTH_1;
    }

    return TIME_INTERVAL.HOUR_1;
  }, [type]);

  useEffect(() => {
    if (params.isForReport && !params.baseTime.initial) return;

    setType(getType(params.baseTime));
  }, [params.baseTime.start, params.baseTime.end]);

  const mapTypeToTimeInterval = (type: TypeTimeInterval) => {
    if (type === TYPE_TIME_INTERVAL.DAY) {
      return TIME_INTERVAL.DAY_1;
    }

    if (type === TYPE_TIME_INTERVAL.WEEK) {
      return TIME_INTERVAL.DAY_7;
    }

    if (type === TYPE_TIME_INTERVAL.MONTH) {
      return TIME_INTERVAL.MONTH_1;
    }

    return TIME_INTERVAL.HOUR_1;
  };

  const getTypeTime = (params: ChartIntervalParams) => {
    const type = getType(params.baseTime);
    setType(getType(params.baseTime));
    return mapTypeToTimeInterval(type);
  };

  return {
    time,
    type,
    setType,
    getTypeTime,
    mapTypeToTimeInterval,
    getType
  };
};
