import {
  CALENDAR_MODE_RANGE_TYPE,
  FORMAT,
} from "../resources/constants/constants";
import {
  IconArrowTimeInputChevronDown,
  IconArrowTimeInputChevronUp,
} from "../../../assets/icons";
import styles from "./TimePicker.module.css";
import {
  CalendarModeRangeType,
  DateTime,
  TimePickerObj,
} from "../HVDatePicker.props";
import { formatDate } from "../resources/utils/utils";
import { HVFlex, HVText } from "../../";

interface TimePickerProps {
  timePicker: TimePickerObj;
  selectedMode: CalendarModeRangeType;
  selectedTempDateTime: DateTime;
  formatMinuteSecond: (value: number) => string;
}

const TimePicker = ({
  timePicker,
  selectedMode,
  selectedTempDateTime,
  formatMinuteSecond,
}: TimePickerProps) => {
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <HVFlex direction={"column"} className={styles["time-range"]}>
      {(Object.keys(timePicker) as Array<keyof typeof timePicker>).map(
        (type) => {
          const isTodayMode = selectedMode === CALENDAR_MODE_RANGE_TYPE.TODAY;
          const disabledClass = isTodayMode ? styles["disabled"] : "";

          return (
            <HVFlex
              direction={"column"}
              key={String(type)}
              className={styles["time-group"]}
            >
              <HVFlex direction={"row"} className={styles["time-label-date"]}>
                <div className={styles["time-label"]}>
                  <HVText
                    type="Body-sm"
                    weight="Semibold"
                    style={{
                      fontWeight: "inherit",
                      lineHeight: "inherit",
                      color: "inherit",
                    }}
                  >
                    {capitalizeFirstLetter(timePicker[type].label)}
                  </HVText>
                </div>
                <div className={styles["time-date"]}>
                  <HVText
                    type="Body-sm"
                    weight="Semibold"
                    style={{
                      fontWeight: "inherit",
                      lineHeight: "inherit",
                      color: "inherit",
                    }}
                  >
                    {formatDate(
                      new Date(
                        type === "start"
                          ? selectedTempDateTime.start
                          : selectedTempDateTime.end ||
                            selectedTempDateTime.start,
                      ),
                      FORMAT.MONTH_DATE_YEAR_1,
                    )}
                  </HVText>
                </div>
              </HVFlex>

              <HVFlex direction={"row"} className={styles["time-input-group"]}>
                <HVFlex
                  direction={"row"}
                  className={styles["input-arrow-container"]}
                >
                  <input
                    aria-label={`${String(type)} hour`}
                    type="number"
                    value={timePicker[type].hour}
                    className={disabledClass}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      timePicker[type].setHour(
                        value >= 1 && value <= 12
                          ? value
                          : timePicker[type].hour,
                      );
                    }}
                    min="1"
                    max="12"
                    disabled={isTodayMode}
                  />
                  <HVFlex
                    direction={"column"}
                    className={styles["arrow-container"]}
                  >
                    <button
                      className={disabledClass}
                      onClick={() =>
                        timePicker[type].setHour(
                          Math.min(timePicker[type].hour + 1, 12),
                        )
                      }
                      aria-label={`Increment ${String(type)} hour`}
                      disabled={isTodayMode}
                    >
                      <IconArrowTimeInputChevronUp />
                    </button>
                    <button
                      className={disabledClass}
                      onClick={() =>
                        timePicker[type].setHour(
                          Math.max(timePicker[type].hour - 1, 1),
                        )
                      }
                      aria-label={`Decrement ${String(type)} hour`}
                      disabled={isTodayMode}
                    >
                      <IconArrowTimeInputChevronDown />
                    </button>
                  </HVFlex>
                </HVFlex>
                <span>:</span>
                <HVFlex
                  direction={"row"}
                  className={styles["input-arrow-container"]}
                >
                  <input
                    aria-label={`${String(type)} minute`}
                    className={disabledClass}
                    type="number"
                    value={formatMinuteSecond(timePicker[type].minute)}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      timePicker[type].setMinute(
                        value >= 0 && value <= 59
                          ? value
                          : timePicker[type].minute,
                      );
                    }}
                    min="0"
                    max="59"
                    disabled={isTodayMode}
                  />
                  <HVFlex
                    direction={"column"}
                    className={styles["arrow-container"]}
                  >
                    <button
                      className={disabledClass}
                      onClick={() =>
                        timePicker[type].setMinute(
                          Math.min(timePicker[type].minute + 1, 59),
                        )
                      }
                      aria-label={`Increment ${String(type)} minute`}
                      disabled={isTodayMode}
                    >
                      <IconArrowTimeInputChevronUp />
                    </button>
                    <button
                      className={disabledClass}
                      onClick={() =>
                        timePicker[type].setMinute(
                          Math.max(timePicker[type].minute - 1, 0),
                        )
                      }
                      aria-label={`Decrement ${String(type)} minute`}
                      disabled={isTodayMode}
                    >
                      <IconArrowTimeInputChevronDown />
                    </button>
                  </HVFlex>
                </HVFlex>
                <span>:</span>
                <HVFlex
                  direction={"row"}
                  className={styles["input-arrow-container"]}
                >
                  <input
                    aria-label={`${String(type)} second`}
                    className={disabledClass}
                    type="number"
                    value={formatMinuteSecond(timePicker[type].second)}
                    onChange={(e) => {
                      const value = Number(e.target.value);

                      timePicker[type].setSecond(
                        value >= 0 && value <= 59
                          ? value
                          : timePicker[type].second,
                      );
                    }}
                    min="0"
                    max="59"
                    disabled={isTodayMode}
                  />
                  <HVFlex
                    direction={"column"}
                    className={styles["arrow-container"]}
                  >
                    <button
                      className={disabledClass}
                      onClick={() =>
                        timePicker[type].setSecond(
                          Math.min(timePicker[type].second + 1, 59),
                        )
                      }
                      aria-label={`Increment ${String(type)} second`}
                      disabled={isTodayMode}
                    >
                      <IconArrowTimeInputChevronUp />
                    </button>
                    <button
                      className={disabledClass}
                      onClick={() =>
                        timePicker[type].setSecond(
                          Math.max(timePicker[type].second - 1, 0),
                        )
                      }
                      aria-label={`Decrement ${String(type)} second`}
                      disabled={isTodayMode}
                    >
                      <IconArrowTimeInputChevronDown />
                    </button>
                  </HVFlex>
                </HVFlex>

                <HVFlex direction={"row"} className={styles["am-pm-toggle"]}>
                  <button
                    className={`${styles["toggle-button"]} ${
                      timePicker[type].period === "AM" ? styles["active"] : ""
                    }`}
                    onClick={() => timePicker[type].setPeriod("AM")}
                    aria-label={`${String(type)} AM`}
                    disabled={isTodayMode}
                  >
                    {"AM"}
                  </button>
                  <button
                    className={`${styles["toggle-button"]} ${
                      timePicker[type].period === "PM" ? styles["active"] : ""
                    }`}
                    onClick={() => timePicker[type].setPeriod("PM")}
                    aria-label={`${String(type)} PM`}
                    disabled={isTodayMode}
                  >
                    {"PM"}
                  </button>
                </HVFlex>
              </HVFlex>
            </HVFlex>
          );
        },
      )}
    </HVFlex>
  );
};

export default TimePicker;
