import { useCallback } from "react";
import { notifications } from "@mantine/notifications";

import styles from "./HVSnackBar.module.css";
import HVText from "../HVText/HVText";
import IHVSnackBar from "./IHVSnackBar";
import HVFlex from "../HVFlex/HVFlex";
import HVIcon from "../HVIcon/HVIcon";

const getIconByVariant = (variant: IHVSnackBar["variant"]) => {
  switch (variant) {
    case "online-success":
      return (
        <HVIcon
          name="online-success-filled"
          svgProps={{
            width: 21,
            height: 20,
            stroke: "Inherit",
            fill: "Inherit",
          }}
        />
      );
    case "warning-error":
      return (
        <HVIcon
          name="warning-error"
          svgProps={{
            width: 21,
            height: 20,
            stroke: "Inherit",
            fill: "Inherit",
          }}
        />
      );
    case "information":
      return (
        <HVIcon
          name="information-filled"
          svgProps={{
            width: 21,
            height: 20,
            stroke: "Inherit",
            fill: "Inherit",
          }}
        />
      );
    case "fail":
      return (
        <HVIcon
          name="fail-snackbar"
          svgProps={{
            width: 21,
            height: 20,
            stroke: "Inherit",
            fill: "Inherit",
          }}
        />
      );
    default:
      return null;
  }
};

const useHVSnackbar = () => {
  const showSnackbar = useCallback(
    ({
      id,
      title,
      message,
      type = "Toast",
      variant,
      customAction,
      closeButtonProps,
      ...rest
    }: IHVSnackBar) => {
      notifications.show({
        id: id,
        title: title && (
          <HVText weight="Bold" c={"var(--hv-snack-bar-text-default)"}>
            {title}
          </HVText>
        ),
        message: (
          <HVText type="Body-sm" c={"var(--hv-snack-bar-text-default)"}>
            {message}
          </HVText>
        ),
        withCloseButton: type !== "Toast",
        icon: variant && getIconByVariant(variant),
        closeButtonProps: {
          className: styles.closeButton,
          children: customAction?.actionLabel && (
            <HVFlex onClick={customAction?.action}>
              <HVText
                type="Body-sm"
                weight="Bold"
                c={"var(--hv-snack-bar-text-default)"}
              >
                {customAction.actionLabel}
              </HVText>
            </HVFlex>
          ),
          ...closeButtonProps,
        },
        position: "top-right",
        classNames: styles,
        ...rest,
      });
    },
    [],
  );

  const hideSnackbar = useCallback(({ id }: IHVSnackBar) => {
    notifications.hide(id);
  }, []);

  return { showSnackbar, hideSnackbar };
};

export default useHVSnackbar;
