export const ICON = {
  SIZE_12: {
    width: 12,
    height: 12
  },
  SIZE_16: {
    width: 16,
    height: 16
  },
  SIZE_20: {
    width: 20,
    height: 20
  },
  SIZE_24: {
    width: 24,
    height: 24
  },
  SIZE_28: {
    width: 28,
    height: 28
  },
  SIZE_38: {
    width: 38,
    height: 38
  },
  SIZE_40: {
    width: 40,
    height: 40
  },
  SIZE_58: {
    width: 58,
    height: 58
  },
  SIZE_60: {
    width: 60,
    height: 60
  },
  SIZE_72: {
    width: 72,
    height: 72
  }
} as const;
