import useDetectOutsideClick from "@hooks/useDetectOutsideClick";
import _ from "lodash";
import { useLang } from "@hooks/useLang";
import ModalManager from "@utils/ModalManager";
import { HVIcon } from "@repo/HVComponents";
import { useState, useRef } from "react";
import * as S from "../TopMenu.styled";
import { ICON } from "../../../../constants/icons";

const DEBOUNCE_TIME_MS = 100;
export type MORE_TYPE = "EDIT_FILTER_NAME" | "REMOVE_FILTER";

interface MoreButtonProps {
  onChange: (status: MORE_TYPE) => void;
  disabled?: boolean;
}

export const MoreButton = (props: MoreButtonProps) => {
  const { translate } = useLang();
  const { disabled = false } = props;
  const [isOpen, setIsOpen] = useState(false);
  const rootRef = useRef(null);
  useDetectOutsideClick(rootRef, () => setIsOpen(false));

  const handleClickItem = _.debounce(
    (e: React.MouseEvent<HTMLUListElement>) => {
      e.stopPropagation();
      const status = (e.target as HTMLElement).dataset.id as MORE_TYPE;
      if (status === "REMOVE_FILTER") {
        ModalManager.confirm(translate("analytics_remove_desc"), {
          title: translate("analytics_remove_title"),
          labelConfirm: translate("remove"),
          labelCancel: translate("cancel"),
          onSubmit: () => {
            setIsOpen(false);
            props.onChange(status);
          }
        });
      } else {
        setIsOpen(false);
        props.onChange(status);
      }
    },
    DEBOUNCE_TIME_MS
  );

  return (
    <S.MenuButton
      isActive={isOpen}
      disabled={disabled}
      ref={rootRef}
      style={{ width: "32px", position: "relative" }}
      onClick={() => setIsOpen(true)}
      data-tooltip={translate("more")}
    >
      <HVIcon name="more" svgProps={{ ...ICON.SIZE_16 }} />
      {isOpen && (
        <S.DropDownList onClick={handleClickItem}>
          <li data-id={"EDIT_FILTER_NAME" as const}>
            {translate("edit_name")}
          </li>
          <li data-id={"REMOVE_FILTER" as const}>{translate("remove")}</li>
        </S.DropDownList>
      )}
    </S.MenuButton>
  );
};
