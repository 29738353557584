import { Skeleton } from "@components/common/skeleton";
import * as S from "../chart/ChartCard/ChartCard.styled";

export const ChartSkeleton = () => {
  const backgroundColor = "var(--background-skeleton-0)";

  return (
    <Skeleton isLoading>
      <S.Container style={{ backgroundColor: "#FFFFFF0D" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            padding: "20px 24px"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              gap: 8
            }}
          >
            <div
              className="skeleton"
              style={{
                width: 200,
                height: 20,
                backgroundColor
              }}
            />
            <div
              className="skeleton"
              style={{
                width: 20,
                height: 20,
                backgroundColor
              }}
            />
          </div>

          <div
            style={{
              width: "100%",
              height: 288,
              marginTop: 16
            }}
          />
        </div>
      </S.Container>
    </Skeleton>
  );
};
