import styled, { css } from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 0 24px 24px 8px;
`;
export const GenAI = styled.div`
  width: 368px;
  position: fixed;
  margin: 0 24px 0 12px;
  height: calc(100vh - 76px);
  right: 0;
  display: flex;
  transition: all 0.25s;
`;

export const Content = styled.div<{ isShow?: boolean }>`
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: var(--surface-widget);
  border-radius: 12px;
  transition: all 0.25s;
  ${(props) =>
    props.isShow &&
    css`
      width: calc(100vw - 484px);
    `};
`;

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 700;
  color: var(--text-primary);
`;

export const ChartList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const NoticeWrap = styled.div`
  width: 100%;
  height: calc(100vh - 210px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background-color: var(--background-analytics-notice-wrap);
  border: 1px solid var(--border-default);
  border-radius: 12px;

  svg path,
  circle {
    stroke: var(--icon-emphasis);
  }
`;

export const NoticeTitile = styled.h1`
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
`;

export const NoticeMessage = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: var(--text-secondary-1);
`;
