import { useMemo, useState, useEffect, useRef } from "react";
import { DateTime } from "@components/common/calendar/date-range-picker/Interfaces";
import moment from "moment";
import _ from "lodash";
import { DEFAULT_DATE_TIME } from "@redux/System";
import { CalendarModeRangeType } from "@custom-types/Types";
import { HVIcon, HVDatePicker } from "@repo/HVComponents";
import { FORMAT } from "@resources/Constants";
import { useCurrentTimezone } from "@hooks/useCurrentTimezone";
import * as S from "../TopMenu.styled";
import { ICON } from "../../../../constants/icons";

interface CalendarButtonProps {
  dateTime?: DateTime;
  onChange: (date: {
    start: string;
    end: string;
    mode: CalendarModeRangeType;
  }) => void;
}
export const CalendarButton = (props: CalendarButtonProps) => {
  const { dateTime } = props;
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const { currentTimeZone, convertByTimezone } = useCurrentTimezone();
  const datePickerRef = useRef<HTMLDivElement>(null);

  const period = useMemo(() => {
    if (dateTime) {
      const range = [];
      const startMoment = moment(dateTime.start).tz(currentTimeZone);
      const endMoment = moment(dateTime.end).tz(currentTimeZone);
      const currentMoment = moment().tz(currentTimeZone);
      if (
        startMoment.get("year") !== endMoment.get("year") ||
        startMoment.get("year") !== currentMoment.get("year") ||
        endMoment.get("year") !== currentMoment.get("year")
      ) {
        range.push(startMoment.format(FORMAT.MONTH_DATE_YEAR_1));
        range.push(endMoment.format(FORMAT.MONTH_DATE_YEAR_1));
      } else {
        range.push(startMoment.format(FORMAT.DATE5));
        range.push(endMoment.format(FORMAT.DATE5));
      }

      return _.uniq(range).join(" - ");
    }
    return "Period";
  }, [dateTime]);

  const handleChangeDate = (selectedDate: DateTime) => {
    if (dateTime) {
      // if prev date is saved, compare with changed date
      const origin = {
        start: convertByTimezone(dateTime.start),
        end: convertByTimezone(dateTime.end)
      };
      const changed = {
        start: convertByTimezone(selectedDate.start),
        end: convertByTimezone(selectedDate.end)
      };

      if (!_.isEqual(origin, changed)) {
        props.onChange(selectedDate);
      }
    } else {
      // if prev date is not saved, save changed date
      props.onChange(selectedDate);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as Node)
      ) {
        setIsShowCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={datePickerRef}>
      <S.MenuButton
        style={{ padding: "0 15px 0 11px", position: "relative" }}
        onClick={() => setIsShowCalendar(!isShowCalendar)}
      >
        <HVIcon name="calendar" svgProps={{ ...ICON.SIZE_16 }} />
        {period}
        {isShowCalendar && (
          <S.LayerWrap onClick={(e) => e.stopPropagation()}>
            <HVDatePicker
              dateTime={dateTime ?? DEFAULT_DATE_TIME}
              setDateTime={handleChangeDate}
              availableModeList={undefined}
              showTimePicker={false}
              disabled={false}
              tabIndex={undefined}
              rangeSelectLimit={undefined}
              usePreview={false}
              showModePicker
              setShowDatePicker={setIsShowCalendar}
            />
          </S.LayerWrap>
        )}
      </S.MenuButton>
    </div>
  );
};
