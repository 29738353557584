"use client";

import { forwardRef } from "react";
import { Button, createPolymorphicComponent } from "@mantine/core";
import IHVButtonProps from "./IHVButtonProps";
import style from "./HVButton.module.css";
import HVText from "../HVText/HVText";
import clsx from "clsx";

const HVButton = createPolymorphicComponent<"button", IHVButtonProps>(
  forwardRef<HTMLButtonElement, IHVButtonProps>(
    (
      {
        variant = "Primary",
        children,
        text,
        textProps,
        ...rest
      }: IHVButtonProps,
      ref,
    ) => (
      <Button
        component="button"
        ref={ref}
        variant={variant}
        className={clsx(style.root, style[variant])}
        {...rest}
      >
        {text && (
          <HVText type="Body-sm" c="inherit" {...textProps}>
            {text}
          </HVText>
        )}
        {children}
      </Button>
    ),
  ),
);

export default HVButton;
