import { ComparisonType } from "@components/widgets/base/widget";
import {
  CalendarModeRangeType,
  ChartTooltipParam,
  EventManagementData,
  EventType,
  TimeInterval,
  TreeMaxSelected,
  WidgetCategory,
  WidgetType
} from "@custom-types/Types";
import {
  AI_TYPE,
  CALENDAR_MODE_RANGE_TYPE,
  COMPARISON_TYPE,
  DATE_PERIOD,
  EVENT_TYPE,
  FORMAT,
  SESSION_STORAGE_KEY,
  TIME_INTERVAL,
  WIDGET_CATEGORY,
  WIDGET_CHIP_RULES,
  WIDGET_RULE_CHIP_SIZES,
  WIDGET_TYPE
} from "@resources/Constants";
import { DefaultLabelFormatterCallbackParams } from "echarts";
import { isUndefined } from "lodash";
import moment, { unitOfTime } from "moment";
import momentTimeZone from "moment-timezone";

export const numberSymbol = (value?: number | string): [string, string] => {
  if (typeof value === "number") {
    const symbols = ["", "K", "M", "B"];

    let digitCount = parseInt(value.toString(), 10).toString().length;
    if (value < 0) {
      digitCount -= 1;
    }
    const factor = parseInt(((digitCount - 1) / 3).toString(), 10);
    const symbolDigit = 1000 ** factor;
    const finalValue = parseFloat(
      (value / symbolDigit).toString().substring(0, 4)
    );
    return [finalValue.toString(), symbols[factor]];
  }
  return ["-", ""];
};

export const getPercent = (
  partial: number | string | DefaultLabelFormatterCallbackParams["data"],
  total?: number,
  decimalPoint = 0
): string => {
  const ret = (Number(partial) / Number(total)) * 100;
  if (!Number.isFinite(ret)) {
    return "-";
  }
  return ret.toFixed(decimalPoint);
};

export const getComparisonPercent = (
  value: number,
  base: number,
  decimalPoint = 0
): string => {
  if (value === base) {
    return "0";
  }
  return getPercent(value - base, base, decimalPoint);
};

export const sumArrNum = (arr: number[]): number => {
  return arr.reduce((a, b) => a + b, 0);
};

export const getComparisonType = (
  value: number,
  base: number | undefined,
  kpi?: number
): ComparisonType => {
  if (kpi) {
    if (value > kpi) {
      return COMPARISON_TYPE.INCREASE;
    }
    if (value < kpi) {
      return COMPARISON_TYPE.DECREASE;
    }
    return COMPARISON_TYPE.EQUAL;
  }

  if (isUndefined(base)) {
    if (value > 0) {
      return COMPARISON_TYPE.INCREASE;
    }
    return COMPARISON_TYPE.EQUAL;
  }
  const diff = value - base;
  if (diff > 0) {
    return COMPARISON_TYPE.INCREASE;
  }
  if (diff < 0) {
    return COMPARISON_TYPE.DECREASE;
  }
  return COMPARISON_TYPE.EQUAL;
};

export const convertWidgetTypeToAI = (type: WidgetType) => {
  switch (type) {
    case WIDGET_TYPE.CUMULATE_PEOPLECOUNTS:
    case WIDGET_TYPE.STAYING_PEOPLECOUNTS:
    case WIDGET_TYPE.ENTRY_EXIT_PEOPLECOUNTS:
    case WIDGET_TYPE.RANK_PEOPLECOUNTS:
      return AI_TYPE.PEOPLE_COUNT;
    case WIDGET_TYPE.MES_MANAGEMENT:
    case WIDGET_TYPE.CHECKOUT_QUEUE:
    case WIDGET_TYPE.NUMBER_OF_PEOPLE_BY_AREA:
      return AI_TYPE.QUEUE_MANAGEMENT;
    case WIDGET_TYPE.SHOPPING_CART_QUEUE_MANAGEMENT:
    case WIDGET_TYPE.SHOPPING_CART_CHECKOUT_QUEUE:
      return AI_TYPE.SHOPPING_CART_QUEUE_MANAGEMENT;
    case WIDGET_TYPE.VEHICLE_QUEUE:
      return AI_TYPE.VEHICLE_QUEUE_MANAGEMENT;
    case WIDGET_TYPE.SLIP_AND_FALL:
    case WIDGET_TYPE.SLIP_AND_FALL_STATISTICS:
      return AI_TYPE.SLIP_AND_FALL;
    case WIDGET_TYPE.TEMPERATURE_EVENT:
    case WIDGET_TYPE.TEMPERATURE_STATISTICS:
      return AI_TYPE.TEMPERATURE_DETECTION;
    case WIDGET_TYPE.STOPPED_VEHICLE_EVENT:
    case WIDGET_TYPE.STOPPED_VEHICLE_STATISTICS:
      return AI_TYPE.STOPPED_VEHICLE;
    case WIDGET_TYPE.SLOW_TRAFFIC_STATISTICS:
    case WIDGET_TYPE.TRAFFIC_JAM_EVENT:
      return AI_TYPE.TRAFFIC_JAM;
    case WIDGET_TYPE.CUMULATE_VEHICLECOUNTS:
    case WIDGET_TYPE.ENTRY_EXIT_VEHICLECOUNTS:
    case WIDGET_TYPE.STAYING_VEHICLECOUNTS:
      return AI_TYPE.VEHICLE_COUNT;
    case WIDGET_TYPE.CROWD_SAFETY_STATISTICS:
      return AI_TYPE.CROWD_COUNT;
    case WIDGET_TYPE.HEATMAP_PEOPLE:
      return AI_TYPE.HEATMAP;
    case WIDGET_TYPE.VEHICLE_HEATMAP:
      return AI_TYPE.VEHICLE_HEATMAP;
    case WIDGET_TYPE.SHOPPING_CART_HEATMAP:
      return AI_TYPE.SHOPPING_CART_HEATMAP;
    case WIDGET_TYPE.FORKLIFT_SPEED_STATISTICS:
    case WIDGET_TYPE.FORKLIFT_SPEED_EVENT:
      return AI_TYPE.FORKLIFT_SPEED;
    case WIDGET_TYPE.CUMULATE_SHOPPING_CART_COUNTS:
    case WIDGET_TYPE.SHOPPING_CART_COUNT:
      return AI_TYPE.SHOPPING_CART_COUNT;
    case WIDGET_TYPE.SHOPPING_CART_LOSS_EVENT:
      return AI_TYPE.SHOPPING_CART_LOSS;
    default:
      return undefined;
  }
};

export const getWidgetCategoryName = (
  widgetCategory: WidgetCategory
): string => {
  switch (widgetCategory) {
    case WIDGET_CATEGORY.ALL:
      return "widget.category.all";
    case WIDGET_CATEGORY.PEOPLE_MANAGEMENT:
      return "widget.category.people_management";
    case WIDGET_CATEGORY.SEAMLESS_OPERATION:
      return "widget.category.seamless_operation";
    case WIDGET_CATEGORY.FACILITY_MANAGEMENT:
      return "widget.category.facility_management";
    case WIDGET_CATEGORY.MANAGEMENT_MAP:
      return "widget.category.management_map";
    case WIDGET_CATEGORY.VEHICLE_MANAGEMENT:
      return "widget.category.vehicle_management";
    case WIDGET_CATEGORY.SAFETY_MANAGEMENT:
      return "widget.category.safety_management";
    default:
      return "none";
  }
};

export const getMaxSelected = (widgetType?: WidgetType): TreeMaxSelected => {
  const errorMessages = {
    cameraMessage: "error_max_camera_selected",
    ruleMessage: "error_max_rule_selected"
  };
  switch (widgetType) {
    case WIDGET_TYPE.CUMULATE_PEOPLECOUNTS:
      return {
        ...errorMessages,
        camera: undefined,
        rule: 30
      };
    case WIDGET_TYPE.STAYING_PEOPLECOUNTS:
      return {
        ...errorMessages,
        camera: undefined,
        rule: 30
      };
    case WIDGET_TYPE.NUMBER_OF_PEOPLE_BY_AREA:
      return {
        ...errorMessages,
        camera: 12,
        rule: 36
      };
    case WIDGET_TYPE.RANK_PEOPLECOUNTS:
      return {
        ...errorMessages,
        camera: 12,
        rule: 24
      };
    case WIDGET_TYPE.CHECKOUT_QUEUE:
      return {
        ...errorMessages,
        camera: undefined,
        rule: 10
      };
    case WIDGET_TYPE.TEMPERATURE_EVENT:
      return {
        ...errorMessages,
        camera: undefined,
        rule: 10
      };
    case WIDGET_TYPE.TEMPERATURE_STATISTICS:
      return {
        ...errorMessages,
        camera: undefined,
        rule: 10
      };
    case WIDGET_TYPE.MES_MANAGEMENT:
      return {
        ...errorMessages,
        camera: undefined,
        rule: 100
      };
    case WIDGET_TYPE.SHOPPING_CART_LOSS_EVENT:
    case WIDGET_TYPE.SLIP_AND_FALL:
      return {
        ...errorMessages,
        camera: undefined,
        rule: 8
      };
    case WIDGET_TYPE.CROWD_SAFETY_STATISTICS:
      return {
        ...errorMessages,
        camera: undefined,
        rule: 8
      };
    case WIDGET_TYPE.STOPPED_VEHICLE_EVENT:
      return {
        ...errorMessages,
        camera: undefined,
        rule: 8
      };
    case WIDGET_TYPE.STOPPED_VEHICLE_STATISTICS:
      return {
        ...errorMessages,
        camera: undefined,
        rule: 8
      };
    case WIDGET_TYPE.CUMULATE_VEHICLECOUNTS:
      return {
        ...errorMessages,
        camera: undefined,
        rule: 100
      };
    case WIDGET_TYPE.TRAFFIC_JAM_EVENT:
      return {
        ...errorMessages,
        camera: undefined,
        rule: 100
      };
    case WIDGET_TYPE.FORKLIFT_SPEED_EVENT:
      return {
        ...errorMessages,
        camera: 100,
        rule: 100
      };
    case WIDGET_TYPE.HEATMAP_PEOPLE:
    case WIDGET_TYPE.VEHICLE_HEATMAP:
    case WIDGET_TYPE.SHOPPING_CART_HEATMAP:
      return {
        ...errorMessages,
        camera: 1,
        rule: 1
      };
    case WIDGET_TYPE.SLIP_AND_FALL_STATISTICS:
      return {
        ...errorMessages,
        camera: undefined,
        rule: 8
      };
    case WIDGET_TYPE.SLOW_TRAFFIC_STATISTICS:
      return {
        ...errorMessages,
        camera: undefined,
        rule: 8
      };
    case WIDGET_TYPE.FORKLIFT_SPEED_STATISTICS:
      return {
        ...errorMessages,
        camera: undefined,
        rule: 8
      };
    default:
      return {
        ...errorMessages,
        camera: undefined,
        rule: undefined
      };
  }
};

export const getRuleTypeName = (widgetType?: WidgetType): string => {
  switch (widgetType) {
    case WIDGET_TYPE.CUMULATE_PEOPLECOUNTS:
    case WIDGET_TYPE.STAYING_PEOPLECOUNTS:
    case WIDGET_TYPE.ENTRY_EXIT_PEOPLECOUNTS:
    case WIDGET_TYPE.RANK_PEOPLECOUNTS:
      return "ai_rules.people_count";
    case WIDGET_TYPE.SHOPPING_CART_COUNT:
    case WIDGET_TYPE.CUMULATE_SHOPPING_CART_COUNTS:
      return "ai_rules.shopping_cart_count";
    case WIDGET_TYPE.CUMULATE_VEHICLECOUNTS:
    case WIDGET_TYPE.ENTRY_EXIT_VEHICLECOUNTS:
    case WIDGET_TYPE.STAYING_VEHICLECOUNTS:
      return "ai_rules.vehicle_count";
    case WIDGET_TYPE.CHECKOUT_QUEUE:
    case WIDGET_TYPE.NUMBER_OF_PEOPLE_BY_AREA:
    case WIDGET_TYPE.MES_MANAGEMENT:
      return "ai_rules.queue_management";
    case WIDGET_TYPE.SHOPPING_CART_CHECKOUT_QUEUE:
      return "ai_rules.shopping_cart_queue_management";
    case WIDGET_TYPE.VEHICLE_QUEUE:
      return "ai_rules.vehicle_queue_management";
    case WIDGET_TYPE.SLIP_AND_FALL_STATISTICS:
      return "ai_rules.slip_and_fall";
    case WIDGET_TYPE.CROWD_SAFETY_STATISTICS:
      return "ai_rules.crowd_count";
    case WIDGET_TYPE.STOPPED_VEHICLE_STATISTICS:
      return "ai_rules.stopped_vehicle";
    case WIDGET_TYPE.SLOW_TRAFFIC_STATISTICS:
      return "ai_rules.traffic_jam";
    case WIDGET_TYPE.FORKLIFT_SPEED_STATISTICS:
      return "ai_rules.forklift_speed";
    case WIDGET_TYPE.HEATMAP_PEOPLE:
      return "ai_rules.heatmap";
    case WIDGET_TYPE.VEHICLE_HEATMAP:
      return "ai_rules.vehicle_heatmap";
    case WIDGET_TYPE.SHOPPING_CART_HEATMAP:
      return "ai_rules.shopping_cart_heatmap";
    default:
      return "ai_rules.none";
  }
};

export const getWidgetName = (widgetType?: WidgetType): string => {
  switch (widgetType) {
    case WIDGET_TYPE.CUMULATE_PEOPLECOUNTS:
      return "widget.name.cumulative_people";
    case WIDGET_TYPE.STAYING_PEOPLECOUNTS:
      return "widget.name.people_occupancy";
    case WIDGET_TYPE.STAYING_VEHICLECOUNTS:
      return "widget.name.vehicle_occupancy";
    case WIDGET_TYPE.ENTRY_EXIT_PEOPLECOUNTS:
      return "widget.name.people_flow";
    case WIDGET_TYPE.ENTRY_EXIT_VEHICLECOUNTS:
      return "widget.name.vehicle_flow";
    case WIDGET_TYPE.RANK_PEOPLECOUNTS:
      return "widget.name.busiest_location";
    case WIDGET_TYPE.CHECKOUT_QUEUE:
      return "widget.name.checkout_queue";
    case WIDGET_TYPE.NUMBER_OF_PEOPLE_BY_AREA:
      return "widget.name.number_of_people_by_area";
    case WIDGET_TYPE.CITY_MAP:
      return "widget.name.city_map";
    case WIDGET_TYPE.FACTORY_MAP:
      return "widget.name.factory_map";
    case WIDGET_TYPE.RETAIL_MAP:
      return "widget.name.retail_map";
    case WIDGET_TYPE.COMBINED_MAP:
      return "widget.name.combined_map";
    case WIDGET_TYPE.SLIP_AND_FALL:
      return "widget.name.slip_and_fall";
    case WIDGET_TYPE.MES_MANAGEMENT:
      return "widget.name.mes_management";
    case WIDGET_TYPE.TEMPERATURE_STATISTICS:
      return "widget.name.temperature_statistics";
    case WIDGET_TYPE.TEMPERATURE_EVENT:
      return "widget.name.temperature_event";
    case WIDGET_TYPE.STOPPED_VEHICLE_EVENT:
      return "widget.name.stopped_vehicle_event";
    case WIDGET_TYPE.STOPPED_VEHICLE_STATISTICS:
      return "widget.name.stopped_vehicle_statistics";
    case WIDGET_TYPE.TRAFFIC_JAM_EVENT:
      return "widget.name.traffic_jam_event";
    case WIDGET_TYPE.CUMULATE_VEHICLECOUNTS:
      return "widget.name.cumulative_vehicle";
    case WIDGET_TYPE.CUMULATE_SHOPPING_CART_COUNTS:
      return "widget.name.cumulative_shopping_cart_counts";
    case WIDGET_TYPE.CROWD_SAFETY_STATISTICS:
      return "widget.name.crowd_safety_statistics";
    case WIDGET_TYPE.FORKLIFT_SPEED_EVENT:
      return "widget.name.forklift_event";
    case WIDGET_TYPE.SHOPPING_CART_CHECKOUT_QUEUE:
      return "widget.name.shopping_cart_queue";
    case WIDGET_TYPE.VEHICLE_QUEUE:
      return "widget.name.vehicle_queue";
    case WIDGET_TYPE.SHOPPING_CART_COUNT:
      return "widget.name.current_shopping_cart_counts";
    case WIDGET_TYPE.SHOPPING_CART_LOSS_EVENT:
      return "widget.name.shopping_cart_loss_event";
    case WIDGET_TYPE.HEATMAP_PEOPLE:
      return "widget.name.heatmap_people";
    case WIDGET_TYPE.VEHICLE_HEATMAP:
      return "widget.name.vehicle_heatmap";
    case WIDGET_TYPE.SHOPPING_CART_HEATMAP:
      return "widget.name.shopping_cart_heatmap";
    case WIDGET_TYPE.SLIP_AND_FALL_STATISTICS:
      return "widget.name.slip_and_fall_statistics";
    case WIDGET_TYPE.FORKLIFT_SPEED_STATISTICS:
      return "widget.name.fork_lift_statistics";
    case WIDGET_TYPE.SLOW_TRAFFIC_STATISTICS:
      return "widget.name.slow_traffic_statistics";
    default:
      return "widget.name.none";
  }
};

export const getWidgetHelp = (widgetType?: WidgetType): string => {
  switch (widgetType) {
    case WIDGET_TYPE.CUMULATE_PEOPLECOUNTS:
      return "widget.help.cumulative_people";
    case WIDGET_TYPE.STAYING_PEOPLECOUNTS:
      return "widget.help.people_occupancy";
    case WIDGET_TYPE.STAYING_VEHICLECOUNTS:
      return "widget.help.vehicle_occupancy";
    case WIDGET_TYPE.ENTRY_EXIT_PEOPLECOUNTS:
      return "widget.help.people_flow";
    case WIDGET_TYPE.ENTRY_EXIT_VEHICLECOUNTS:
      return "widget.help.vehicle_flow";
    case WIDGET_TYPE.RANK_PEOPLECOUNTS:
      return "widget.help.visitor_ranking";
    case WIDGET_TYPE.CHECKOUT_QUEUE:
      return "widget.help.checkout_queue";
    case WIDGET_TYPE.VEHICLE_QUEUE:
      return "widget.help.vehicle_queue";
    case WIDGET_TYPE.SHOPPING_CART_CHECKOUT_QUEUE:
      return "widget.help.shopping_cart_queue";
    case WIDGET_TYPE.NUMBER_OF_PEOPLE_BY_AREA:
      return "widget.help.number_of_people_by_area";
    case WIDGET_TYPE.CITY_MAP:
      return "widget.help.city";
    case WIDGET_TYPE.FACTORY_MAP:
      return "widget.help.factory";
    case WIDGET_TYPE.RETAIL_MAP:
      return "widget.help.retail";
    case WIDGET_TYPE.COMBINED_MAP:
      return "widget.help.combined_map";
    case WIDGET_TYPE.SLIP_AND_FALL:
      return "widget.help.slip_and_fall";
    case WIDGET_TYPE.MES_MANAGEMENT:
      return "widget.help.mes_management";
    case WIDGET_TYPE.TEMPERATURE_STATISTICS:
      return "widget.help.temperature_statistics";
    case WIDGET_TYPE.TEMPERATURE_EVENT:
      return "widget.help.temperature_event";
    case WIDGET_TYPE.STOPPED_VEHICLE_EVENT:
      return "widget.help.stopped_vehicle_event";
    case WIDGET_TYPE.STOPPED_VEHICLE_STATISTICS:
      return "widget.help.stopped_vehicle_statistics";
    case WIDGET_TYPE.TRAFFIC_JAM_EVENT:
      return "widget.help.traffic_jam_event";
    case WIDGET_TYPE.CUMULATE_VEHICLECOUNTS:
      return "widget.help.cumulative_vehicle";
    case WIDGET_TYPE.CUMULATE_SHOPPING_CART_COUNTS:
      return "widget.help.cumulative_shopping_cart_counts";
    case WIDGET_TYPE.SHOPPING_CART_COUNT:
      return "widget.help.current_shopping_cart_counts";
    case WIDGET_TYPE.CROWD_SAFETY_STATISTICS:
      return "widget.help.crowd_safety_statistics";
    case WIDGET_TYPE.FORKLIFT_SPEED_EVENT:
      return "widget.help.forklift_event";
    case WIDGET_TYPE.SHOPPING_CART_LOSS_EVENT:
      return "widget.help.shopping_cart_loss";
    case WIDGET_TYPE.HEATMAP_PEOPLE:
      return "widget.help.heatmap_people";
    case WIDGET_TYPE.VEHICLE_HEATMAP:
      return "widget.help.vehicle_heatmap";
    case WIDGET_TYPE.SHOPPING_CART_HEATMAP:
      return "widget.help.shopping_cart_heatmap";
    case WIDGET_TYPE.SLIP_AND_FALL_STATISTICS:
      return "widget.help.slip_and_fall_statistics";
    case WIDGET_TYPE.SLOW_TRAFFIC_STATISTICS:
      return "widget.help.slow_traffic_statistics";
    case WIDGET_TYPE.FORKLIFT_SPEED_STATISTICS:
      return "widget.help.forklift_speeding_statistics";

    default:
      return "widget.help.none";
  }
};

export const getEventName = (type?: EventType) => {
  switch (type) {
    case EVENT_TYPE.SHOPPING_CART_LOSS:
      return "widget.event.name.shopping_cart_loss";
    case EVENT_TYPE.SLIP_AND_FALL:
      return "widget.event.name.slip_and_fall";
    case EVENT_TYPE.QUEUE_MANAGEMENT:
      return "widget.event.name.queue_management";
    case EVENT_TYPE.MES:
      return "widget.event.name.mes";
    case EVENT_TYPE.TEMPERATURE:
      return "widget.event.name.temperature";
    case EVENT_TYPE.STOPPED_VEHICLE:
      return "widget.event.name.stopped_vehicle";
    case EVENT_TYPE.TRAFFIC_JAM:
      return "widget.event.name.traffic_jam";
    case EVENT_TYPE.FORKLIFT_SPEED:
      return "widget.event.name.forklift_speed";
    default:
      return "";
  }
};

export const getDateTime = (
  mode: CalendarModeRangeType,
  start: string,
  end?: string
): {
  start: string;
  end: string;
  comparisonStart: string;
  comparisonEnd: string;
} => {
  const period = moment(end || start).diff(start, "d");
  switch (mode) {
    case CALENDAR_MODE_RANGE_TYPE.LAST_24_HOURS:
      return {
        start: moment().utc().subtract(24, "h").toISOString(true),
        end: moment().utc().toISOString(true),
        comparisonStart: moment().utc().subtract(72, "h").toISOString(true),
        comparisonEnd: moment().utc().subtract(48, "h").toISOString(true)
      };
    case CALENDAR_MODE_RANGE_TYPE.LAST_7_DAYS:
      return {
        start: moment().utc().subtract(6, "d").toISOString(true),
        end: moment().utc().toISOString(true),
        comparisonStart: moment().utc().subtract(13, "d").toISOString(true),
        comparisonEnd: moment().utc().subtract(7, "d").toISOString(true)
      };
    case CALENDAR_MODE_RANGE_TYPE.LAST_30_DAYS:
      return {
        start: moment().utc().subtract(29, "d").toISOString(true),
        end: moment().utc().toISOString(true),
        comparisonStart: moment().utc().subtract(59, "d").toISOString(true),
        comparisonEnd: moment().utc().subtract(30, "d").toISOString(true)
      };
    case CALENDAR_MODE_RANGE_TYPE.LAST_90_DAYS:
      return {
        start: moment().utc().subtract(89, "d").toISOString(true),
        end: moment().utc().toISOString(true),
        comparisonStart: moment().utc().subtract(179, "d").toISOString(true),
        comparisonEnd: moment().utc().subtract(90, "d").toISOString(true)
      };
    case CALENDAR_MODE_RANGE_TYPE.LAST_180_DAYS:
      return {
        start: moment().utc().subtract(179, "d").toISOString(true),
        end: moment().utc().toISOString(true),
        comparisonStart: moment().utc().subtract(359, "d").toISOString(true),
        comparisonEnd: moment().utc().subtract(180, "d").toISOString(true)
      };
    case CALENDAR_MODE_RANGE_TYPE.LAST_365_DAYS:
      return {
        start: moment().utc().subtract(364, "d").toISOString(true),
        end: moment().utc().toISOString(true),
        comparisonStart: moment().utc().subtract(729, "d").toISOString(true),
        comparisonEnd: moment().utc().subtract(365, "d").toISOString(true)
      };
    case CALENDAR_MODE_RANGE_TYPE.YESTERDAY:
      return {
        start: moment().utc().subtract(1, "d").toISOString(true),
        end: moment().utc().subtract(1, "d").toISOString(true),
        comparisonStart: moment().utc().subtract(2, "d").toISOString(true),
        comparisonEnd: moment().utc().subtract(2, "d").toISOString(true)
      };
    case CALENDAR_MODE_RANGE_TYPE.DAY_BEFORE_YESTERDAY:
      return {
        start: moment().utc().subtract(2, "d").toISOString(true),
        end: moment().utc().subtract(2, "d").toISOString(true),
        comparisonStart: moment().utc().subtract(3, "d").toISOString(true),
        comparisonEnd: moment().utc().subtract(3, "d").toISOString(true)
      };
    case CALENDAR_MODE_RANGE_TYPE.CUSTOM:
      return {
        start: moment(start).utc().toISOString(true),
        end: moment(end || start)
          .utc()
          .toISOString(true),
        comparisonStart: moment(end || start)
          .utc()
          .subtract(period * 2 + 1, "d")
          .toISOString(true),
        comparisonEnd: moment(end || start)
          .utc()
          .subtract(period + 1, "d")
          .toISOString(true)
      };
    default:
      return {
        start: moment().utc().toISOString(true),
        end: moment().utc().toISOString(true),
        comparisonStart: moment().utc().subtract(1, "d").toISOString(true),
        comparisonEnd: moment().utc().subtract(1, "d").toISOString(true)
      };
  }
};

export const getAxisLabels = (
  mode: string,
  start: string,
  end: string,
  step: {
    value: number;
    unit: unitOfTime.DurationConstructor;
  },
  options?: {
    currentTimeZone?: string;
    format?: string;
  }
): {
  labels: string[];
  indexFuture: number;
} => {
  const { currentTimeZone: timezone, format = FORMAT.DATE } = options || {};
  const currentTimeZone =
    timezone ||
    JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEY.TIME_ZONE) as string
    ) ||
    momentTimeZone.tz.guess();
  let startDateTime = momentTimeZone
    .tz(start, currentTimeZone)
    .toISOString(true);
  let endDateTime = momentTimeZone.tz(end, currentTimeZone).toISOString(true);
  if (
    mode !== CALENDAR_MODE_RANGE_TYPE.LAST_24_HOURS &&
    mode !== DATE_PERIOD.TODAY &&
    mode !== DATE_PERIOD.LAST_24_HOUR
  ) {
    startDateTime = momentTimeZone.tz(start, currentTimeZone).toISOString(true);
    endDateTime = momentTimeZone.tz(end, currentTimeZone).toISOString(true);
  }
  if (mode === DATE_PERIOD.TODAY) {
    startDateTime = momentTimeZone.tz(start, currentTimeZone).toISOString(true);
  }
  // When selecting a month
  // there is one extra tick because it calculates from the 1st to the last day of the month.
  const isMonthTick =
    step.unit === "month" || step.unit === "months" || step.unit === "M";
  const offset = isMonthTick ? 2 : 1;
  const indexFuture =
    moment
      .tz(endDateTime, currentTimeZone)
      .diff(moment.tz(startDateTime, currentTimeZone), step.unit) + offset;

  const startDateTimeMoment = momentTimeZone.tz(startDateTime, currentTimeZone);
  const labels = Array.from({ length: indexFuture }).map(() => {
    const timeLabel = momentTimeZone
      .tz(startDateTimeMoment, currentTimeZone)
      .format(format);

    if (isMonthTick) {
      // set the tick to the 1st day of the month  ( except for the first month )
      startDateTimeMoment.set("date", 1);
    }

    // next unit
    startDateTimeMoment.add(step.value, step.unit);
    return timeLabel;
  });

  return {
    labels,
    indexFuture
  };
};

export const getAxisIntervalLabels = (
  mode: string,
  start: string,
  end: string,
  timeInterval: string,
  currentTimeZone?: string
) => {
  if (timeInterval === TIME_INTERVAL.DAY_1) {
    return getAxisLabels(
      mode,
      start,
      end,
      { value: 1, unit: "day" },
      {
        currentTimeZone,
        format: FORMAT.DATE_MONTH_TYPE
      }
    );
  }
  if (timeInterval === TIME_INTERVAL.DAY_7) {
    return getAxisLabels(
      mode,
      start,
      end,
      { value: 1, unit: "week" },
      {
        currentTimeZone,
        format: FORMAT.DATE_MONTH_TYPE
      }
    );
  }
  if (timeInterval === TIME_INTERVAL.MONTH_1) {
    return getAxisLabels(
      mode,
      start,
      end,
      { value: 1, unit: "month" },
      {
        currentTimeZone,
        format: FORMAT.DATE_MONTH_TYPE
      }
    );
  }
  return getAxisLabels(
    mode,
    start,
    end,
    { value: 1, unit: "hour" },
    {
      currentTimeZone,
      format: FORMAT.DATE_TIME_24H
    }
  );
};

export const xAxisLabelFormatter =
  (timeInterval: string | undefined, mode: CalendarModeRangeType) =>
  (value: string) => {
    if (timeInterval === TIME_INTERVAL.HOUR_1) {
      const [date, time] = value.split(" ");
      if (mode !== CALENDAR_MODE_RANGE_TYPE.TODAY && time === "00:00") {
        return moment(date).format(FORMAT.DATE3);
      }

      return time?.split(":")[0];
    }
    return moment(value).format(FORMAT.DATE3);
  };

const formatedNumber = (val: string) => {
  return parseInt(val, 10) === +val ? val : (+val).toFixed(2);
};

export const chartTooltipFormatter = (
  timeInterval: TimeInterval,
  dateValue: string,
  params: ChartTooltipParam[],
  end?: string | number | Date,
  mode?: CalendarModeRangeType
) => {
  let title = "";
  const date = moment(dateValue, true);
  if (date.isValid()) {
    switch (timeInterval) {
      case TIME_INTERVAL.HOUR_1: {
        const from = date.format(FORMAT.DATE_TIME2_24H);
        const to = date.add(59, "minutes").format(FORMAT.TIME_1);
        const [month1, day1] = to.split("/").map(Number);
        const year1 = new Date().getFullYear(); // Assuming current year
        const date1 = new Date(year1, month1 - 1, day1); // Month is zero-based (subtract 1)
        if (end) {
          const date2 = new Date(end);

          // Calculate the difference in milliseconds
          const differenceMs = date2.getTime() - date1.getTime();

          // Convert milliseconds to days
          const differenceDays = Math.floor(
            differenceMs / (1000 * 60 * 60 * 24)
          );
          if (differenceDays >= 1) {
            title = [from, moment(dateValue).format(FORMAT.DATE3)].join(" - ");
          } else {
            title = [from, to].join(" - ");
          }
        }
        break;
      }
      case TIME_INTERVAL.DAY_1: {
        if (mode === CALENDAR_MODE_RANGE_TYPE.LAST_24_HOURS) {
          const currentTimeZone =
            JSON.parse(
              sessionStorage.getItem(SESSION_STORAGE_KEY.TIME_ZONE) as string
            ) || momentTimeZone.tz.guess();
          const date1 = moment().tz(currentTimeZone).format(FORMAT.DATE);
          const currentTime = moment()
            .tz(currentTimeZone)
            .format(FORMAT.TIME_1);
          if (currentTime === "00:00") {
            title = date.format(FORMAT.DATE3);
          } else if (date1 === dateValue) {
            title = `${date.format(FORMAT.DATE3)} 00:00 - ${date.format(
              FORMAT.DATE3
            )} ${currentTime}`;
          } else {
            title = `${date.format(
              FORMAT.DATE3
            )} ${currentTime} - ${date.format(FORMAT.DATE3)} 00:00`;
          }
        } else {
          title = date.format(FORMAT.DATE3);
        }

        break;
      }
      case TIME_INTERVAL.DAY_7: {
        const from = date.format(FORMAT.DATE3);
        const to = date.add(7, "days").format(FORMAT.DATE3);

        if (end) {
          const isLastTick =
            moment(moment(end).format(FORMAT.DATE)).diff(
              moment(dateValue).format(FORMAT.DATE),
              "day"
            ) < 7;

          if (isLastTick) {
            title = [from, moment(end).format(FORMAT.DATE3)].join(" - ");
          } else {
            title = [from, to].join(" - ");
          }
        }

        break;
      }
      case TIME_INTERVAL.MONTH_1: {
        const from = date.format(FORMAT.DATE3);
        const to = date.endOf("M").format(FORMAT.DATE3);

        if (end) {
          if (
            date.get("M") === moment(end).get("M") &&
            date.get("year") === moment(end).get("year")
          ) {
            // last data
            title = [from, moment(end).format(FORMAT.DATE3)].join(" - ");
          } else {
            title = [from, to].join(" - ");
          }
        }
        break;
      }
      default:
        break;
    }
  } else {
    title = dateValue;
  }

  return `<div class="widget-tooltip" data-testid="widget-tooltip" style="display: flex; flex-direction: column; width: max-content;">
        <div style="font-size: 12px; font-weight: bold; line-height: 16px;">${title}</div>
        ${params
          .map(
            (
              item
            ) => `<div class="widget-tooltip-item" style="display: flex; justify-content: space-between; align-items: center; gap: 14px; font-size: 10px;">
                    <div data-testid="widget-tooltip-item-label" style="display: flex; gap: 4px; align-items: center;">
                      ${
                        item.color
                          ? `<span style="width: 6px;height: 6px; border-radius: 50%; background-color: ${item.color};"></span>`
                          : ""
                      }
                      <span>${item.label}</span>
                    </div>
                    <div>${
                      !Number.isNaN(parseInt(item.data, 10))
                        ? formatedNumber(item.data)
                        : item.data
                    }</div>
                  </div>`
          )
          .join("")}
      </div>`;
};

export const getHeatmapColor = (value: number, max: number) => {
  const hue = 255 - Math.floor((value / max) * 255);

  return `hsla(${Math.floor(hue)}, 100%, ${hue === 255 ? "100%" : "50%"}, ${
    hue === 255 ? "0" : "1"
  })`;
};

export const checkDeviceSeries = (model: string) => {
  if (!model) return undefined;
  const firstLetter = model.toLowerCase().at(0);
  if (firstLetter === "p") {
    return "p";
  }
  if (firstLetter === "q") {
    return "q";
  }
  return "x";
};

export const getWidgetGraphCombinedDataOfSameRule = (
  eventManagementData: EventManagementData[]
) => {
  return eventManagementData.reduce((acc: EventManagementData[], item) => {
    const existing: EventManagementData | undefined = acc.find(
      (record) => record.name === item.name
    );
    if (existing) {
      existing.count += item.count;
    } else {
      // Add a new entry if not already present
      acc.push({ ...item });
    }
    return acc;
  }, []);
};

export const calculateWidgetChipRules = (
  total: number,
  calculateSize = false
) => {
  switch (total) {
    case 1:
      return calculateSize
        ? WIDGET_RULE_CHIP_SIZES.EXTRA_LARGE
        : WIDGET_CHIP_RULES.RULE_1;

    case 2:
      return calculateSize
        ? WIDGET_RULE_CHIP_SIZES.LARGE
        : WIDGET_CHIP_RULES.RULE_2;

    case 3:
    case 4:
      return calculateSize
        ? WIDGET_RULE_CHIP_SIZES.MEDIUM
        : WIDGET_CHIP_RULES.RULE_3_4;

    case 5:
    case 6:
      return calculateSize
        ? WIDGET_RULE_CHIP_SIZES.NORMAL
        : WIDGET_CHIP_RULES.RULE_5_6;

    case 7:
    case 8:
      return calculateSize
        ? WIDGET_RULE_CHIP_SIZES.SMALL
        : WIDGET_CHIP_RULES.RULE_7_8;

    default:
      return "";
  }
};

export const prepareWidgetParamsCompareDate = (
  widgetId: string,
  {
    start,
    end,
    currentTimeZone,
    comparisonEnd,
    comparisonStart,
    timeInterval
  }: {
    start: string;
    end: string;
    currentTimeZone: string;
    comparisonStart?: string;
    comparisonEnd?: string;
    timeInterval: TimeInterval;
  },
  mode?: CalendarModeRangeType
) => {
  const params = {
    widgetId,
    startDateTime: momentTimeZone
      .tz(start, currentTimeZone)
      .startOf("day")
      .toISOString(true),
    endDateTime: momentTimeZone.tz(end, currentTimeZone).toISOString(true),
    startComparisonDateTime: momentTimeZone
      .tz(comparisonStart, currentTimeZone)
      .startOf("day")
      .toISOString(true),
    endComparisonDateTime: momentTimeZone
      .tz(comparisonEnd, currentTimeZone)
      .endOf("day")
      .toISOString(true),
    timeInterval
  };

  if (mode === CALENDAR_MODE_RANGE_TYPE.TODAY) {
    return {
      ...params,
      endDateTime: momentTimeZone
        .tz(momentTimeZone(), currentTimeZone)
        .toISOString(true)
    };
  }

  return params;
};

export const prepareWidgetParams = (
  widgetId: string,
  {
    start,
    end,
    currentTimeZone,
    timeInterval
  }: {
    start: string;
    end: string;
    currentTimeZone: string;
    timeInterval: TimeInterval;
  },
  mode?: CalendarModeRangeType
) => {
  const params = {
    widgetId,
    startDateTime: momentTimeZone.tz(start, currentTimeZone).toISOString(true),
    endDateTime: momentTimeZone.tz(end, currentTimeZone).toISOString(true),
    timeInterval
  };

  if (mode === CALENDAR_MODE_RANGE_TYPE.TODAY) {
    return {
      ...params,
      endDateTime: momentTimeZone
        .tz(momentTimeZone(), currentTimeZone)
        .toISOString(true)
    };
  }

  return params;
};
