/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from "react";

import { useLang } from "@hooks/useLang";
import {
  useCreateAnalyticsFilterMutation,
  useGetAnalyticsFilterQuery,
  useUpdateAnalyticsFilterMutation,
  useDeleteAnalyticsFilterMutation,
  useUpdateFilterNameNotSaveMutation,
  FilterType,
  toChartType,
  Hide,
  Order
} from "@queries/tanstack/analytics-filter/AnalyticsFilterQuery";
import _ from "lodash";
import { IconNoResult, IconPictogram } from "@resources/icons/Icons";

import {
  getRuleType,
  getChartIcon,
  useGetRuleGroupByLocation,
  getChartData
} from "@hooks/analytics/useRuleGroupByLocation";
import { useCurrentTimezone } from "@hooks/useCurrentTimezone";
import { CalendarModeRangeType } from "@custom-types/Types";
import { AnalyticsFilterRequestDto } from "@open-api";
import WidgetAllHideDarkImage from "@resources/img/widget/widget-all-hide-dark.png";
import WidgetAllHideLightImage from "@resources/img/widget/widget-all-hide-light.png";
import { useHVSnackbar } from "@repo/HVComponents";
import { useTheme } from "styled-components";
import { TopMenu } from "./menu/TopMenu";
import * as S from "./AnalyticsPage.styled";
import ChartCard from "./chart/ChartCard";
import { AnalyticsPageSkeleton } from "./skeleton/AnalyticsPageSkeleton";
import { GeneratvieAI } from "./generativeAI/GeneratvieAI";

export const AnalyticsPage = () => {
  const { translate } = useLang();
  const { convertByTimezone } = useCurrentTimezone();
  const theme = useTheme();

  const [searchFilter, setSearchFilter] = useState<FilterType>();
  const [show, setShow] = useState<boolean>(false);
  const {
    data: filters,
    isLoading: isFilterLoading,
    isInitialLoading: isInitialLoadingFilter
  } = useGetAnalyticsFilterQuery();
  const [filterIdx, setFilterIdx] = useState(0);

  const { mutateAsync: updateFilter } = useUpdateAnalyticsFilterMutation();
  const { mutateAsync: createFilter } = useCreateAnalyticsFilterMutation();
  const { mutateAsync: deleteFilter } = useDeleteAnalyticsFilterMutation();
  const { mutateAsync: updateFilterNameNotSave } =
    useUpdateFilterNameNotSaveMutation();

  const { showSnackbar } = useHVSnackbar();
  // [TODO] state 처리되는 데이터 랜더링 최적화 해야함. ( 한번에 처리되도록 )
  const { data: ruleGroup, isLoading: isLoadingRuleGroup } =
    useGetRuleGroupByLocation(searchFilter?.rules ?? filters?.[0]?.rules ?? []);

  useEffect(() => {
    if (isInitialLoadingFilter) {
      return;
    }

    if (filters.length > 0) {
      setSearchFilter(filters[0]);
    }
  }, [isInitialLoadingFilter, filters]);

  const handleSave = (
    type: "SAVE" | "SAVE_AS",
    options: { fileName: string; id?: string }
  ) => {
    if (searchFilter?.rules && searchFilter?.baseTime) {
      const rules = searchFilter.rules.map((rule) => {
        const [ruleType, deviceId, channel, ruleName] = (rule as string).split(
          ":"
        );
        return {
          deviceId,
          ruleName,
          channel: Number(channel),
          ruleType
        };
      });
      const filterName = options.fileName;
      const startDateTime = searchFilter.baseTime.start;
      const endDateTime = searchFilter.baseTime.end;
      const calenderMode = searchFilter.baseTime.mode;
      const hide = searchFilter.hide;
      const order = searchFilter.order;

      const reqParams = {
        filterName,
        startDateTime,
        endDateTime,
        rules,
        hide,
        order,
        calenderMode
      } as unknown as AnalyticsFilterRequestDto;

      if (filters.length === 0 || type === "SAVE_AS") {
        createFilter(reqParams)
          .then(() => {
            showSnackbar({
              id: "analytics-result-saved",
              message: translate("analytics_result_saved"),
              variant: "online-success"
            });

            if (type === "SAVE_AS") {
              _.defer(() => {
                setFilterIdx(0);
              });
            }
          })
          .catch(() => {
            showSnackbar({
              id: "request-error",
              message: translate("request_error"),
              variant: "fail"
            });
          });
      } else {
        const filterId = options.id;
        if (filterId) {
          updateFilter({
            ...reqParams,
            filterId
          })
            .then(() => {
              showSnackbar({
                id: "analytics-result-saved",
                message: translate("analytics_result_saved"),
                variant: "online-success"
              });

              _.defer(() => {
                setFilterIdx(0);
              });
            })
            .catch(() => {
              showSnackbar({
                id: "request-error",
                message: translate("request_error"),
                variant: "fail"
              });
            });
        }
      }
    }
  };

  const handleRemove = (id: string) => {
    const nextSearch = (() => {
      const idx = filters.findIndex((filter) => filter._id === id);
      if (idx === 0) {
        // if first filter is removed, set next filter as first filter ( if not exist, set undefined )
        return filters.length > 0 ? filters[idx + 1] : undefined;
      }

      // else set first filter
      return filters[0];
    })();
    deleteFilter(id)
      .then(() => {
        setFilterIdx(0);
        setSearchFilter(nextSearch);
      })
      .then(() => {
        showSnackbar({
          id: "analytics-result-removed",
          message: translate("analytics_result_removed"),
          variant: "online-success"
        });
      })
      .catch(() => {
        showSnackbar({
          id: "request-error",
          message: translate("request_error"),
          variant: "fail"
        });
      });
  };
  const handleChangeFilterName = (filterId: string, filterName: string) => {
    const prevFilter = filters[filterIdx];
    if (prevFilter.rules && prevFilter.baseTime) {
      // temporary apply UI before API is completed
      const prevFilterName = prevFilter.filterName;
      updateFilterNameNotSave({ filterId, filterName });
      setSearchFilter({ ...searchFilter, filterName } as FilterType);

      const rules = prevFilter.rules.map((rule) => {
        const [ruleType, deviceId, channel, ruleName] = (rule as string).split(
          ":"
        );
        return {
          deviceId,
          ruleName,
          channel: Number(channel),
          ruleType
        };
      });

      const startDateTime = prevFilter.baseTime.start;
      const endDateTime = prevFilter.baseTime.end;
      const calenderMode = prevFilter.baseTime.mode;
      const hide = prevFilter.hide;
      const order = prevFilter.order;

      const reqParams = {
        startDateTime,
        endDateTime,
        rules,
        hide,
        order,
        calenderMode,
        filterName
      } as unknown as AnalyticsFilterRequestDto;

      updateFilter({
        ...reqParams,
        filterId
      })
        .then(() => {
          _.defer(() => {
            setFilterIdx(0);
          });
        })
        .catch(() => {
          // restore when fail
          updateFilterNameNotSave({ filterId, filterName: prevFilterName });
          setSearchFilter({
            ...searchFilter,
            filterName: prevFilterName
          } as FilterType);
        });
    }
  };

  const handleOrderAndHide = (params: { order: Order[]; hide: Hide[] }) => {
    const { order, hide } = params;
    setSearchFilter({
      ...searchFilter,
      order,
      hide
    } as FilterType);
  };

  const handleChangeTab = (idx: number) => {
    setFilterIdx(idx);
    setSearchFilter(filters[idx]);
  };

  const handleShowChat = () => {
    setShow(!show);
  };

  if (
    isFilterLoading ||
    isLoadingRuleGroup ||
    (filters.length > 0 && !searchFilter)
  ) {
    return <AnalyticsPageSkeleton />;
  }

  return (
    <S.Container>
      <S.Content isShow={show}>
        <S.Title>{translate("analytics")}</S.Title>
        <TopMenu
          key={filters[filterIdx]?._id}
          filters={filters}
          filterIdx={filterIdx}
          onSearch={setSearchFilter}
          onSave={handleSave}
          onChange={handleChangeTab}
          onRemove={handleRemove}
          onChangeFilterName={handleChangeFilterName}
          onChangeOrderAndHide={handleOrderAndHide}
        />
        {(() => {
          if (_.isNil(searchFilter)) {
            return (
              <S.NoticeWrap>
                <IconNoResult />
                <S.NoticeTitile>{translate("analytics_search")}</S.NoticeTitile>
              </S.NoticeWrap>
            );
          }

          const isNoData = _.isEmpty(ruleGroup);
          if (isNoData) {
            return (
              <S.NoticeWrap>
                <IconPictogram />
                <S.NoticeTitile>
                  {translate("analytics_no_data")}
                </S.NoticeTitile>
                <S.NoticeMessage>
                  {translate("analytics_not_match")}
                </S.NoticeMessage>
              </S.NoticeWrap>
            );
          }

          const isAllHide = searchFilter.order.every((v) =>
            searchFilter.hide.includes(v)
          );

          if (isAllHide) {
            return (
              <S.NoticeWrap>
                <img
                  src={
                    theme.type === "Light"
                      ? WidgetAllHideLightImage
                      : WidgetAllHideDarkImage
                  }
                  alt="all widgets are hidden"
                />
                <S.NoticeTitile>
                  {translate("analytics_all_hidden")}
                </S.NoticeTitile>
                <S.NoticeMessage>
                  {translate("analytics_turn_on_chart")}
                </S.NoticeMessage>
              </S.NoticeWrap>
            );
          }

          const baseTime = searchFilter.baseTime && {
            start: convertByTimezone(searchFilter.baseTime.start),
            end: convertByTimezone(searchFilter.baseTime.end),
            mode: searchFilter.baseTime.mode as CalendarModeRangeType
          };

          return (
            !!baseTime && (
              <S.ChartList>
                {searchFilter.order.map((orderType) => {
                  const isShow = !searchFilter.hide.includes(orderType);
                  if (isShow) {
                    const chartType = toChartType(orderType);
                    const ruleType = getRuleType(chartType);
                    const viewListTypes = getChartData(chartType);
                    if (ruleType) {
                      const rules = ruleGroup[ruleType];
                      if (rules) {
                        const ChartIcon = getChartIcon(chartType);
                        return (
                          <ChartCard
                            type={chartType}
                            key={`${chartType}_${searchFilter._id}`}
                            rules={rules}
                            icon={ChartIcon && <ChartIcon />}
                            title={translate(
                              `analytics_chart_name.${chartType.toLowerCase()}`
                            )}
                            onChangeInsightChat={handleShowChat}
                            baseTime={baseTime}
                            options={{ viewList: viewListTypes }}
                          />
                        );
                      }
                    }
                  }

                  return null;
                })}
              </S.ChartList>
            )
          );
        })()}
      </S.Content>
      {show && (
        <S.GenAI>
          <GeneratvieAI
            title="Cumulative people count"
            searchFilter={searchFilter}
            ruleGroup={ruleGroup}
            isShow={show}
            onChangeInsightChat={handleShowChat}
          />
        </S.GenAI>
      )}
    </S.Container>
  );
};
