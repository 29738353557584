import { FORMAT, MONTH } from "../../resources/constants/constants";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import {
  IconArrowChevronDown,
  IconArrowChevronUp,
} from "../../../../assets/icons";
import { ContextMenu } from "./ContextMenu/ContextMenu";
import styles from "./Header.module.css";
import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";
import { formatDate, formatMonth } from "../../resources/utils/utils";
import { HVFlex, HVText } from "../../../";

const Header = ({
  // date,
  monthDate,
  decreaseMonth,
  increaseMonth,
  changeMonth,
  changeYear,
  nextMonthButtonDisabled,
  prevMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) => {
  const months = Object.values(MONTH);

  return (
    <HVFlex direction={"row"} className={styles[`header`]}>
      <HVFlex direction={"row"} className={styles[`control`]}>
        <HVFlex direction={"row"} className={styles[`month-year-menu`]}>
          <ContextMenu
            data={months.map((month, index) => ({
              label: month,
              custom: {
                onClick: () => {
                  changeMonth(index);
                },
              },
            }))}
          >
            <HVFlex direction={"row"} className={styles["month-year"]}>
              <HVText
                type="Body-md"
                weight="Regular"
                style={{ lineHeight: "inherit", color: "inherit" }}
              >
                {formatMonth(monthDate || new Date())}
              </HVText>

              <span className={styles["icon-dropdown"]}>
                <IconArrowChevronDown />
              </span>
            </HVFlex>
          </ContextMenu>

          <ContextMenu
            data={Array.from({ length: 6 }, (_, i) => {
              const year = new Date().getFullYear() - (5 - i);
              return {
                label: year.toString(),
                custom: {
                  onClick: () => {
                    changeYear(year);
                  },
                },
              };
            })}
          >
            <HVFlex direction={"row"} className={styles["month-year"]}>
              <HVText
                type="Body-md"
                weight="Regular"
                style={{ lineHeight: "inherit", color: "inherit" }}
              >
                {formatDate(monthDate || new Date(), FORMAT.FULL_YEAR)}
              </HVText>
              <span className={styles["icon-dropdown"]}>
                <IconArrowChevronDown />
              </span>
            </HVFlex>
          </ContextMenu>
        </HVFlex>

        <HVFlex direction={"row"} className={styles["month-control"]}>
          <button
            className={`${styles["icon-arrow"]} up`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (!prevMonthButtonDisabled) {
                decreaseMonth();
              }
            }}
          >
            <IconArrowChevronUp />
          </button>
          <button
            className={`${styles["icon-arrow"]} down`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (!nextMonthButtonDisabled) {
                increaseMonth();
              }
            }}
          >
            <IconArrowChevronDown />
          </button>
        </HVFlex>
      </HVFlex>
    </HVFlex>
  );
};

export default Header;
