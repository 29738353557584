import { useCallback, useRef, useState } from "react";
import { FlatNodeItem } from "@components/common/tree-view/Interfaces";
import { useLang } from "@hooks/useLang";
import { HVButton, HVIcon } from "@repo/HVComponents";
import useDetectOutsideClick from "@hooks/useDetectOutsideClick";
import * as S from "./AnalyticsRuleLayer.styled";
import { AnalyticsRuleTree } from "./AnalyticsRuleTree";
import { ICON } from "../../../constants/icons";

interface AnalyticsRuleLayerProps {
  onOK: () => void;
  defaultRules: FlatNodeItem[];
  onChecked: (checkedNodes: FlatNodeItem[]) => void;
}
export const AnalyticsRuleLayer = (props: AnalyticsRuleLayerProps) => {
  const { translate } = useLang();
  const rootRef = useRef(null);
  useDetectOutsideClick(rootRef, props.onOK);

  const [rulesChecked, setRulesChecked] = useState<FlatNodeItem[]>(
    props.defaultRules
  );

  const isCheckboxChanged = useRef(false);
  const handleRulesOK = useCallback(() => {
    if (isCheckboxChanged.current) {
      props.onChecked(rulesChecked);
    }
    props.onOK();
  }, [props, rulesChecked]);

  const handleClearAll = () => {
    isCheckboxChanged.current = true;
    setRulesChecked([]);
  };

  return (
    <S.Container ref={rootRef}>
      <S.RuleWrap>
        <AnalyticsRuleTree
          rulesChecked={rulesChecked.map((node) => node.value)}
          onChecked={(node) => {
            isCheckboxChanged.current = true;
            setRulesChecked(node.filter((v) => !v.children));
          }}
        />
      </S.RuleWrap>
      <S.BottomWrap>
        <HVButton
          variant="Ghost"
          leftSection={<HVIcon name="refresh" svgProps={{ ...ICON.SIZE_16 }} />}
          onClick={handleClearAll}
        >
          {translate("clear_all")}
        </HVButton>
        <HVButton
          variant="Primary"
          onClick={handleRulesOK}
          styles={{ root: { minWidth: "80px" } }}
        >
          {translate("ok")}
        </HVButton>
      </S.BottomWrap>
    </S.Container>
  );
};
