"use client";

import React from "react";
import { HVFlex, HVIcon, HVLogo, HVText } from "../..";
import styles from "./HVAppsMenu.module.css";
import IHVAppsMenuProps from "./IHVAppsMenuProps";
import IHVLogoProps from "../HVLogo/IHVLogoProps";

function HVAppsMenu({
  appsMenuContainerProps,
  apps,
  helpSection,
  helpText,
  onHelpClick,
}: IHVAppsMenuProps) {
  return (
    <HVFlex
      className={styles.appsMenuContainer}
      direction={"row"}
      wrap={"wrap"}
      {...appsMenuContainerProps}
    >
      <HVFlex className={styles.logoContainer}>
        {apps.map((app: IHVLogoProps) => (
          <HVFlex key={app.text}>
            <HVLogo {...app} />
          </HVFlex>
        ))}
      </HVFlex>
      {helpSection && (
        <HVFlex
          className={styles.helpContainer}
          direction={"row"}
          onClick={onHelpClick}
        >
          <HVIcon
            name="help"
            svgProps={{ width: 24, stroke: "var(--hv-icon-normal-secondary)" }}
          />
          <HVText>{helpText || "Help"}</HVText>
          <HVFlex className={styles.rightIcon}>
            <HVIcon
              name="arrow-chevron-right"
              svgProps={{
                width: 16,
                stroke: "var(--hv-icon-normal-secondary)",
              }}
            />
          </HVFlex>
        </HVFlex>
      )}
    </HVFlex>
  );
}

export default HVAppsMenu;
