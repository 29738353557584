"use client";

import IHVIconProps from "./IHVIcon";
import iconList from "./iconList";
import { createElement } from "react";

const svgDefaultProperties: { [key: string]: number | string } = {
  width: 24,
  height: 24,
  strokeWidth: 1.5,
};

const HVIcon = ({
  name,
  svgProps = svgDefaultProperties,
  styleClass,
}: IHVIconProps) => {
  const iconsWithFillProperty = [
    "camera-external",
    "camera-PTZ",
    "camera-tamp",
    "event",
    "firmware",
    "license",
    "map",
    "shock-detection",
    "vehicle-sedan",
    "vehicle-counting",
    "warranty-expiration",
  ];

  if (iconsWithFillProperty.includes(name)) {
    svgDefaultProperties["fill"] = "var(--hv-icon-normal-primary)";
    svgDefaultProperties["stroke"] = "none";
  } else {
    svgDefaultProperties["fill"] = "none";
    svgDefaultProperties["stroke"] = "var(--hv-icon-normal-primary)";
  }

  return (
    <>
      {createElement(iconList[name], {
        className: styleClass,
        ...svgDefaultProperties,
        ...svgProps,
      })}
    </>
  );
};

export default HVIcon;
