import { ScrollArea } from "@mantine/core";
import scrollbarNormal from "./HVScrollBar.module.css";
import scrollbarLines from "./HVScrollBarLine.module.css";
import IHVScrollBarProps from "./IHVScrollBarProps";

function HVScrollBar({
  scrollBarProps,
  children,
  variant = "Normal",
}: IHVScrollBarProps) {
  return (
    <ScrollArea
      p={"var(--mantine-spacing-3)"}
      classNames={variant === "Normal" ? scrollbarNormal : scrollbarLines}
      {...scrollBarProps}
    >
      {children}
    </ScrollArea>
  );
}

export default HVScrollBar;
