import { useAppSelector } from "@configs/Redux";
import { useMemo } from "react";

export const useThemeMode = () => {
  const { colorMode } = useAppSelector((state) => state.system);

  const mode = useMemo(() => {
    if (
      colorMode === "Dark" ||
      (colorMode === "System" &&
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      return "dark" as const;
    }

    return "light" as const;
  }, [colorMode]);

  return { mode };
};
