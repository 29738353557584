import { MouseEvent as ReactMouseEvent, ReactNode } from "react";
import styles from "./ScrollBar.module.css";
import useScrollBar from "./useScrollBar";

interface ScrollBarProps {
  children: ReactNode;
  onScroll?: (e: ReactMouseEvent<HTMLDivElement>) => void;
}

const ScrollBar = ({ children, onScroll }: ScrollBarProps) => {
  const {
    onScrollContent,
    onMouseDown,
    onKeyDown,
    scrollbarRef,
    contentRef,
    uniqId,
  } = useScrollBar();

  return (
    <div className={styles["scrollbar"]}>
      <div
        id={`scrollbar-${uniqId}`}
        ref={contentRef}
        className={styles["scrollbar-content"]}
        data-testid="scrollbar-content"
        onScroll={(e: ReactMouseEvent<HTMLDivElement>) => {
          onScroll?.(e);
          onScrollContent();
        }}
      >
        {children}
      </div>
      <div
        ref={scrollbarRef}
        onMouseDown={onMouseDown}
        onKeyDown={onKeyDown}
        className={styles["scrollbar-thumb"]}
        tabIndex={0}
        aria-label="scrollbar"
        role="scrollbar"
        aria-controls={`scrollbar-${uniqId}`}
        aria-valuenow={0}
      />
    </div>
  );
};

export default ScrollBar;
