"use client";

import { HVFlex } from "../..";
import IHVPermission from "../HVPermission/IHVPermission";
import HVPermission from "../HVPermission/HVPermission";
import { Fragment } from "react";

const HVPermissions = ({ permissions }: { permissions: IHVPermission[] }) => {
  return (
    <HVFlex
      display={"grid"}
      style={{
        gridTemplateColumns: "minmax(auto, 50%) auto minmax(auto, 50%)",
      }}
      py="4px"
      gap="8px"
      align="center"
    >
      {permissions.map((permission: IHVPermission, index: number) => {
        return (
          <Fragment key={index.toString()}>
            <HVPermission
              {...permission}
              containerProps={{ justify: index % 2 > 0 ? "start" : "end" }}
            />
            {index % 2 === 0 && (
              <HVFlex h="12px" w="1px" bg="var(--hv-border-default)" />
            )}
          </Fragment>
        );
      })}
    </HVFlex>
  );
};

export default HVPermissions;
