import { Group, NumberInput, Pagination } from "@mantine/core";
import IHVPaginationProps from "./IHVPaginationProps";
import styles from "./HVPagination.module.css";
import { useState } from "react";
import HVFlex from "../HVFlex/HVFlex";
import HVText from "../HVText/HVText";

function HVPagination({
  containerProps,
  showTotalRows = true,
  totalRowsPrefix = "Total",
  totalRows,
  totalTextProps,
  defaultItemsPerPage,
  paginationProps,
  currentPage,
  onPageChange,
  goToPageContainerProps,
  goToPagePrefix = "Go to page",
  goToPagePrefixTextProps,
}: IHVPaginationProps) {
  const [inputValue, setInputValue] = useState("");
  const totalPages = Math.ceil(totalRows / (defaultItemsPerPage ?? 10));

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const page = parseInt(inputValue, 10);
      if (page >= 1 && page <= totalPages) {
        onPageChange?.(page);
      } else {
        alert(`Please enter a page number between 1 and ${totalPages}`);
      }
      setInputValue("");
    }
  };

  return (
    <HVFlex
      w={"100%"}
      direction={"row"}
      align={"center"}
      justify={"end"}
      gap={"var(--mantine-spacing-5)"}
      h={"var(--mantine-spacing-8)"}
      px={0}
      my={"var(--mantine-spacing-5)"}
      {...containerProps}
    >
      {showTotalRows && (
        <HVText type="Body-sm" {...totalTextProps}>
          {totalRowsPrefix} {totalRows}
        </HVText>
      )}

      <Pagination
        total={totalPages}
        value={currentPage}
        onChange={onPageChange}
        classNames={styles}
        {...paginationProps}
      />

      <Group gap="var(--mantine-spacing-2)" {...goToPageContainerProps}>
        <HVText type="Body-sm" {...goToPagePrefixTextProps}>
          {goToPagePrefix}
        </HVText>
        <NumberInput
          hideControls
          w="50px"
          size="xs"
          value={inputValue}
          onChange={(value) => setInputValue(value.toString())}
          onKeyDown={handleInputKeyDown}
        />
      </Group>
    </HVFlex>
  );
}

export default HVPagination;
