import {
  PortalDeviceWithLinkedLicenses,
  WidgetType
} from "@custom-types/Types";
import {
  DeviceLicenseInfo,
  GetPortalDevicesConfigResponse
} from "@queries/Devices";
import {
  AI_TYPE,
  DEVICE_STATUS,
  ON_CLOUD_STATUS,
  WAVE_SYNC_STATUS,
  WIDGET_TYPE
} from "@resources/Constants";
import { displayChannelNo } from "@utils/Common";
import { LicenseStatus } from "@components/common/tree-view/Interfaces";
import {
  WidgetRuleInfos,
  WidgetRuleItem
} from "@components/widgets/base/setup/widget-rules/Interfaces";
import { ReactComponent as IconRuleCrowd } from "@resources/icons/svg/rule/icon-rule-crowd.svg";
import { ReactComponent as IconRuleForklift } from "@resources/icons/svg/rule/icon-rule-forklift.svg";
import { ReactComponent as IconRuleMES } from "@resources/icons/svg/rule/icon-rule-mes.svg";
import { ReactComponent as IconRulePeopleCount } from "@resources/icons/svg/rule/icon-rule-people-counting.svg";
import { ReactComponent as IconRuleTemperature } from "@resources/icons/svg/rule/icon-rule-temp.svg";
import { ReactComponent as IconCameraDomeOnline } from "@resources/icons/svg/icon-camera-dome-online.svg";
import { ReactComponent as IconCameraDomeOffline } from "@resources/icons/svg/icon-camera-dome-offline.svg";

import styled, { css } from "styled-components";
import { HVIcon } from "@repo/HVComponents";
import { ICON } from "../../../../../constants/icons";

const IconWrap = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
`;

const CameraIconWrap = styled(IconWrap)<{ circleColor: string }>`
  ${({ circleColor }) => css`
    svg {
      path:nth-child(2) {
        fill: ${circleColor} !important;
      }
    }
  `}
`;

export const getWidgetIcon = (type: WidgetType, size = 24) => {
  const iconSize = { width: size, height: size };
  switch (type) {
    case WIDGET_TYPE.CUMULATE_PEOPLECOUNTS:
    case WIDGET_TYPE.STAYING_PEOPLECOUNTS:
    case WIDGET_TYPE.ENTRY_EXIT_PEOPLECOUNTS:
    case WIDGET_TYPE.RANK_PEOPLECOUNTS:
      return <IconRulePeopleCount {...iconSize} />;
    case WIDGET_TYPE.MES_MANAGEMENT:
      return <IconRuleMES {...iconSize} />;
    case WIDGET_TYPE.CHECKOUT_QUEUE:
    case WIDGET_TYPE.NUMBER_OF_PEOPLE_BY_AREA:
      return <HVIcon name="queue-management" svgProps={{ ...iconSize }} />;
    case WIDGET_TYPE.SHOPPING_CART_QUEUE_MANAGEMENT:
    case WIDGET_TYPE.SHOPPING_CART_CHECKOUT_QUEUE:
      return (
        <HVIcon
          name="shopping-cart-queue-management"
          svgProps={{ ...iconSize }}
        />
      );
    case WIDGET_TYPE.VEHICLE_QUEUE:
      return (
        <HVIcon name="vehicle-queue-management" svgProps={{ ...iconSize }} />
      );
    case WIDGET_TYPE.SLIP_AND_FALL:
    case WIDGET_TYPE.SLIP_AND_FALL_STATISTICS:
      return <HVIcon name="slip-fall" svgProps={{ ...iconSize }} />;
    case WIDGET_TYPE.TEMPERATURE_EVENT:
    case WIDGET_TYPE.TEMPERATURE_STATISTICS:
      return <IconRuleTemperature {...iconSize} />;
    case WIDGET_TYPE.STOPPED_VEHICLE_EVENT:
    case WIDGET_TYPE.STOPPED_VEHICLE_STATISTICS:
      return <HVIcon name="stopped-vehicle" svgProps={{ ...iconSize }} />;
    case WIDGET_TYPE.TRAFFIC_JAM_EVENT:
    case WIDGET_TYPE.SLOW_TRAFFIC_STATISTICS:
      return <HVIcon name="traffic-jam" svgProps={{ ...iconSize }} />;
    case WIDGET_TYPE.CUMULATE_VEHICLECOUNTS:
    case WIDGET_TYPE.ENTRY_EXIT_VEHICLECOUNTS:
    case WIDGET_TYPE.STAYING_VEHICLECOUNTS:
      return <HVIcon name="vehicle-counting" svgProps={{ ...iconSize }} />;
    case WIDGET_TYPE.CROWD_SAFETY_STATISTICS:
      return <IconRuleCrowd {...iconSize} />;
    case WIDGET_TYPE.HEATMAP_PEOPLE:
      return <HVIcon name="people-heatmap" svgProps={{ ...iconSize }} />;
    case WIDGET_TYPE.VEHICLE_HEATMAP:
      return <HVIcon name="vehicle-heatmap" svgProps={{ ...iconSize }} />;
    case WIDGET_TYPE.SHOPPING_CART_HEATMAP:
      return <HVIcon name="shopping-cart-heatmap" svgProps={{ ...iconSize }} />;
    case WIDGET_TYPE.FORKLIFT_SPEED_EVENT:
    case WIDGET_TYPE.FORKLIFT_SPEED_STATISTICS:
      return <IconRuleForklift {...iconSize} />;
    case WIDGET_TYPE.CUMULATE_SHOPPING_CART_COUNTS:
    case WIDGET_TYPE.SHOPPING_CART_COUNT:
    case WIDGET_TYPE.SHOPPING_CART_LOSS_EVENT:
      return (
        <HVIcon name="shopping-cart-counting" svgProps={{ ...iconSize }} />
      );
    default:
      console.warn("No icon for widget type", type);
      return null;
  }
};

export const ruleDataMapper = (
  data: WidgetRuleInfos[],
  widgetType: WidgetType | undefined,
  dateUpdate?: WidgetRuleInfos
): WidgetRuleItem[] => {
  const result: WidgetRuleItem[] = [];

  data.forEach((deviceData) => {
    let hasRule = false;
    const device =
      dateUpdate?.deviceId === deviceData.deviceId ? dateUpdate : deviceData;

    if (!device) return;
    const deviceInfo: WidgetRuleItem = {
      value: device.deviceId,
      label: device.name,
      icon: <HVIcon name="device" svgProps={{ ...ICON.SIZE_16 }} />,
      metaData: {
        ...device,
        type: "camera"
      },
      licenseStatus: device.licenseStatus
    };

    if (device.channelInfo.length) {
      const channels: WidgetRuleItem[] = [];

      device.channelInfo.forEach((info, index) => {
        const channel = info.channel;
        const channelLabel = displayChannelNo(channel);
        const isCameraOnline = device.status === DEVICE_STATUS.CONNECT;
        let channelInfo: WidgetRuleItem = {
          label: `Channel ${channelLabel}`,
          value: `channel:${device.deviceId}:${channel}`,
          icon: (
            <CameraIconWrap
              circleColor={isCameraOnline ? "#56ab50" : "#747e87"}
              data-testid={isCameraOnline ? "camera-online" : "camera-offline"}
            >
              {isCameraOnline ? (
                <IconCameraDomeOnline />
              ) : (
                <IconCameraDomeOffline />
              )}
            </CameraIconWrap>
          ),
          hideArrow: false,
          metaData: {
            ...device,
            channel,
            type: "channel"
          },
          thumbnail: {
            width: info.maxResolution?.width || 0,
            height: info.maxResolution?.height || 0,
            path: info.thumbnailPath || ""
          },
          licenseStatus: device.licenseStatus
        };

        let rules: WidgetRuleItem[] = [];
        const RuleIcon = getWidgetIcon(widgetType as WidgetType);
        const icon = RuleIcon && <IconWrap>{RuleIcon}</IconWrap>;
        switch (widgetType) {
          case WIDGET_TYPE.CUMULATE_PEOPLECOUNTS:
          case WIDGET_TYPE.STAYING_PEOPLECOUNTS:
          case WIDGET_TYPE.ENTRY_EXIT_PEOPLECOUNTS:
          case WIDGET_TYPE.RANK_PEOPLECOUNTS:
            if (info.ai?.peopleCount?.rules.length) {
              channelInfo = {
                ...channelInfo,
                metaData: {
                  ...channelInfo.metaData,
                  enabledAiRule: info.ai?.peopleCount.enable
                },
                label: channelInfo?.metaData?.name as React.ReactNode // camera name -> rule name
              };
              rules = [
                ...rules,
                ...info.ai.peopleCount.rules.map((rule) => ({
                  label: `${rule.ruleNumber}: ${rule.ruleName}`,
                  value: `${AI_TYPE.PEOPLE_COUNT}:${device.deviceId}:${channel}:${rule.ruleName}`,
                  icon,
                  metaData: {
                    ...device,
                    channel,
                    ruleName: rule.ruleName,
                    type: AI_TYPE.PEOPLE_COUNT,
                    enabledAiRule: info.ai?.peopleCount.enable
                  },
                  thumbnail: {
                    width: info.maxResolution?.width || 0,
                    height: info.maxResolution?.height || 0,
                    path: info.thumbnailPath || ""
                  }
                }))
              ];
            }
            break;
          case WIDGET_TYPE.MES_MANAGEMENT:
          case WIDGET_TYPE.CHECKOUT_QUEUE:
          case WIDGET_TYPE.NUMBER_OF_PEOPLE_BY_AREA:
            if (info.ai?.queueManagement?.rules?.length) {
              channelInfo = {
                ...channelInfo,
                metaData: {
                  ...channelInfo.metaData,
                  enabledAiRule: info.ai?.queueManagement.enable
                },
                label: channelInfo?.metaData?.name as React.ReactNode // camera name -> rule name
              };
              rules = [
                ...rules,
                ...info.ai.queueManagement.rules.map((rule) => ({
                  label: `${rule.ruleNumber}: ${rule.ruleName}`,
                  value: `${AI_TYPE.QUEUE_MANAGEMENT}:${device.deviceId}:${channel}:${rule.ruleName}`,
                  icon,
                  metaData: {
                    ...device,
                    channel,
                    ruleName: rule.ruleName,
                    type: AI_TYPE.QUEUE_MANAGEMENT,
                    enabledAiRule: info.ai?.queueManagement.enable
                  },
                  thumbnail: {
                    width: info.maxResolution?.width || 0,
                    height: info.maxResolution?.height || 0,
                    path: info.thumbnailPath || ""
                  }
                }))
              ];
            }
            break;
          case WIDGET_TYPE.SHOPPING_CART_QUEUE_MANAGEMENT:
          case WIDGET_TYPE.SHOPPING_CART_CHECKOUT_QUEUE:
            if (info.ai?.shoppingCartQueueManagement?.rules?.length) {
              channelInfo = {
                ...channelInfo,
                metaData: {
                  ...channelInfo.metaData,
                  enabledAiRule: info.ai?.shoppingCartQueueManagement.enable
                },
                label: channelInfo?.metaData?.name as React.ReactNode // camera name -> rule name
              };
              rules = [
                ...rules,
                ...info.ai.shoppingCartQueueManagement.rules.map((rule) => ({
                  label: `${rule.ruleNumber}: ${rule.ruleName}`,
                  value: `${AI_TYPE.SHOPPING_CART_QUEUE_MANAGEMENT}:${device.deviceId}:${channel}:${rule.ruleName}`,
                  icon,
                  metaData: {
                    ...device,
                    channel,
                    ruleName: rule.ruleName,
                    type: AI_TYPE.SHOPPING_CART_QUEUE_MANAGEMENT,
                    enabledAiRule: info.ai?.shoppingCartQueueManagement.enable
                  },
                  thumbnail: {
                    width: info.maxResolution?.width || 0,
                    height: info.maxResolution?.height || 0,
                    path: info.thumbnailPath || ""
                  }
                }))
              ];
            }
            break;
          case WIDGET_TYPE.VEHICLE_QUEUE:
            if (info.ai?.vehicleQueueManagement?.rules?.length) {
              channelInfo = {
                ...channelInfo,
                metaData: {
                  ...channelInfo.metaData,
                  enabledAiRule: info.ai?.vehicleQueueManagement.enable
                },
                label: channelInfo?.metaData?.name as React.ReactNode // camera name -> rule name
              };
              rules = [
                ...rules,
                ...info.ai.vehicleQueueManagement.rules.map((rule) => ({
                  label: `${rule.ruleNumber}: ${rule.ruleName}`,
                  value: `${AI_TYPE.VEHICLE_QUEUE_MANAGEMENT}:${device.deviceId}:${channel}:${rule.ruleName}`,
                  icon,
                  metaData: {
                    ...device,
                    channel,
                    ruleName: rule.ruleName,
                    type: AI_TYPE.VEHICLE_QUEUE_MANAGEMENT,
                    enabledAiRule: info.ai?.vehicleQueueManagement.enable
                  },
                  thumbnail: {
                    width: info.maxResolution?.width || 0,
                    height: info.maxResolution?.height || 0,
                    path: info.thumbnailPath || ""
                  }
                }))
              ];
            }
            break;
          case WIDGET_TYPE.SLIP_AND_FALL:
          case WIDGET_TYPE.SLIP_AND_FALL_STATISTICS:
            if (info.ai?.slipAndFall?.rules?.length) {
              channelInfo = {
                ...channelInfo,
                metaData: {
                  ...channelInfo.metaData,
                  enabledAiRule: info.ai?.slipAndFall.enable
                },
                label: channelInfo?.metaData?.name as React.ReactNode // camera name -> rule name
              };
              rules = [
                ...rules,
                ...info.ai.slipAndFall.rules.map((rule) => ({
                  label: `${rule.ruleNumber}: ${rule.ruleName}`,
                  value: `${AI_TYPE.SLIP_AND_FALL}:${device.deviceId}:${channel}:${rule.ruleName}`,
                  icon,
                  metaData: {
                    ...device,
                    channel,
                    ruleName: rule.ruleName,
                    type: AI_TYPE.SLIP_AND_FALL,
                    enabledAiRule: info.ai?.slipAndFall.enable
                  },
                  thumbnail: {
                    width: info.maxResolution?.width || 0,
                    height: info.maxResolution?.height || 0,
                    path: info.thumbnailPath || ""
                  }
                }))
              ];
            }
            break;
          case WIDGET_TYPE.TEMPERATURE_EVENT:
          case WIDGET_TYPE.TEMPERATURE_STATISTICS:
            if (info.temperatureInfo?.temperatureROIs.length) {
              channelInfo = {
                ...channelInfo,
                metaData: {
                  ...channelInfo.metaData,
                  enabledAiRule: info.temperatureInfo.enable
                },
                label: channelInfo?.metaData?.name as React.ReactNode // camera name -> rule name
              };
              rules = [
                ...rules,
                ...info.temperatureInfo.temperatureROIs.map((rule) => ({
                  label: `${rule.index}: ${rule.name}`,
                  value: `${AI_TYPE.TEMPERATURE_DETECTION}:${device.deviceId}:${channel}:${rule.name}`,
                  icon,
                  metaData: {
                    ...device,
                    channel,
                    ruleName: rule.name,
                    type: AI_TYPE.TEMPERATURE_DETECTION,
                    enabledAiRule: info.temperatureInfo.enable
                  },
                  thumbnail: {
                    width: info.maxResolution?.width || 0,
                    height: info.maxResolution?.height || 0,
                    path: info.thumbnailPath || ""
                  }
                }))
              ];
            }
            break;
          case WIDGET_TYPE.STOPPED_VEHICLE_EVENT:
          case WIDGET_TYPE.STOPPED_VEHICLE_STATISTICS:
            if (info.ai?.stoppedVehicle?.rules?.length) {
              channelInfo = {
                ...channelInfo,
                metaData: {
                  ...channelInfo.metaData,
                  enabledAiRule: info.ai?.stoppedVehicle.enable
                },
                label: channelInfo?.metaData?.name as React.ReactNode // camera name -> rule name
              };
              rules = [
                ...rules,
                ...info.ai.stoppedVehicle.rules.map((rule) => ({
                  label: `${rule.ruleNumber}: ${rule.ruleName}`,
                  value: `${AI_TYPE.STOPPED_VEHICLE}:${device.deviceId}:${channel}:${rule.ruleName}`,
                  icon,
                  metaData: {
                    ...device,
                    channel,
                    ruleName: rule.ruleName,
                    type: AI_TYPE.STOPPED_VEHICLE,
                    enabledAiRule: info.ai?.stoppedVehicle.enable
                  },
                  thumbnail: {
                    width: info.maxResolution?.width || 0,
                    height: info.maxResolution?.height || 0,
                    path: info.thumbnailPath || ""
                  }
                }))
              ];
            }
            break;
          case WIDGET_TYPE.TRAFFIC_JAM_EVENT:
          case WIDGET_TYPE.SLOW_TRAFFIC_STATISTICS:
            if (info.ai?.trafficJam?.rules?.length) {
              channelInfo = {
                ...channelInfo,
                metaData: {
                  ...channelInfo.metaData,
                  enabledAiRule: info.ai?.trafficJam.enable
                },
                label: channelInfo?.metaData?.name as React.ReactNode // camera name -> rule name
              };
              rules = [
                ...rules,
                ...info.ai.trafficJam.rules.map((rule) => ({
                  label: `${rule.ruleNumber}: ${rule.ruleName}`,
                  value: `${AI_TYPE.TRAFFIC_JAM}:${device.deviceId}:${channel}:${rule.ruleName}`,
                  icon,
                  metaData: {
                    ...device,
                    channel,
                    ruleName: rule.ruleName,
                    type: AI_TYPE.TRAFFIC_JAM,
                    enabledAiRule: info.ai?.trafficJam.enable
                  },
                  thumbnail: {
                    width: info.maxResolution?.width || 0,
                    height: info.maxResolution?.height || 0,
                    path: info.thumbnailPath || ""
                  }
                }))
              ];
            }
            break;
          case WIDGET_TYPE.CUMULATE_VEHICLECOUNTS:
          case WIDGET_TYPE.ENTRY_EXIT_VEHICLECOUNTS:
          case WIDGET_TYPE.STAYING_VEHICLECOUNTS:
            if (info.ai?.vehicleCount?.rules?.length) {
              channelInfo = {
                ...channelInfo,
                metaData: {
                  ...channelInfo.metaData,
                  enabledAiRule: info.ai?.vehicleCount.enable
                },
                label: channelInfo?.metaData?.name as React.ReactNode // camera name -> rule name
              };
              rules = [
                ...rules,
                ...info.ai.vehicleCount.rules.map((rule) => ({
                  label: `${rule.ruleNumber}: ${rule.ruleName}`,
                  value: `${AI_TYPE.VEHICLE_COUNT}:${device.deviceId}:${channel}:${rule.ruleName}`,
                  icon,
                  metaData: {
                    ...device,
                    channel,
                    ruleName: rule.ruleName,
                    type: AI_TYPE.VEHICLE_COUNT,
                    enabledAiRule: info.ai?.vehicleCount.enable
                  },
                  thumbnail: {
                    width: info.maxResolution?.width || 0,
                    height: info.maxResolution?.height || 0,
                    path: info.thumbnailPath || ""
                  }
                }))
              ];
            }
            break;
          case WIDGET_TYPE.CROWD_SAFETY_STATISTICS:
            if (info.ai?.crowdCount?.rules?.length) {
              channelInfo = {
                ...channelInfo,
                metaData: {
                  ...channelInfo.metaData,
                  enabledAiRule: info.ai?.crowdCount.enable
                },
                label: channelInfo?.metaData?.name as React.ReactNode // camera name -> rule name
              };
              rules = [
                ...rules,
                ...info.ai.crowdCount.rules.map((rule) => ({
                  label: `${rule.ruleNumber}: ${rule.ruleName}`,
                  value: `${AI_TYPE.CROWD_COUNT}:${device.deviceId}:${channel}:${rule.ruleName}`,
                  icon,
                  metaData: {
                    ...device,
                    channel,
                    ruleName: rule.ruleName,
                    type: AI_TYPE.CROWD_COUNT,
                    enabledAiRule: info.ai?.crowdCount.enable
                  },
                  thumbnail: {
                    width: info.maxResolution?.width || 0,
                    height: info.maxResolution?.height || 0,
                    path: info.thumbnailPath || ""
                  }
                }))
              ];
            }
            break;
          case WIDGET_TYPE.HEATMAP_PEOPLE:
            if (info.ai?.heatmap?.enable) {
              channelInfo = {
                ...channelInfo,
                value: `${AI_TYPE.HEATMAP}:${device.deviceId}:${channel}`,
                metaData: {
                  ...channelInfo.metaData,
                  enabledAiRule: info.ai?.heatmap.enable,
                  type: AI_TYPE.HEATMAP,
                  icon
                },
                label: channelInfo?.metaData?.name as React.ReactNode, // camera name -> rule name
                thumbnail: {
                  width: info.maxResolution?.width || 0,
                  height: info.maxResolution?.height || 0,
                  path: info.thumbnailPath || ""
                }
              };
              hasRule = true;
            }
            break;
          case WIDGET_TYPE.VEHICLE_HEATMAP:
            if (info.ai?.vehicleheatmap?.enable) {
              channelInfo = {
                ...channelInfo,
                value: `${AI_TYPE.VEHICLE_HEATMAP}:${device.deviceId}:${channel}`,
                metaData: {
                  ...channelInfo.metaData,
                  enabledAiRule: info.ai?.vehicleheatmap.enable,
                  type: AI_TYPE.VEHICLE_HEATMAP,
                  icon
                },
                label: channelInfo?.metaData?.name as React.ReactNode, // camera name -> rule name
                thumbnail: {
                  width: info.maxResolution?.width || 0,
                  height: info.maxResolution?.height || 0,
                  path: info.thumbnailPath || ""
                }
              };
              hasRule = true;
            }
            break;
          case WIDGET_TYPE.SHOPPING_CART_HEATMAP:
            if (info.ai?.shoppingcartheatmap?.enable) {
              channelInfo = {
                ...channelInfo,
                value: `${AI_TYPE.SHOPPING_CART_HEATMAP}:${device.deviceId}:${channel}`,
                metaData: {
                  ...channelInfo.metaData,
                  enabledAiRule: info.ai?.shoppingcartheatmap.enable,
                  type: AI_TYPE.SHOPPING_CART_HEATMAP,
                  icon
                },
                label: channelInfo?.metaData?.name as React.ReactNode, // camera name -> rule name
                thumbnail: {
                  width: info.maxResolution?.width || 0,
                  height: info.maxResolution?.height || 0,
                  path: info.thumbnailPath || ""
                }
              };
              hasRule = true;
            }
            break;
          case WIDGET_TYPE.FORKLIFT_SPEED_EVENT:
          case WIDGET_TYPE.FORKLIFT_SPEED_STATISTICS:
            if (info.ai?.forkliftSpeedDetection.enable) {
              channelInfo = {
                ...channelInfo,
                value: `${AI_TYPE.FORKLIFT_SPEED}:${device.deviceId}:${channel}`,
                metaData: {
                  ...channelInfo.metaData,
                  enabledAiRule: info.ai?.forkliftSpeedDetection.enable,
                  type: AI_TYPE.FORKLIFT_SPEED,
                  icon
                },
                label: channelInfo?.metaData?.name as React.ReactNode, // camera name -> rule name
                thumbnail: {
                  width: info.maxResolution?.width || 0,
                  height: info.maxResolution?.height || 0,
                  path: info.thumbnailPath || ""
                }
              };
              hasRule = true;
            }
            break;
          case WIDGET_TYPE.CUMULATE_SHOPPING_CART_COUNTS:
          case WIDGET_TYPE.SHOPPING_CART_COUNT:
            if (info.ai?.shoppingCartCount?.rules.length) {
              channelInfo = {
                ...channelInfo,
                metaData: {
                  ...channelInfo.metaData,
                  enabledAiRule: info.ai?.shoppingCartCount.enable
                },
                label: channelInfo?.metaData?.name as React.ReactNode // camera name -> rule name
              };
              rules = [
                ...rules,
                ...info.ai.shoppingCartCount.rules.map((rule) => ({
                  label: `${rule.ruleNumber}: ${rule.ruleName}`,
                  value: `${AI_TYPE.SHOPPING_CART_COUNT}:${device.deviceId}:${channel}:${rule.ruleName}`,
                  icon,
                  metaData: {
                    ...device,
                    channel,
                    ruleName: rule.ruleName,
                    type: AI_TYPE.SHOPPING_CART_COUNT,
                    enabledAiRule: info.ai?.shoppingCartCount.enable
                  },
                  thumbnail: {
                    width: info.maxResolution?.width || 0,
                    height: info.maxResolution?.height || 0,
                    path: info.thumbnailPath || ""
                  }
                }))
              ];
            }
            break;
          case WIDGET_TYPE.SHOPPING_CART_LOSS_EVENT:
            if (info.ai?.shoppingCartCount?.rules.length) {
              channelInfo = {
                ...channelInfo,
                metaData: {
                  ...channelInfo.metaData,
                  enabledAiRule: info.ai?.shoppingCartCount.enable
                },
                label: channelInfo?.metaData?.name as React.ReactNode // camera name -> rule name
              };
              rules = [
                ...rules,
                ...info.ai.shoppingCartCount.rules.map((rule) => ({
                  label: `${rule.ruleNumber}: ${rule.ruleName}`,
                  value: `${AI_TYPE.SHOPPING_CART_LOSS}:${device.deviceId}:${channel}:${rule.ruleName}`,
                  icon,
                  metaData: {
                    ...device,
                    channel,
                    ruleName: rule.ruleName,
                    type: AI_TYPE.SHOPPING_CART_LOSS,
                    enabledAiRule: info.ai?.shoppingCartCount.enable
                  },
                  thumbnail: {
                    width: info.maxResolution?.width || 0,
                    height: info.maxResolution?.height || 0,
                    path: info.thumbnailPath || ""
                  }
                }))
              ];
            }
            break;
          default:
            channelInfo = {
              ...channelInfo,
              metaData: {
                ...channelInfo.metaData,
                enabledAiRule: info.ai?.peopleCount.enable
              },
              label: channelInfo?.metaData?.name as React.ReactNode // camera name -> rule name
            };
            rules = [
              ...rules,
              // 1) people counting
              ...(info.ai?.peopleCount.rules ?? []).map((rule) => ({
                label: `${rule.ruleNumber}: ${rule.ruleName}`,
                value: `${AI_TYPE.PEOPLE_COUNT}:${device.deviceId}:${channel}:${rule.ruleName}`,
                icon,
                metaData: {
                  ...device,
                  channel,
                  ruleName: rule.ruleName,
                  type: AI_TYPE.PEOPLE_COUNT,
                  enabledAiRule: info.ai?.peopleCount.enable
                },
                thumbnail: {
                  width: info.maxResolution?.width || 0,
                  height: info.maxResolution?.height || 0,
                  path: info.thumbnailPath || ""
                }
              })),
              // 2) people queue
              ...(info.ai?.queueManagement.rules ?? []).map((rule) => ({
                label: `${rule.ruleNumber}: ${rule.ruleName}`,
                value: `${AI_TYPE.QUEUE_MANAGEMENT}:${device.deviceId}:${channel}:${rule.ruleName}`,
                icon,
                metaData: {
                  ...device,
                  channel,
                  ruleName: rule.ruleName,
                  type: AI_TYPE.QUEUE_MANAGEMENT,
                  enabledAiRule: info.ai?.queueManagement.enable
                },
                thumbnail: {
                  width: info.maxResolution?.width || 0,
                  height: info.maxResolution?.height || 0,
                  path: info.thumbnailPath || ""
                }
              })),
              // 3) slip & fall deteciton
              ...(info.ai?.slipAndFall.rules ?? []).map((rule) => ({
                label: `${rule.ruleNumber}: ${rule.ruleName}`,
                value: `${AI_TYPE.SLIP_AND_FALL}:${device.deviceId}:${channel}:${rule.ruleName}`,
                icon,
                metaData: {
                  ...device,
                  channel,
                  ruleName: rule.ruleName,
                  type: AI_TYPE.SLIP_AND_FALL,
                  enabledAiRule: info.ai?.slipAndFall.enable
                },
                thumbnail: {
                  width: info.maxResolution?.width || 0,
                  height: info.maxResolution?.height || 0,
                  path: info.thumbnailPath || ""
                }
              })),
              // 4) crowd detection
              ...(info.ai?.crowdCount.rules ?? []).map((rule) => ({
                label: `${rule.ruleNumber}: ${rule.ruleName}`,
                value: `${AI_TYPE.CROWD_COUNT}:${device.deviceId}:${channel}:${rule.ruleName}`,
                icon,
                metaData: {
                  ...device,
                  channel,
                  ruleName: rule.ruleName,
                  type: AI_TYPE.CROWD_COUNT,
                  enabledAiRule: info.ai?.crowdCount.enable
                },
                thumbnail: {
                  width: info.maxResolution?.width || 0,
                  height: info.maxResolution?.height || 0,
                  path: info.thumbnailPath || ""
                }
              })),
              // 5) shopping cart counting
              ...(info.ai?.shoppingCartCount.rules ?? []).map((rule) => ({
                label: `${rule.ruleNumber}: ${rule.ruleName}`,
                value: `${AI_TYPE.SHOPPING_CART_COUNT}:${device.deviceId}:${channel}:${rule.ruleName}`,
                icon,
                metaData: {
                  ...device,
                  channel,
                  ruleName: rule.ruleName,
                  type: AI_TYPE.SHOPPING_CART_COUNT,
                  enabledAiRule: info.ai?.shoppingCartCount.enable
                },
                thumbnail: {
                  width: info.maxResolution?.width || 0,
                  height: info.maxResolution?.height || 0,
                  path: info.thumbnailPath || ""
                }
              })),
              // 6) shopping cart queue
              ...(info.ai?.shoppingCartQueueManagement.rules ?? []).map(
                (rule) => ({
                  label: `${rule.ruleNumber}: ${rule.ruleName}`,
                  value: `${AI_TYPE.SHOPPING_CART_QUEUE_MANAGEMENT}:${device.deviceId}:${channel}:${rule.ruleName}`,
                  icon,
                  metaData: {
                    ...device,
                    channel,
                    ruleName: rule.ruleName,
                    type: AI_TYPE.SHOPPING_CART_QUEUE_MANAGEMENT,
                    enabledAiRule: info.ai?.shoppingCartQueueManagement.enable
                  },
                  thumbnail: {
                    width: info.maxResolution?.width || 0,
                    height: info.maxResolution?.height || 0,
                    path: info.thumbnailPath || ""
                  }
                })
              ),
              // 7) vehicle counting
              ...(info.ai?.vehicleCount.rules ?? []).map((rule) => ({
                label: `${rule.ruleNumber}: ${rule.ruleName}`,
                value: `${AI_TYPE.VEHICLE_COUNT}:${device.deviceId}:${channel}:${rule.ruleName}`,
                icon,
                metaData: {
                  ...device,
                  channel,
                  ruleName: rule.ruleName,
                  type: AI_TYPE.VEHICLE_COUNT,
                  enabledAiRule: info.ai?.vehicleCount.enable
                },
                thumbnail: {
                  width: info.maxResolution?.width || 0,
                  height: info.maxResolution?.height || 0,
                  path: info.thumbnailPath || ""
                }
              })),
              // 8) vehicle queue
              ...(info.ai?.vehicleQueueManagement.rules ?? []).map((rule) => ({
                label: `${rule.ruleNumber}: ${rule.ruleName}`,
                value: `${AI_TYPE.VEHICLE_QUEUE_MANAGEMENT}:${device.deviceId}:${channel}:${rule.ruleName}`,
                icon,
                metaData: {
                  ...device,
                  channel,
                  ruleName: rule.ruleName,
                  type: AI_TYPE.VEHICLE_QUEUE_MANAGEMENT,
                  enabledAiRule: info.ai?.vehicleQueueManagement.enable
                },
                thumbnail: {
                  width: info.maxResolution?.width || 0,
                  height: info.maxResolution?.height || 0,
                  path: info.thumbnailPath || ""
                }
              })),
              // 9) stopped vehicle
              ...(info.ai?.stoppedVehicle.rules ?? []).map((rule) => ({
                label: `${rule.ruleNumber}: ${rule.ruleName}`,
                value: `${AI_TYPE.STOPPED_VEHICLE}:${device.deviceId}:${channel}:${rule.ruleName}`,
                icon,
                metaData: {
                  ...device,
                  channel,
                  ruleName: rule.ruleName,
                  type: AI_TYPE.STOPPED_VEHICLE,
                  enabledAiRule: info.ai?.stoppedVehicle.enable
                },
                thumbnail: {
                  width: info.maxResolution?.width || 0,
                  height: info.maxResolution?.height || 0,
                  path: info.thumbnailPath || ""
                }
              })),
              // 10) Traffic jam
              ...(info.ai?.trafficJam.rules ?? []).map((rule) => ({
                label: `${rule.ruleNumber}: ${rule.ruleName}`,
                value: `${AI_TYPE.TRAFFIC_JAM}:${device.deviceId}:${channel}:${rule.ruleName}`,
                icon,
                metaData: {
                  ...device,
                  channel,
                  ruleName: rule.ruleName,
                  type: AI_TYPE.TRAFFIC_JAM,
                  enabledAiRule: info.ai?.trafficJam.enable
                },
                thumbnail: {
                  width: info.maxResolution?.width || 0,
                  height: info.maxResolution?.height || 0,
                  path: info.thumbnailPath || ""
                }
              }))
            ];

            break;
        }

        if (rules.length) {
          channelInfo.children = rules;
          rules = [];
          hasRule = true;
        }
        channels.push(channelInfo);

        if (!index) {
          deviceInfo.thumbnail = {
            width: info?.maxResolution?.width || 0,
            height: info?.maxResolution?.height || 0,
            path: info?.thumbnailPath || ""
          };
        }
      });

      if (hasRule) {
        deviceInfo.children = channels;
      }
    }

    if (hasRule) {
      result.push(deviceInfo);
    }
  });
  // TODO : data processing optimization required (tmep code)
  return result.map((camera) => camera.children ?? []).flat();
};

const getLicenseStatus = (licenseInfo: DeviceLicenseInfo): LicenseStatus => {
  if (!licenseInfo) return "No License";

  if (licenseInfo.retentionPeriodType === "Grace") return "No License";

  if (new Date() > new Date(licenseInfo.expiryDate)) return "Expired";

  return "Normal";
};

export const devicesDataMapper = (
  allDevices: PortalDeviceWithLinkedLicenses[],
  deviceConfig: GetPortalDevicesConfigResponse
): WidgetRuleInfos[] => {
  return deviceConfig.reduce((previous: WidgetRuleInfos[], deviceConfig) => {
    const deviceInfo = allDevices.find(
      (meta) => meta.gatewayId === deviceConfig.gatewayId
    ) as PortalDeviceWithLinkedLicenses;

    const licenseInfo = allDevices
      .filter((device) => device.gatewayId !== device.id)
      .find(
        (device) => device.gatewayId === deviceConfig.gatewayId
      ) as PortalDeviceWithLinkedLicenses;

    if (deviceInfo) {
      const result: WidgetRuleInfos = {
        enabled: deviceInfo.connectionStatus === "online",
        mac: deviceInfo.mac,
        area: deviceInfo.areaName,
        location: deviceInfo.locationName,
        serial: deviceInfo.serial,
        waveSync: true,
        model: deviceInfo.model,
        name: deviceInfo.name,
        channelInfo: [...deviceConfig.channelInfo],
        status:
          deviceInfo.connectionStatus === "online"
            ? DEVICE_STATUS.CONNECT
            : DEVICE_STATUS.DISCONNECT,
        deviceId: deviceConfig.gatewayId,
        licenseStatus: getLicenseStatus(deviceConfig.licenseInfo),
        linkedLicenses: (licenseInfo && licenseInfo.linkedLicenses) || {
          waveSync: WAVE_SYNC_STATUS.LINK_DISCONNECT,
          onCloud: ON_CLOUD_STATUS.LINK_DISCONNECT
        }
      };
      previous.push(result);
    }
    return previous;
  }, []);
};
