import { HVThemeProvider } from "@repo/HVComponents";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { getTheme } from "@themes/index";
import { useThemeMode } from "@hooks/useThemeMode";

interface ThemeProps {
  children: React.ReactNode;
}

export const ThemeProvider = (props: ThemeProps) => {
  const { mode } = useThemeMode();

  return (
    <HVThemeProvider forceColorScheme={mode}>
      {/* TOBE : <ThemeProvider /> will be removed after HV Component Migration */}
      <StyledThemeProvider
        theme={getTheme(
          String(mode).replace(/^[a-z]/, (m) => m.toUpperCase()) as
            | "Light"
            | "Dark"
        )}
      >
        {props.children}
      </StyledThemeProvider>
    </HVThemeProvider>
  );
};
