import { IconChat } from "@resources/icons/Icons";
import * as S from "./Card.styled";

interface CardProps {
  title: string;
  subtitle: string;
  onSendChat: (msg: string) => void;
}

export const Card = ({ title, subtitle, onSendChat }: CardProps) => {
  return (
    <S.CardWrapper onClick={() => onSendChat(subtitle)}>
      <S.IconWrapper>
        <IconChat width={20} height={20} />
      </S.IconWrapper>
      <S.CardText>
        <h3>{title}</h3>
        <p>{subtitle}</p>
      </S.CardText>
    </S.CardWrapper>
  );
};
