import { useState, useEffect } from "react";

type SessionStorageValue<T> = T | null;

function useSessionStorage<T>(
  key: string,
  initialValue: T,
): [SessionStorageValue<T>, (value: T) => void] {
  const storedValue =
    sessionStorage.getItem(key) || JSON.stringify(initialValue);

  const parsedValue = JSON.parse(storedValue) as SessionStorageValue<T>;

  const [value, setValue] = useState<SessionStorageValue<T>>(parsedValue);

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [parsedValue, setValue];
}

export default useSessionStorage;
