import styled from "styled-components";

export const CardWrapper = styled.div`
  background-color: var(--background-2nd-button-press);
  padding: 19px 16px;
  border-radius: 12px;
  display: flex;
  align-items: start;
  gap: 12px;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  background-color: var(--surface-popup);
  width: 48px;
  height: 48px;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg path {
    fill: var(--icon-emphasis);
  }
`;

export const CardText = styled.div`
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  h3 {
    margin-bottom: 4px;
    color: var(--text-primary);
    line-height: 20px;
  }

  p {
    color: var(--text-secondary-2);
    line-height: 18px;
  }
`;
