import { Breadcrumbs } from "@mantine/core";
import styles from "./HVBreadcrumbs.module.css";
import IHVBreadcrumbsProps, { IHVBreadcrumbItem } from "./IHVBreadcrumbsProps";
import HVText from "../HVText/HVText";
import HVFlex from "../HVFlex/HVFlex";
import HVIcon from "../HVIcon/HVIcon";
import { svgProps } from "../../utils/Utils";

const HVBreadcrumbItem = ({
  item,
  type,
  ...rest
}: {
  item: IHVBreadcrumbItem;
  type?: "sm" | "md";
}) => {
  return (
    <HVFlex
      direction={"row"}
      align={"center"}
      gap={"var(--mantine-spacing-1)"}
      className={styles.flex}
      onClick={item?.onClick}
      {...rest}
    >
      {item.variant === "More" ? (
        <HVText
          type={type === "md" ? "Body-md" : "Body-xs"}
          className={`${styles.text} ${item.isActive && styles.textActive}`}
        >
          ...
        </HVText>
      ) : (
        <>
          {item.icon}
          <HVText
            type={type === "md" ? "Body-md" : "Body-xs"}
            className={`${styles.text} ${item.isActive && styles.textActive}`}
          >
            {item.label}
          </HVText>
        </>
      )}
    </HVFlex>
  );
};
const itemList = ({ type, items }: IHVBreadcrumbsProps) => {
  return items?.map((item, index) => (
    <HVBreadcrumbItem item={item} type={type} key={index.toString()} />
  ));
};

function HVBreadcrumbs({
  type = "sm",
  breadcrumbsProps,
  items,
  children,
}: IHVBreadcrumbsProps) {
  return (
    <Breadcrumbs
      separator={
        <HVIcon
          name="arrow-chevron-right"
          svgProps={svgProps(
            type === "md" ? 20 : 16,
            "",
            "var(--hv-text-normal-secondary)",
          )}
        />
      }
      {...breadcrumbsProps}
      className={`${breadcrumbsProps?.className}`}
    >
      {children ? children : itemList({ type, items })}
    </Breadcrumbs>
  );
}

export default HVBreadcrumbs;
