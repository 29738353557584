interface LoaderSVGProps {
  primaryStroke: string;
  secondaryStroke: string;
  strokeWidth?: string | number;
  size?: number | string;
}

const LoaderSVG = ({
  primaryStroke,
  secondaryStroke,
  strokeWidth,
  size,
}: LoaderSVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="25 25 50 50"
      className="circular"
      width={size}
      height={size}
    >
      <style>
        {`
    .circular {
      animation: rotate 1.33s linear infinite;
      transform-origin: center center;
      margin: auto;
    }
    .path {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      animation: dash 1.5s ease-in-out infinite;
      stroke-linecap: round;
      stroke: ${primaryStroke};
    }
    .path-outline {
      stroke: ${secondaryStroke};
    }
    /* Keyframe animations */
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -21px;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
      }
    }
      `}
      </style>
      <circle
        className="path-outline"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        strokeWidth={strokeWidth ?? "2"}
        strokeMiterlimit="10"
      />
      <circle
        className="path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        strokeWidth={strokeWidth ?? "2"}
        strokeMiterlimit="10"
      />
    </svg>
  );
};
export default LoaderSVG;
