import { useEffect, useRef } from "react";
import { Input } from "@components/common/input";
import useDetectOutsideClick from "@hooks/useDetectOutsideClick";
import { useInputHook } from "@hooks/useInputHook";
import _ from "lodash";
import { HVButton, HVDropdown, HVIcon } from "@repo/HVComponents";
import { MAX_LENGTH_ANALYTICS_NAME } from "@resources/Constants";
import * as S from "../TopMenu.styled";
import { ICON } from "../../../../constants/icons";

const DEBOUNCE_TIME_MS = 100;
interface FilterListDropdownButtonProps {
  editMode: boolean;
  filters?: {
    value: string;
    label: string;
  }[];
  defaultIdx: number;
  onChange: (v: string) => void;
  onChangeName: (v: string) => void;
  onChangeMode: (type: "EDIT" | "DROP_DOWN") => void;
}
export const FilterListDropdownButton = (
  props: FilterListDropdownButtonProps
) => {
  const filterNameList = props.filters ?? [
    {
      value: "Analytics list",
      label: "Analytics list"
    }
  ];

  const defaultFilter = useRef<{
    value: string;
    label: string;
  }>(filterNameList[props.defaultIdx]);

  const rootRef = useRef<HTMLDivElement>(null);
  const { inputRef, getValueFromRef, setValue, value } = useInputHook<string>(
    defaultFilter.current?.label
  );
  const updateName = _.debounce(() => {
    if (props.editMode) {
      const changed = getValueFromRef();
      if (changed && defaultFilter.current?.label !== changed) {
        props.onChangeName(changed);
      }

      if (!changed) {
        setValue(defaultFilter.current.label);
      }

      props.onChangeMode("DROP_DOWN");
    }
  }, DEBOUNCE_TIME_MS);

  useDetectOutsideClick(rootRef, () => updateName());

  useEffect(() => {
    if (props.editMode) {
      inputRef.current?.focus();
    }
  }, [props.editMode]);

  return (
    <S.SelectWrap ref={rootRef}>
      {(() => {
        if (props.editMode) {
          return (
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  updateName();
                }
              }}
            >
              <Input
                innerRef={inputRef}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                style={{ width: "200px", paddingRight: 24 }}
                maxLength={MAX_LENGTH_ANALYTICS_NAME}
              />
              <HVButton
                variant="Ghost"
                w={32}
                h={32}
                p={0}
                onClick={(e) => {
                  e.stopPropagation();
                  setValue("");
                  inputRef.current?.focus();
                }}
                styles={{
                  root: {
                    position: "absolute",
                    right: 8,
                    display: "flex"
                  }
                }}
              >
                <HVIcon name="fail" svgProps={{ ...ICON.SIZE_16 }} />
              </HVButton>
            </div>
          );
        }

        return (
          <HVDropdown
            type="medium"
            options={filterNameList}
            selectedItem={defaultFilter.current?.value}
            getSelectedValue={(value) => {
              value && props.onChange(value as string);
            }}
            selectWithArrow={false}
          />
        );
      })()}
    </S.SelectWrap>
  );
};
