import { useCallback, useState } from "react";
import Treeview from "@components/common/tree-view";
import {
  DataTreeViewItemValue,
  FlatNodeItem
} from "@components/common/tree-view/Interfaces";
import { getMaxSelected } from "@utils/Widget";
import { WidgetRuleProps } from "@components/widgets/base/setup/widget-rules/Interfaces";
import { useAnalyticsRuleData } from "@hooks/analytics/useAnalyticsRuleData";
import { TreeItem } from "@components/widgets/base/setup/widget-rules/sub/TreeItem";
import { HVLoader } from "@repo/HVComponents";
import * as S from "./AnalyticsRuleTree.styled";

export const AnalyticsRuleTree = (props: WidgetRuleProps) => {
  const {
    onSelected,
    onChecked,
    rulesChecked = [],
    widgetType,
    hoverItem,
    onHover
  } = props;
  const [expanded, setExpanded] = useState<DataTreeViewItemValue[]>([]);

  // use pre-fetched cache data from RuleButton.tsx
  const { data, isLoading } = useAnalyticsRuleData(
    {},
    {
      refetchOnMount: false
    }
  );

  const onRenderItem = useCallback(
    (props: FlatNodeItem) => <TreeItem {...props} />,
    []
  );

  return (
    <S.Container>
      {isLoading || props.isForceRefreshing ? (
        <S.LoadingWrap>
          <HVLoader
            size={80}
            containerProps={{ h: "100%", justify: "center" }}
          />
        </S.LoadingWrap>
      ) : (
        <S.TreeWrap>
          <Treeview
            nodes={data}
            checked={rulesChecked}
            disabled={false}
            expanded={expanded}
            name="rule-tree-view"
            showIcon
            showCheckbox
            maxSelected={getMaxSelected(widgetType)}
            onClicked={onSelected}
            onChecked={onChecked}
            onExpanded={(expanded: FlatNodeItem[]) =>
              setExpanded(expanded.map((node) => node.value))
            }
            onRenderItem={(item) => onRenderItem(item)}
            hoverItem={hoverItem}
            onHover={onHover}
            useHoverTooltip
            isFirstAlwaysExpand
          />
        </S.TreeWrap>
      )}
    </S.Container>
  );
};
