/* eslint-disable no-underscore-dangle, no-case-declarations */
import { useMemo, useRef, useState } from "react";
import {
  FilterType,
  getDefaultChartOrder,
  Hide,
  Order
} from "@queries/tanstack/analytics-filter/AnalyticsFilterQuery";
import { CalendarModeRangeType } from "@custom-types/Types";
import _ from "lodash";
import { useLang } from "@hooks/useLang";
import { FlatNodeItem } from "@components/common/tree-view/Interfaces";
import { useHVSnackbar } from "@repo/HVComponents";
import * as S from "./TopMenu.styled";
import { SaveButton } from "./btns/SaveButton";
import { MORE_TYPE, MoreButton } from "./btns/MoreButton";
import { CalendarButton } from "./btns/CalendarButton";
import { RuleButton } from "./btns/RuleButton";
import { FilterListDropdownButton } from "./btns/FilterListDropdownButton";
import { OrderingButton } from "./btns/OrderingButton";

interface TopMenuProps {
  filters: FilterType[];
  filterIdx: number;
  onSearch: (filter: FilterType) => void;
  onSave: (
    type: "SAVE" | "SAVE_AS",
    options: { fileName: string; id?: string }
  ) => void;
  onRemove: (id: string) => void;
  onChange: (idx: number) => void;
  onChangeFilterName: (id: string, filterName: string) => void;
  onChangeOrderAndHide: (options: { order: Order[]; hide: Hide[] }) => void;
}
export const TopMenu = (props: TopMenuProps) => {
  const { translate } = useLang();
  const [tempSavedFilter, setTempSavedFilter] = useState<FilterType>(
    props.filters?.[props.filterIdx] ?? {
      filterName: "",
      order: [],
      hide: []
    }
  );

  const selectedFilterId = props.filters[props.filterIdx]?._id;
  const prevTempSavedFilter = useRef<FilterType>(tempSavedFilter);
  const [isEditMode, setIsEditMode] = useState(false);
  const { showSnackbar } = useHVSnackbar();
  const [isSearched, setIsSearched] = useState(false);
  const [isChangedDate, setIsChangedDate] = useState(false);
  const [isChangedRule, setIsChangedRule] = useState(false);
  const [isChangedOrder, setIsChangedOrder] = useState(false);

  const handleSearch = () => {
    if (tempSavedFilter) {
      if (_.isEqual(prevTempSavedFilter.current, tempSavedFilter)) {
        return;
      }

      const newTempSavedFilter = {
        ...tempSavedFilter,
        hide: [],
        order: getDefaultChartOrder(tempSavedFilter.rules)
      };

      prevTempSavedFilter.current = _.cloneDeep(newTempSavedFilter);
      props.onSearch(newTempSavedFilter);
      setTempSavedFilter(newTempSavedFilter);
      setIsSearched(true);
    }
  };

  const handleMore = (moreType: MORE_TYPE) => {
    switch (moreType) {
      case "EDIT_FILTER_NAME":
        setIsEditMode(true);
        break;
      case "REMOVE_FILTER":
        if (selectedFilterId) {
          props.onRemove(selectedFilterId);
        }
        break;
      default:
        break;
    }
  };

  const disableSearch =
    !tempSavedFilter?.baseTime ||
    !tempSavedFilter?.rules ||
    tempSavedFilter?.rules.length === 0 ||
    (!isChangedDate && !isChangedRule);

  const disableSave = (() => {
    return !isSearched && !isChangedOrder;
  })();

  const filterList = useMemo(() => {
    if (props.filters.length > 0) {
      return props.filters.map((filter, idx) => ({
        value: idx.toString(),
        label: filter.filterName
      }));
    }

    return undefined;
  }, [props.filters]);

  const isValidName = (name: string) => {
    return !_.isEmpty(name.trim());
  };

  const handleSave = (
    status: "SAVE" | "SAVE_AS",
    options: {
      fileName?: string;
    }
  ) => {
    const fileName =
      options.fileName || props.filters[props.filterIdx].filterName;
    if (fileName && isValidName(fileName)) {
      // change filter name ( save as, change filter name )
      props.onSave(status, { fileName, id: selectedFilterId });
    } else {
      showSnackbar({
        id: "enter-right-name",
        message: translate("analytics_enter_right_name"),
        variant: "fail"
      });
      return;
    }

    // initialize
    setIsChangedDate(false);
    setIsChangedRule(false);
  };

  const handleChangeName = (name: string) => {
    if (selectedFilterId) {
      if (isValidName(name)) {
        props.onChangeFilterName(selectedFilterId, name);
      } else {
        showSnackbar({
          id: "enter-right-name",
          message: translate("analytics_enter_right_name"),
          variant: "fail"
        });
      }
    } else {
      showSnackbar({
        id: "filter-id-not-found",
        message: "filter id not found",
        variant: "fail"
      });
    }
  };

  const handleChangeDate = (v: {
    start: string;
    end: string;
    mode: CalendarModeRangeType;
  }) => {
    setTempSavedFilter((prev) => ({
      ...(prev ?? {}),
      baseTime: v
    }));
    setIsChangedDate(true);
  };

  const handleChangeRule = (v: FlatNodeItem[]) => {
    const rules = _.map(v, (rule) => rule.value) as string[];
    setTempSavedFilter((prev) => ({
      ...(prev ?? {}),
      rules
    }));
    setIsChangedRule(true);
  };

  const handleChangeOrderAndHide = (options: {
    order: Order[];
    hide: Hide[];
  }) => {
    const { order, hide } = options;
    const nextTempSavedFilter = {
      ...tempSavedFilter,
      order,
      hide
    };

    setTempSavedFilter(nextTempSavedFilter);
    props.onChangeOrderAndHide({ order, hide });
    setIsChangedOrder(true);
  };

  return (
    <S.TopMenu>
      <S.LeftSideMenu>
        <FilterListDropdownButton
          editMode={isEditMode}
          onChangeName={handleChangeName}
          onChangeMode={(type) => setIsEditMode(type === "EDIT")}
          key={`${props.filters.length}_${props.filterIdx}_${props.filters[
            props.filterIdx
          ]?.filterName}`}
          filters={filterList}
          defaultIdx={props.filterIdx}
          onChange={(v) => props.onChange(Number(v))}
        />
        <SaveButton
          savedCount={props.filters.length}
          disabled={disableSave}
          onSave={handleSave}
        />
        <MoreButton disabled={!selectedFilterId} onChange={handleMore} />
        <S.Divider height={20} />
        <CalendarButton
          key={`${props.filters[props.filterIdx]?.baseTime?.start}-${props
            .filters[props.filterIdx]?.baseTime?.end}-${props.filters[
            props.filterIdx
          ]?.baseTime?.mode}`}
          dateTime={tempSavedFilter?.baseTime}
          onChange={handleChangeDate}
        />
        <RuleButton
          key={props.filterIdx}
          defaultRules={props.filters[props.filterIdx]?.rules ?? []}
          onChange={handleChangeRule}
        />
        <S.SearchButton
          disabled={disableSearch}
          style={{ width: "80px" }}
          onClick={handleSearch}
        >
          {translate("search")}
        </S.SearchButton>
      </S.LeftSideMenu>
      <S.RightSideMenu>
        <OrderingButton
          key={`${tempSavedFilter?.order.join("")}_${tempSavedFilter?.hide.join(
            ""
          )}`}
          disabled={tempSavedFilter?.order.length === 0}
          order={tempSavedFilter?.order ?? []}
          hide={tempSavedFilter?.hide ?? []}
          onSave={handleChangeOrderAndHide}
        />
      </S.RightSideMenu>
    </S.TopMenu>
  );
};
