"use client";

import { Radio } from "@mantine/core";

import IHVRadioProps from "./IHVRadioProps";
import styles from "./HVRadio.module.css";

const HVRadio = ({
  checked,
  onChange,
  label,
  disabled = false,
  ...rest
}: IHVRadioProps) => {
  return (
    <Radio
      disabled={disabled}
      variant="outline"
      checked={checked}
      onChange={onChange}
      label={label}
      classNames={styles}
      {...rest}
    />
  );
};

export default HVRadio;
