import { Skeleton } from "@components/common/skeleton";
import { useLang } from "@hooks/useLang";
import * as S from "../AnalyticsPage.styled";

export const AnalyticsPageSkeleton = () => {
  const { translate } = useLang();
  const backgroundColor = "var(--background-skeleton-0)";
  return (
    <S.Container>
      <S.Content>
        <S.Title>{translate("analytics")}</S.Title>
        <Skeleton isLoading>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              gap: 8,
              marginTop: 20
            }}
          >
            <div
              className="skeleton"
              style={{
                width: 200,
                height: 32,
                backgroundColor
              }}
            />
            <div
              className="skeleton"
              style={{
                width: 32,
                height: 32,
                backgroundColor
              }}
            />
            <div
              className="skeleton"
              style={{
                width: 32,
                height: 32,
                backgroundColor
              }}
            />
            <div
              className="skeleton"
              style={{
                width: 1,
                height: 20,
                backgroundColor,
                margin: "0 4px"
              }}
            />
            <div
              className="skeleton"
              style={{
                width: 89,
                height: 32,
                backgroundColor
              }}
            />
            <div
              className="skeleton"
              style={{
                width: 102,
                height: 32,
                backgroundColor
              }}
            />
            <div
              className="skeleton"
              style={{
                width: 80,
                height: 32,
                backgroundColor
              }}
            />
          </div>

          <div
            className="skeleton"
            style={{
              width: "100%",
              height: `calc(100vh - 210px)`,
              backgroundColor,
              marginTop: 16
            }}
          />
        </Skeleton>
      </S.Content>
    </S.Container>
  );
};
