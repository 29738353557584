import React, {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useId,
  useRef,
} from "react";

const useScrollBar = () => {
  const uniqId = useId();
  const scrollbarRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const onScrollContent = () => {
    const scrollbarEle = scrollbarRef.current;
    const contentEle = contentRef.current;
    if (scrollbarEle && contentEle) {
      const top = (contentEle.scrollTop * 100) / contentEle.scrollHeight;
      scrollbarEle.style.top = top >= 0 ? `calc(${top}% + 6px)` : "0";
    }
  };

  const onChangeHeight = (entries: ResizeObserverEntry[]) => {
    if (entries.length) {
      const { target } = entries[0];
      setTimeout(() => {
        const scrollbarEle = scrollbarRef.current;
        const ratio = target.clientHeight / target.scrollHeight;
        if (ratio < 1 && scrollbarEle) {
          scrollbarEle.style.display =
            ratio * target.clientHeight ? "block" : "none";
          scrollbarEle.style.height = `${ratio * target.clientHeight - 12}px`;
        }
        if (target.clientHeight === target.scrollHeight && scrollbarEle) {
          scrollbarEle.style.display = "none";
        }
      }, 100);
    }
  };

  const onMouseDown = useCallback((e: ReactMouseEvent) => {
    const scrollbarEle = scrollbarRef.current;
    const contentEle = contentRef.current;
    if (scrollbarEle && contentEle) {
      const startPos = {
        x: e.clientX,
        y: e.clientY,
        top: contentEle.scrollTop,
      };

      const onMouseMove = (e: MouseEvent) => {
        const dy = e.clientY - startPos.y;
        const scrollRatio = contentEle.clientHeight / contentEle.scrollHeight;
        contentEle.scrollTop = startPos.top + dy / scrollRatio;
      };

      const onMouseUp = () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
      };

      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }
  }, []);

  const onKeyDown = (e: React.KeyboardEvent) => {
    const contentEle = contentRef.current;
    if (contentEle) {
      const scrollAmount = 10;
      switch (e.key) {
        case "ArrowUp":
          contentEle.scrollTop -= scrollAmount;
          break;
        case "ArrowDown":
          contentEle.scrollTop += scrollAmount;
          break;
        default:
          return;
      }
      e.preventDefault();
      onScrollContent();
    }
  };

  useEffect(() => {
    const scrollbarElement = document.getElementById(`scrollbar-${uniqId}`);
    let resizeObserver: ResizeObserver;
    if (scrollbarElement) {
      resizeObserver = new ResizeObserver(onChangeHeight);
      resizeObserver.observe(scrollbarElement);
    }

    return () => {
      if (resizeObserver && scrollbarElement) {
        resizeObserver.unobserve(scrollbarElement);
      }
    };
  }, []);

  return {
    onScrollContent,
    onMouseDown,
    onKeyDown,
    scrollbarRef,
    contentRef,
    uniqId,
  };
};

export default useScrollBar;
