"use client";

import { forwardRef, useImperativeHandle, useState } from "react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowData,
  MRT_TableOptions,
  useMantineReactTable,
} from "mantine-react-table";
import "mantine-react-table/styles.css";
import classes from "./HVTable.module.css";
import { HVFlex, HVIcon } from "../..";
import IHVTable from "./IHVTable";
import _ from "lodash";
import HVPagination from "../HVPagination/HVPagination";
import { svgProps } from "../../utils/Utils";
import checkBoxStyle from "../HVCheckbox/HVCheckbox.module.css";
interface ColumnDef extends MRT_ColumnDef<MRT_RowData> {
  justifyContent?: "flex-start" | "flex-end" | "center";
}

const HVTable = forwardRef(
  (
    {
      tableContainerProps,
      data,
      columns,
      onRowClick,
      tableOptions,
      hideSelectionColumn,
      hideExpandCollapseColumn,
      enablePagination = false,
      paginationProps = {
        defaultItemsPerPage: 10,
      },
    }: IHVTable,
    ref,
  ) => {
    const [currentPage, setCurrentPage] = useState(1);

    const defaultTableOptions: MRT_TableOptions<MRT_RowData> = {
      columns,
      data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
      initialState: { density: "xs", ...tableOptions?.initialState },
      enableRowSelection: true,
      enableMultiRowSelection: true,
      enableStickyHeader: true,
      enableTopToolbar: false,
      enableTableFooter: false,
      enableStickyFooter: true,
      enableColumnActions: false,

      sortingFns: {
        "case-insensitive-string-sort": (rowA, rowB, columnId) => {
          const a = rowA.getValue(columnId);
          const b = rowB.getValue(columnId);

          // Handle null or undefined values
          if (a == null) return b == null ? 0 : -1;
          if (b == null) return 1;

          // If both are numbers, compare numerically
          if (typeof a === "number" && typeof b === "number") {
            return a - b;
          }

          // If both are strings, do case-insensitive comparison
          if (typeof a === "string" && typeof b === "string") {
            return a.toLowerCase().trim().localeCompare(b.toLowerCase().trim());
          }

          // Convert to string for consistent comparison if types differ
          return String(a)
            .toLowerCase()
            .trim()
            .localeCompare(String(b).toLowerCase().trim());
        },
      },
      defaultColumn: {
        sortingFn: "case-insensitive-string-sort",
      },
      icons: {
        IconArrowsSort: () => (
          <HVIcon
            name="sort-arrow-up-down"
            svgProps={svgProps(20, "", "var(--hv-icon-normal-secondary)")}
          />
        ),
        IconSortAscending: () => (
          <HVIcon
            name="sort-arrow-up"
            svgProps={svgProps(20, "", "var(--hv-icon-normal-secondary)")}
          />
        ),
        IconSortDescending: () => (
          <HVIcon
            name="sort-arrow-down"
            svgProps={svgProps(20, "", "var(--hv-icon-normal-secondary)")}
          />
        ),
      },
      displayColumnDefOptions: {
        "mrt-row-select": {
          mantineTableHeadCellProps: {
            display: hideSelectionColumn ? "none" : "grid",
            kind: "mrt-row-select",
          },
          mantineTableBodyCellProps: {
            display: hideSelectionColumn ? "none" : "flex",
            kind: "mrt-row-select",
          },
        },
        "mrt-row-expand": {
          mantineTableHeadCellProps: {
            display: hideExpandCollapseColumn ? "none" : "grid",
            kind: "mrt-row-expand",
          },
          mantineTableBodyCellProps: {
            display: hideExpandCollapseColumn ? "none" : "flex",
            kind: "mrt-row-expand",
          },
        },
      },
      //MainContainer Style and Props
      mantinePaperProps: (props) => {
        const paperProps =
          typeof tableOptions?.mantinePaperProps === "function"
            ? tableOptions?.mantinePaperProps?.(props)
            : tableOptions?.mantinePaperProps;
        return {
          ...paperProps,
          className: `${classes.mantinePaper}  ${paperProps?.className}`,
        };
      },
      //TableContainer Style and Props
      mantineTableContainerProps: (props) => {
        const containerProps =
          typeof tableOptions?.mantineTableContainerProps === "function"
            ? tableOptions?.mantineTableContainerProps?.(props)
            : tableOptions?.mantineTableContainerProps;
        return {
          ...containerProps,
          className: `${classes.tableContainer} ${containerProps?.className}`,
        };
      },
      //Header Row & Column Style and Props
      mantineTableHeadRowProps: (props) => {
        const headRowProps =
          typeof tableOptions?.mantineTableHeadRowProps === "function"
            ? tableOptions?.mantineTableHeadRowProps?.(props)
            : tableOptions?.mantineTableHeadRowProps;
        return {
          ...headRowProps,
          className: `${classes.headRow} ${headRowProps?.className}`,
        };
      },
      mantineTableHeadCellProps: (props) => {
        const headCellProps =
          typeof tableOptions?.mantineTableHeadCellProps === "function"
            ? tableOptions?.mantineTableHeadCellProps?.(props)
            : tableOptions?.mantineTableHeadCellProps;

        return {
          ...headCellProps,
          className: `${classes.headCell} ${headCellProps?.className}`,
          style: {
            justifyContent:
              props.column.columnDef.id === "mrt-row-select"
                ? "center"
                : (props?.column.columnDef as ColumnDef).justifyContent ||
                  "center",
            ...headCellProps?.style,
          },
        };
      },
      //Body Row & Column Style and Props
      mantineTableBodyProps: (props) => {
        const bodyProps =
          typeof tableOptions?.mantineTableBodyProps === "function"
            ? tableOptions?.mantineTableBodyProps?.(props)
            : tableOptions?.mantineTableBodyProps;
        return {
          ...bodyProps,
          style: { borderBottomWidth: 4, ...bodyProps?.style },
        };
      },
      mantineTableBodyRowProps: (props) => {
        const tableBodyRowProps =
          typeof tableOptions?.mantineTableBodyRowProps === "function"
            ? tableOptions?.mantineTableBodyRowProps?.(props)
            : tableOptions?.mantineTableBodyRowProps;
        const isClickable = !!onRowClick;
        const isSelected = props.table
          .getState()
          .rowSelection.hasOwnProperty(props.row.original.id);
        const isExpanded = props.table
          .getState()
          .rowSelection.hasOwnProperty(props.row.original.id);
        return {
          ...tableBodyRowProps,
          className: props.isDetailPanel
            ? `${classes.detailPanel} ${isSelected && classes.detailPanelSelected}`
            : `${classes.bodyRow} ${isExpanded && classes.bodyRowExpanded}) ${tableBodyRowProps?.className}`,
          onClick: (e) => {
            tableBodyRowProps?.onClick
              ? tableBodyRowProps?.onClick(e)
              : onRowClick?.(props.row, props.table);
          },
          style: [
            isClickable ? { cursor: "pointer" } : {},
            tableBodyRowProps?.style,
          ],
        };
      },
      mantineTableBodyCellProps: (props) => {
        const columnDef =
          typeof props?.column?.columnDef?.mantineTableBodyCellProps ===
          "function"
            ? props?.column?.columnDef?.mantineTableBodyCellProps?.(props)
            : props?.column?.columnDef?.mantineTableBodyCellProps;
        const tableBodyCellProps =
          typeof tableOptions?.mantineTableBodyCellProps === "function"
            ? tableOptions?.mantineTableBodyCellProps?.(props)
            : tableOptions?.mantineTableBodyCellProps;
        const justify = (props?.column.columnDef as ColumnDef).justifyContent;
        const textAlign =
          justify === "flex-start"
            ? "start"
            : justify === "flex-end"
              ? "end"
              : "center";
        return {
          ...tableBodyCellProps,
          className: `${classes.bodyCell} ${tableBodyCellProps?.className}`,
          display: columnDef?.display,
          style: {
            textAlign: textAlign,
            placeItems: "center",
            ...tableBodyCellProps?.style,
          },
        };
      },
      mantineSelectCheckboxProps: (props) => {
        const selectCheckboxProps =
          typeof tableOptions?.mantineSelectCheckboxProps === "function"
            ? tableOptions?.mantineSelectCheckboxProps?.(props)
            : tableOptions?.mantineSelectCheckboxProps;
        return {
          classNames: checkBoxStyle,
          size: "xs",
          ...selectCheckboxProps,
        };
      },
      mantineSelectAllCheckboxProps: (props) => {
        const selectAllCheckboxProps =
          typeof tableOptions?.mantineSelectAllCheckboxProps === "function"
            ? tableOptions?.mantineSelectAllCheckboxProps?.(props)
            : tableOptions?.mantineSelectAllCheckboxProps;
        return {
          classNames: checkBoxStyle,
          size: "xs",
          ...selectAllCheckboxProps,
        };
      },
      mantineExpandButtonProps: (props) => {
        const expandButtonProps =
          typeof tableOptions?.mantineExpandButtonProps === "function"
            ? tableOptions?.mantineExpandButtonProps?.(props)
            : tableOptions?.mantineExpandButtonProps;
        return {
          ...expandButtonProps,
        };
      },
      //Virtualization for smooth rendering
      enableRowVirtualization: true,
      enableColumnVirtualization: true,

      //Bottom Toolbar and Pagination
      enableBottomToolbar: false,
      enablePagination: false,
    };

    const omittedTableOptions = _.omit(tableOptions, [
      "initialState",
      "mantinePaperProps",
      "mantineTableContainerProps",
      "mantineTableHeadRowProps",
      "mantineTableHeadCellProps",
      "mantineTableBodyProps",
      "mantineTableBodyRowProps",
      "mantineTableBodyCellProps",
      "mantinePaginationProps",
    ]);

    const paginatedData = enablePagination
      ? data.slice(
          (currentPage - 1) * (paginationProps.defaultItemsPerPage ?? 10),
          currentPage * (paginationProps.defaultItemsPerPage ?? 10),
        )
      : data;

    const table = useMantineReactTable({
      ...defaultTableOptions,
      ...omittedTableOptions,
      data: paginatedData,
    });

    useImperativeHandle(ref, () => table, [table]);

    return (
      <HVFlex
        {...tableContainerProps}
        style={{ overflow: "auto", ...tableContainerProps?.style }}
      >
        <MantineReactTable table={table} />
        {enablePagination && (
          <HVPagination
            totalRows={data.length}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            {...paginationProps}
          />
        )}
      </HVFlex>
    );
  },
);

export default HVTable;
