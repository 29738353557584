import { ReactComponent as IconFloppyDisk } from "@resources/icons/svg/analytics/icon-floppy-disk.svg";
import useDetectOutsideClick from "@hooks/useDetectOutsideClick";
import ModalManager from "@utils/ModalManager";
import { useLang } from "@hooks/useLang";
import moment from "moment";
import { Input } from "@components/common/input";
import { useInputHook } from "@hooks/useInputHook";
import _ from "lodash";
import { FORMAT, MAX_LENGTH_ANALYTICS_NAME } from "@resources/Constants";
import { useState, useRef } from "react";
import * as S from "../TopMenu.styled";

type SAVE_TYPE = "SAVE" | "SAVE_AS";
const MAX_SAVE_FILTER = 10;
const DEBOUNCE_TIME_MS = 100;

interface SaveButtonProps {
  onSave: (
    status: SAVE_TYPE,
    options: {
      fileName?: string;
    }
  ) => void;
  disabled?: boolean;
  savedCount: number;
}

export const SaveButton = (props: SaveButtonProps) => {
  const { translate } = useLang();
  const { disabled = false } = props;
  const [isOpen, setIsOpen] = useState(false);
  const rootRef = useRef(null);
  useDetectOutsideClick(rootRef, () => setIsOpen(false));
  const { setValue, value, inputRef, getValueFromRef } = useInputHook<
    string | undefined
  >();

  const handleSave = _.debounce((type: SAVE_TYPE, fileName?: string) => {
    props.onSave(type, { fileName });
  }, DEBOUNCE_TIME_MS);

  const handleClickSave = (e: React.MouseEvent<HTMLUListElement>) => {
    e.stopPropagation();
    const type = (e.target as HTMLElement).dataset.id as SAVE_TYPE;
    setIsOpen(false);

    // max count
    if (type === "SAVE_AS") {
      if (MAX_SAVE_FILTER <= props.savedCount) {
        ModalManager.message(
          translate("max_filter_count").replace("{max}", `${MAX_SAVE_FILTER}`),
          {
            title: translate("system"),
            labelButton: translate("ok")
          }
        );
        return;
      }
    }

    if (type === "SAVE_AS" || props.savedCount === 0) {
      const defaultName = moment().format(FORMAT.DATE_TIME_24H_1);
      // 1) input name
      ModalManager.confirm(
        <div>
          <S.InputTitle style={{ marginBottom: 10 }}>
            {translate("name")}
          </S.InputTitle>
          <Input
            innerRef={inputRef}
            defaultValue={defaultName}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            maxLength={MAX_LENGTH_ANALYTICS_NAME}
          />
        </div>,
        {
          title: translate(type.toLocaleLowerCase()),
          labelConfirm: translate("save"),
          labelCancel: translate("cancel"),
          onSubmit: () => {
            const fileName = getValueFromRef() ?? defaultName;
            handleSave(type, fileName);
            _.defer(() => setValue(undefined));
          },
          setShowModal: (visible: boolean) => {
            if (!visible) {
              _.defer(() => setValue(undefined));
            }
          }
        }
      );
    } else {
      handleSave(type);
    }
  };

  return (
    <S.MenuButton
      isActive={isOpen}
      disabled={disabled}
      ref={rootRef}
      style={{ width: "32px", position: "relative" }}
      onClick={() => setIsOpen(true)}
      data-tooltip={translate("save")}
    >
      <IconFloppyDisk width={16} height={16} />
      {isOpen && (
        <S.DropDownList onClick={handleClickSave}>
          <li data-id={"SAVE" as const}>{translate("save")}</li>
          <li data-id={"SAVE_AS" as const}>{translate("save_as")}</li>
        </S.DropDownList>
      )}
    </S.MenuButton>
  );
};
