"use client";

import { Text } from "@mantine/core";
import style from "./HVText.module.css";
import IHVTextProps from "./IHVTextProps";
const HVText = ({ type, weight, children, ...rest }: IHVTextProps) => {
  return (
    <Text
      style={{
        fontWeight: `var(--hv-font-weight-${weight?.toLowerCase() || "regular"})`,
      }}
      {...rest}
      className={`${style.root} ${style[`${type?.toLowerCase()}`] || style["body-md"]} ${rest?.className}`}
    >
      {children}
    </Text>
  );
};

export default HVText;
