import { ReactNode, useEffect, useId, useRef } from "react";
import { HVButton, HVIcon } from "@repo/HVComponents";
import { ModalStyled } from "./Modal.styled";
import { ICON } from "../../../constants/icons";

export interface ModalProps {
  isConfirm?: boolean;
  content?: string;
  title?: string;
  labelButton?: string;
  showModal?: boolean;
  setShowModal?: (visible: boolean) => void;
  onSubmit?: () => void;
  onCancel?: () => void;
  labelConfirm?: string;
  labelCancel?: string;
  className?: string;
  children?: ReactNode;
  moreInfo?: ReactNode | string | number;
  actionButtons?: ReactNode;
  hiddenFooter?: boolean;
  isDisabledConfirm?: boolean;
  isLoadingConfirm?: boolean;
  confirmationOnCancel?: boolean;
}

export const Modal = ({
  content = "",
  title = "",
  isConfirm = false,
  labelButton = "",
  showModal = false,
  setShowModal,
  onSubmit,
  onCancel,
  labelConfirm = "",
  labelCancel = "",
  className,
  children,
  moreInfo,
  actionButtons,
  hiddenFooter = false,
  isDisabledConfirm = false,
  isLoadingConfirm = false,
  confirmationOnCancel = false
}: ModalProps) => {
  const uniq = useId();
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showModal) {
      modalRef.current?.focus();
    }
  }, [showModal]);

  useEffect(() => {
    if (!showModal) return undefined;

    const handleKeyDown = (e: KeyboardEvent) => {
      const focusableElements = modalRef.current?.querySelectorAll(
        'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
      );
      if (!focusableElements) return;
      const firstElement = focusableElements[0] as HTMLElement;
      const lastElement = focusableElements[
        focusableElements.length - 1
      ] as HTMLElement;
      if (e.key === "Tab") {
        if (e.shiftKey && document.activeElement === firstElement) {
          // Shift + Tab
          e.preventDefault();
          lastElement.focus();
        } else if (document.activeElement === lastElement) {
          // Tab
          e.preventDefault();
          firstElement.focus();
        }
      } else if (e.key === "Escape") {
        // Close modal on Escape key press
        onCancel && onCancel();
        !confirmationOnCancel && setShowModal?.(false);
      }
    };

    // Attach keydown listener
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup keydown listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showModal, onCancel]);

  if (!showModal) return <div />;

  return (
    <ModalStyled
      ref={modalRef}
      id={`modal_${uniq}`}
      className={`modal ${className} ${hiddenFooter && "modal-only-body"}`}
      role="dialog"
      aria-modal="true"
      tabIndex={-1}
      aria-label="Model"
    >
      <div className="modal-container" aria-label="modal container">
        <div className="modal-header" aria-label="modal header">
          <h4 className="modal-title">{title}</h4>
          <div className="modal-close">
            <HVButton
              aria-label="Close modal"
              onClick={() => {
                onCancel?.();
                !confirmationOnCancel && setShowModal?.(false);
              }}
              w={32}
              h={32}
              p={0}
              variant="Ghost"
            >
              <HVIcon name="close" svgProps={{ ...ICON.SIZE_24 }} />
            </HVButton>
          </div>
        </div>
        <div className="modal-body" aria-label="modal body">
          {content || children}
        </div>
        {!hiddenFooter && (
          <div className="modal-footer" aria-label="modal footer">
            <div>{moreInfo}</div>
            <div style={{ display: "flex" }}>
              {actionButtons}
              {!actionButtons &&
                isConfirm && [
                  <HVButton
                    aria-label={labelConfirm || "modal confirm"}
                    onClick={() => onSubmit?.()}
                    variant="Primary"
                    disabled={isDisabledConfirm}
                    loading={isLoadingConfirm}
                    key={1}
                  >
                    {labelConfirm}
                  </HVButton>,
                  <HVButton
                    aria-label={labelCancel || "modal cancel"}
                    onClick={() => {
                      onCancel?.();
                      setShowModal?.(false);
                    }}
                    variant="Secondary"
                    key={2}
                  >
                    {labelCancel}
                  </HVButton>
                ]}

              {!actionButtons && !isConfirm && (
                <HVButton
                  aria-label={labelButton || "modal submit"}
                  onClick={() => onSubmit?.()}
                  variant="Primary"
                >
                  {labelButton}
                </HVButton>
              )}
            </div>
          </div>
        )}
      </div>
    </ModalStyled>
  );
};
