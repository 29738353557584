import { useEffect, useMemo, useState } from "react";
import useOrganization from "@hooks/useOrganization";
import { useGetAnalyticsChartQuery } from "@queries/tanstack/analytics/AnalyticsQuery";
import {
  AnalyticsDataResponse,
  AnalyticsFilterRules
} from "@queries/Analytics";
import { useChartFilterInterval } from "@hooks/useChartFilterInterval";
import { TypeTimeInterval } from "@custom-types/Types";
import { VIEW_TYPE_MAPPING } from "@resources/Constants";
import { ChartSkeleton } from "../../skeleton/ChartSkeleton";
import { ChartCardPresenter } from "./Presenter";
import { ChartCardBaseProps, ChartRules } from "./Interfaces";
import { defaultDataVehicleType } from "./PreviewData";

const ChartCardContainer = (props: ChartCardBaseProps) => {
  const { orgId } = useOrganization();
  const {
    type,
    baseTime: { ...baseTime },
    options,
    onChangeInsightChat
  } = props;
  const [dataSeries, setDataSeries] = useState<string[]>([]);
  const {
    getTypeTime: getTypeInterval,
    mapTypeToTimeInterval,
    time: timeInterval,
    type: typeInterval,
    setType
  } = useChartFilterInterval({ baseTime });
  const [selected, setSelected] = useState<string[]>(defaultDataVehicleType);
  const [viewType, setViewType] = useState<string>(options?.viewList[0] ?? "");

  const {
    data: analyticsDatas,
    isLoading,
    getChartData
  } = useGetAnalyticsChartQuery(type, viewType, selected);

  useEffect(() => {
    if (analyticsDatas && analyticsDatas.length > 0) {
      const series = analyticsDatas
        ? analyticsDatas
            .map((data) => data.locationName)
            .filter((name): name is string => name !== undefined)
        : [""];
      setDataSeries(series);
    }
  }, [analyticsDatas]);

  const convertRule = (rules: ChartRules) => {
    let formattedRules: AnalyticsFilterRules = {};
    Object.keys(rules).forEach((key) => {
      formattedRules = {
        ...formattedRules,
        [key]: rules[key].map((rule) => ({
          deviceId: rule.deviceId,
          ruleName: rule.ruleName,
          channel: rule.channel
        }))
      };
    });
    return formattedRules;
  };

  const updatedAnalytics = (
    types: string[] = [],
    selectedInterval: TypeTimeInterval | null = null,
    changedViewType: string | null = null
  ) => {
    const timeInterval = selectedInterval
      ? mapTypeToTimeInterval(selectedInterval)
      : (getTypeInterval({ baseTime }) as string);
    const viewTypeConverted = VIEW_TYPE_MAPPING[changedViewType || viewType];
    timeInterval &&
      getChartData({
        endDateTime: baseTime.end,
        startDateTime: baseTime.start,
        orgId,
        timeInterval,
        vehicleType: types,
        rules: convertRule(props.rules),
        cumulativeType: viewTypeConverted ?? ""
      });
  };

  useMemo(() => {
    setViewType(options?.viewList[0] ?? "");
    setSelected(defaultDataVehicleType);
    updatedAnalytics();
  }, [baseTime.start, baseTime.end, props.rules]);

  if (isLoading) {
    return <ChartSkeleton />;
  }

  return (
    <div>
      <ChartCardPresenter
        {...props}
        dataSeries={dataSeries}
        analyticsData={analyticsDatas as AnalyticsDataResponse[]}
        updatedAnalytics={updatedAnalytics}
        selected={selected}
        setSelected={setSelected}
        setViewType={setViewType}
        viewType={viewType}
        timeInterval={timeInterval}
        typeInterval={typeInterval}
        setType={setType}
        setInsightChat={onChangeInsightChat}
      />
    </div>
  );
};

export default ChartCardContainer;
