import { isArray } from "lodash";
import { useId, useState } from "react";
import styles from "./ContextMenu.module.css";
import { ContextMenuProps, MenuItem } from "../../../HVDatePicker.props";
import { IconArrowRight, IconCheckLine } from "../../../../../assets/icons";

export const ContextMenu = ({
  children,
  value,
  data = [],
  trigger = "hover",
  tabIndex = 0,
}: ContextMenuProps) => {
  const uniq = useId();
  const [show, setShow] = useState<boolean>(false);

  return (
    <div
      id={`context-menu_${uniq}`}
      aria-label="context-menu"
      className={styles["context-menu"]}
      onMouseMove={() => trigger === "hover" && setShow(true)}
      onMouseLeave={() => trigger === "hover" && setShow(false)}
      onClick={() => trigger === "press" && setShow((prev) => !prev)}
      role="button"
      tabIndex={tabIndex}
      onKeyDown={(e) => {
        if (e.code === "Enter" || e.code === "Space") {
          setShow((prev) => !prev);
        }
      }}
    >
      {children}
      <ul
        style={{ visibility: show ? "visible" : "hidden" }}
        data-visibility={show ? "visible" : "hidden"}
      >
        {isArray(data)
          ? data.map((item: MenuItem, index) => {
              return (
                <li
                  className={item.icon ? styles["has-icon"] : ""}
                  key={`context-menu-${uniq}-${item.label}`}
                  aria-label={`context-menu-${index}`}
                >
                  <button
                    {...item.custom}
                    onClick={(e) => {
                      item.custom?.onClick?.(e);
                      item.custom?.onSelectItem?.(item);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.currentTarget.click();
                      }
                    }}
                  >
                    {value && (
                      <div style={{ width: "16px", height: "16px" }}>
                        {value === item.label ? <IconCheckLine /> : ""}
                      </div>
                    )}
                    {item.icon || null}
                    {item.label}
                    {item.children && (
                      <>
                        <div className={styles["icon"]}>
                          <IconArrowRight />
                        </div>
                        {item.children && (
                          <ul>
                            {item.children.map((sub: MenuItem) => (
                              <li
                                key={`sub-context-menu-${uniq}-${sub.label}`}
                                aria-label={`sub-context-menu-${index}`}
                              >
                                <button
                                  {...sub.custom}
                                  onClick={(e) => {
                                    sub.custom?.onClick?.(e);
                                    sub.custom?.onSelectItem?.(sub);
                                  }}
                                >
                                  {sub.label}
                                </button>
                              </li>
                            ))}
                          </ul>
                        )}
                      </>
                    )}
                  </button>
                </li>
              );
            })
          : data}
      </ul>
    </div>
  );
};
