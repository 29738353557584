import { useCallback, useEffect, useId, useRef } from "react";
import Header from "./Header/Header";
import DatePicker, { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { CalendarProps } from "../HVDatePicker.props";
import { classNames, endOf, isSame, startOf } from "../resources/utils/utils";
import styles from "./Calendar.module.css";
import headerStyles from "./Header/Header.module.css";
import "../DatePicker.css";

export const Calendar = ({ today, ...props }: CalendarProps) => {
  const uniq = useId();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const renderWeekDay = useCallback(
    (nameOfDay: string) => String(nameOfDay).substring(0, 3),
    [],
  );

  const renderDayContents = useCallback(
    (day: number, date: Date) => {
      const startOfMonth = startOf(date, "MONTH");
      const endOfMonth = endOf(date, "MONTH");

      return (
        <div
          className={classNames({
            [styles["date-picker-custom-today"]]: isSame(date, today, "HOUR"),
            [styles["date-picker-custom-date"]]: true,
            [styles["date-picker-start-of-month"]]: isSame(
              date,
              startOfMonth,
              "DAY",
            ),
            [styles["date-picker-end-of-month"]]: isSame(
              date,
              endOfMonth,
              "DAY",
            ),
          })}
        >
          <span data-day={day}>{date?.getDate()}</span>
          <span className={styles["shadow-date"]}>{date?.getDate()}</span>
        </div>
      );
    },
    [today],
  );

  useEffect(() => {
    if (!wrapperRef.current) return undefined;

    const monthContainer = wrapperRef.current.querySelector(
      `.${["react-datepicker__month"]}`,
    ) as HTMLElement;

    const prevButton = wrapperRef.current.querySelector(
      `.${headerStyles["icon-arrow"]}.up`,
    ) as HTMLSpanElement;

    const nextButton = wrapperRef.current.querySelector(
      `.${headerStyles["icon-arrow"]}.down`,
    ) as HTMLSpanElement;

    const handleMouseWheelEvent = (e: WheelEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.deltaY > 0) {
        nextButton?.click();
      } else {
        prevButton?.click();
      }
    };

    monthContainer?.addEventListener("wheel", handleMouseWheelEvent);

    return () =>
      monthContainer?.removeEventListener("wheel", handleMouseWheelEvent);
  }, [wrapperRef.current]);

  return (
    <div
      className={styles["calendar"]}
      ref={wrapperRef}
      id={`date-picker_${uniq}`}
    >
      <DatePicker
        formatWeekDay={renderWeekDay}
        renderDayContents={renderDayContents}
        renderCustomHeader={(props: ReactDatePickerCustomHeaderProps) => (
          <Header {...props} />
        )}
        {...props}
      />
    </div>
  );
};
