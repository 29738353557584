"use client";

import { useState } from "react";
import IHVDropdownProps from "./IHVDropdownProps";
import { Group, Select, SelectProps } from "@mantine/core";
import style from "./HVDropdown.module.css";
import { IconCheck } from "@tabler/icons-react";
import HVText from "../HVText/HVText";
import scrollBarStyle from "./HVDropdpwnScroll.module.css";
import HVIcon from "../HVIcon/HVIcon";

const renderSelectOption: SelectProps["renderOption"] = ({
  option,
  checked,
}) => (
  <Group className={`${style.optionContainer} ${checked && style.selected}`}>
    {option.label}
  </Group>
);

const renderSelectOptionWithArrow: SelectProps["renderOption"] = ({
  option,
  checked,
}) => (
  <Group className={`${style.optionContainer}`}>
    {checked && <IconCheck />}
    <HVText type="Body-sm" className={`${!checked && style.withIcon}`}>
      {option.label}
    </HVText>
  </Group>
);

const HVDropdown = ({
  type,
  selectedItem = null,
  options,
  selectWithArrow,
  getSelectedValue,
  ...rest
}: IHVDropdownProps) => {
  const [rightSectionIcon, setRightSideIcon] = useState(
    <HVIcon
      name="arrow-chevron-down"
      svgProps={{ height: 20, stroke: "var(--hv-icon-normal-primary)" }}
    />,
  );
  return (
    <Select
      data={options}
      value={selectedItem}
      withCheckIcon={false}
      classNames={{
        root: style.rootWrapper,
        input: style.dropdownContainer,
        section: style.sectionContainer,
        dropdown: style.optionsContainer,
        option: style.option,
      }}
      styles={{
        input: {
          height: type === "medium" ? "32px" : "40px",
          backgroundColor:
            selectedItem !== null
              ? "var(--hv-input-foreground-Default)"
              : undefined,
          color:
            selectedItem !== null ? "var(--hv-text-normal-primary)" : undefined,
          border:
            selectedItem !== null
              ? "1px solid var(--hv-input-outline-active)"
              : undefined,
        },
      }}
      onDropdownOpen={() =>
        setRightSideIcon(
          <HVIcon
            name="arrow-chevron-up"
            svgProps={{ height: 20, stroke: "var(--hv-icon-normal-primary)" }}
          />,
        )
      }
      onDropdownClose={() =>
        setRightSideIcon(
          <HVIcon
            name="arrow-chevron-down"
            svgProps={{ height: 20, stroke: "var(--hv-icon-normal-primary)" }}
          />,
        )
      }
      comboboxProps={{ offset: 4 }}
      renderOption={
        selectWithArrow ? renderSelectOptionWithArrow : renderSelectOption
      }
      scrollAreaProps={{ classNames: scrollBarStyle }}
      rightSection={rightSectionIcon}
      onChange={(option) => getSelectedValue && getSelectedValue(option)}
      {...rest}
    />
  );
};

export default HVDropdown;
