import { SVGAttributes } from "react";

export const svgProps = (size: number, fillColor?: string, strokeColor?:string): SVGAttributes<SVGElement> => {
  return {
    height: size,
    width: size,
    fill: fillColor ? fillColor : "var(--hv-alert-text-default)",
    stroke: strokeColor ? strokeColor  :"",
  };
};
