import { nanoid } from "@reduxjs/toolkit";
import { ReactNode, useId } from "react";
import { CHECKBOX_STATUS } from "../checkbox";
import {
  DataTreeViewItem,
  DataTreeViewItemValue,
  FlatNodeItem,
  TreeViewItemProps
} from "./Interfaces";
import NodeModel from "./NodeModel";
import TreeViewItem from "./TreeViewItem";

interface NestedTreeViewItemProps {
  items: DataTreeViewItem[];
  showIcon?: boolean;
  disabled: boolean;
  selected: DataTreeViewItemValue | null;
  showCheckbox?: boolean;
  id?: string;
  model: NodeModel;
  level?: number;
  lazyLoad?: boolean;
  loadDataItemFn?: (
    value: DataTreeViewItemValue
  ) => Promise<DataTreeViewItem[]>;
  onChecked: (data: { value: DataTreeViewItemValue; checked: boolean }) => void;
  onExpanded: (data: {
    value: DataTreeViewItemValue;
    expanded: boolean;
  }) => void;
  onClicked: (data: { value: DataTreeViewItemValue }) => void;
  onRenderItem?: (item: FlatNodeItem) => ReactNode;
  onRenderItemHover?: (item: TreeViewItemProps) => ReactNode;
  onRenderItemSelected?: (label: ReactNode) => ReactNode;
  hoverItem?: string;
  onHover?: (id?: string) => void;
  useHoverTooltip?: boolean;
}

const NestedTreeViewItem = (props: NestedTreeViewItemProps) => {
  const {
    items,
    model,
    disabled,
    selected,
    id,
    level = 0,
    showIcon,
    showCheckbox,
    lazyLoad,
    loadDataItemFn,
    onChecked,
    onExpanded,
    onClicked,
    onRenderItem,
    onRenderItemHover,
    onRenderItemSelected,
    hoverItem,
    onHover
  } = props;

  const isEveryChildChecked = (node: DataTreeViewItem): boolean => {
    if (!node.children) {
      return false;
    }

    return node.children.every(
      (child: DataTreeViewItem) =>
        model.getNode(child.value).checked &&
        (child.children ? isEveryChildChecked(child) : true)
    );
  };

  const isSomeChildChecked = (node: DataTreeViewItem): boolean => {
    if (!node.children) {
      return false;
    }

    return node.children.some(
      (child: DataTreeViewItem) =>
        model.getNode(child.value).checked || isSomeChildChecked(child)
    );
  };

  const determineShallowState = (node: DataTreeViewItem): CHECKBOX_STATUS => {
    const flatNode = model.getNode(node.value);

    if (flatNode.isLeaf || node.children?.length === 0) {
      return flatNode.checked ? CHECKBOX_STATUS.CHECK : CHECKBOX_STATUS.UNCHECK;
    }

    if (isEveryChildChecked(node)) {
      return CHECKBOX_STATUS.CHECK;
    }

    if (isSomeChildChecked(node)) {
      return CHECKBOX_STATUS.HALF_CHECK;
    }

    return CHECKBOX_STATUS.UNCHECK;
  };
  const uniq = useId();

  return (
    <ol key={uniq}>
      {items.map((item) => {
        const flatNode = model.getNode(item.value);
        flatNode.checkState = determineShallowState(item);
        return (
          <TreeViewItem
            key={item.value}
            checked={flatNode.checkState}
            disabled={flatNode.disabled || disabled}
            expanded={flatNode.expanded}
            icon={item.icon}
            isLeaf={item.hideArrow || flatNode.isLeaf || !flatNode.children}
            status={flatNode.status}
            isRoot={level === 0}
            isParent={flatNode.isParent}
            label={item.label}
            model={model}
            lazyLoad={lazyLoad}
            loadDataItemFn={loadDataItemFn}
            id={id || `tree-view-item-${nanoid()}`}
            showCheckbox={flatNode.showCheckbox || showCheckbox}
            showIcon={showIcon}
            data={item}
            selected={selected}
            onChecked={onChecked}
            onClicked={onClicked}
            onExpanded={onExpanded}
            onRenderItem={onRenderItem}
            onRenderItemHover={onRenderItemHover}
            onRenderItemSelected={onRenderItemSelected}
            useHoverTooltip={props.useHoverTooltip}
            hoverItem={hoverItem}
            onHover={onHover}
          >
            {item.children?.length ? (
              <NestedTreeViewItem
                level={level + 1}
                items={item.children}
                disabled={disabled}
                selected={selected}
                showIcon={showIcon}
                showCheckbox={showCheckbox}
                id={id}
                model={model}
                lazyLoad={lazyLoad}
                loadDataItemFn={loadDataItemFn}
                onChecked={onChecked}
                onClicked={onClicked}
                onExpanded={onExpanded}
                onRenderItem={onRenderItem}
                onRenderItemHover={onRenderItemHover}
                onRenderItemSelected={onRenderItemSelected}
                hoverItem={hoverItem}
                onHover={onHover}
                useHoverTooltip={props.useHoverTooltip}
              />
            ) : null}
          </TreeViewItem>
        );
      })}
    </ol>
  );
};

export default NestedTreeViewItem;
