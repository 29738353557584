import useOrganization from "@hooks/useOrganization";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AnalyticsPromptDto, ChatMessageDto } from "@open-api";
import { aiController } from "@queries/AiController";
import { keys } from "./keys";

export const useGetAiInsight = () => {
  const { orgId } = useOrganization();
  const queryClient = useQueryClient();

  return useMutation(
    (req: { start: string; end: string; body: AnalyticsPromptDto }) => {
      return aiController.post({
        path: {
          orgId
        },
        query: {
          startDateTime: req.start,
          endDateTime: req.end
        },
        body: req.body
      });
    },
    {
      onSuccess: (resp: ChatMessageDto) => {
        queryClient.setQueryData<ChatMessageDto>(
          keys.getFilter(orgId),
          (prev) => {
            if (!prev) {
              return prev;
            }
            return resp;
          }
        );
      }
    }
  );
};
