import {
  aiControllerSendPrompt,
  AiControllerSendPromptData,
  ChatMessageDto
} from "@open-api";

export const aiController = {
  post: async (params: AiControllerSendPromptData) => {
    const resp = await aiControllerSendPrompt(params);
    return resp.data as ChatMessageDto;
  }
};
