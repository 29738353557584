import dashboardReducer from "@redux/Dashboard";
import eventReducer from "@redux/Event";
import systemReducer from "@redux/System";
import widgetReducer from "@redux/Widget";
import notificationReducer from "@redux/Notification";
import reportReducer from "@redux/Report";
import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
// import logger from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import organizationReducer from "@redux/Organization";

const persistConfig = {
  key: "root",
  storage: storageSession,
  blacklist: ["widget", "system", "event", "notification", "organization"]
};

const systemConfig = {
  key: "system",
  storage: storageSession,
  blacklist: ["dateTime", "dateTimeZone"]
};

const reducers = combineReducers({
  system: persistReducer(systemConfig, systemReducer),
  event: eventReducer,
  dashboard: dashboardReducer,
  widget: widgetReducer,
  notification: notificationReducer,
  report: reportReducer,
  organization: organizationReducer
});

const rootReducer = (
  state: ReturnType<typeof reducers> | undefined,
  action: AnyAction
) => {
  if (action.type === "system/reset") {
    persistConfig.storage.removeItem("persist:root");
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
  // .concat(logger)
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppDispatch: () => AppDispatch = useDispatch;
