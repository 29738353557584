"use client";

import {
  createTheme,
  CSSVariablesResolver,
  DefaultMantineColor,
  MantineTheme,
  MantineThemeOther,
} from "@mantine/core";

const mantineThemeColors: Omit<MantineTheme["colors"], DefaultMantineColor> = {
  //Background Colors
  BGDark: [
    "#000000", // BG 0
    "#080809", // BG 1
    "#101011", // BG 2
    "#181819", // BG 3
    "#202021", // BG 4
    "#282829", // BG 5
    "#303031", // BG 6
    "#383839", // BG 7
    "#404041", // BG 8
    "#484849", // BG 9
    "#505051", // BG 10
    "#585859", // BG 11
    "#606062", // BG 12
  ],
  BGLight: [
    "#FFFFFF", // BG 0
    "#F9FAFB", // BG 1
    "#F6F7F8", // BG 2
    "#EEEFF1", // BG 3
    "#E5E7EB", // BG 4
    "#DDDFE4", // BG 5
    "#D4D7DD", // BG 6
    "#CCCFD7", // BG 7
    "#C3C6D0", // BG 8
    "#BABEC9", // BG 9
    "#B2B6C2", // BG 10
    "#A9AEBC", // BG 11
    "#A1A6B5", // BG 12
  ],
  //Contrast
  CDark: [
    "#FFFFFF", // C 0
    "#FFFFFFC4", // C 1 (77%)
    "#FFFFFF9C", // C 2 (61%)
    "#FFFFFF7D", // C 3 (49%)
    "#FFFFFF61", // C 4 (38%)
    "#FFFFFF49", // C 5 (29%)
    "#FFFFFF36", // C 6 (21%)
    "#FFFFFF24", // C 7 (14%)
    "#FFFFFF14", // C 8 (8%)
    "#FFFFFF0A", // C 9 (4%)
  ],
  CLight: [
    "#000000", // C 0
    "#000000C4", // C 1 (77%)
    "#0000009C", // C 2 (61%)
    "#0000007D", // C 3 (49%)
    "#00000061", // C 4 (38%)
    "#00000049", // C 5 (29%)
    "#00000036", // C 6 (21%)
    "#00000024", // C 7 (14%)
    "#00000014", // C 8 (8%)
    "#0000000A", // C 9 (4%)
  ],
  //Brand
  BDark: [
    "#F37321", // B 0
    "#F37321CC", // B 1 (77%)
    "#F373219B", // B 2 (61%)
    "#F373217D", // B 3 (49%)
    "#F3732161", // B 4 (38%)
    "#F3732149", // B 5 (29%)
    "#F3732136", // B 6 (21%)
    "#F3732124", // B 7 (14%)
    "#F3732114", // B 8 (8%)
    "#F373210A", // B 9 (4%)
  ],
  BLight: [
    "#F37321", // B 0
    "#F37321CC", // B 1 (77%)
    "#F373219B", // B 2 (61%)
    "#F373217D", // B 3 (49%)
    "#F3732161", // B 4 (38%)
    "#F3732149", // B 5 (29%)
    "#F3732136", // B 6 (21%)
    "#F3732124", // B 7 (14%)
    "#F3732114", // B 8 (8%)
    "#F373210A", // B 9 (4%)
  ],
  orange:[
    "#F37321", // B 0
    "#F37321CC", // B 1 (77%)
    "#F373219B", // B 2 (61%)
    "#F373217D", // B 3 (49%)
    "#F3732161", // B 4 (38%)
    "#F3732149", // B 5 (29%)
    "#F3732136", // B 6 (21%)
    "#F3732124", // B 7 (14%)
    "#F3732114", // B 8 (8%)
    "#F373210A", // B 9 (4%)
  ]
};

const mantineThemeOthers: MantineThemeOther = {
  fontWeight: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  DataDark: {
    blue: "#4891FF",
    red: "#FA4D4D",
    gray: "#56595B",
    green: "#7DC519",
    yellow: "#FFB400",
    sapphire: "#5071F2",
    orange: "#FF6D10",
    emerald: "#00B960",
    coral: "#FD6E55",
    indigo: "#5A44EB",
    purple: "#8333FC",
    violet: "#B93CF0",
    pink: "#FF5082",
  },
  DataLight: {
    blue: "#5596E2",
    red: "#F65A5A",
    gray: "#747E87",
    green: "#8EC63F",
    yellow: "#FFA400",
    sapphire: "#556ED7",
    orange: "#F37321",
    emerald: "#56AB50",
    coral: "#FF816B",
    indigo: "#647AED",
    purple: "#9A57E5",
    violet: "#B95AE5",
    pink: "#FF598E",
  },
};

export const theme = createTheme({
  fontFamily: "Noto_Sans, sans-serif",
  fontFamilyMonospace: "Noto_Sans, sans-serif",
  //--mantine-font-size
  fontSizes: {
    xsBody: "12px",
    smBody: "14px",
    mdBody: "16px",
    lgBody: "18px",
    "2xsHeadline": "20px",
    xsHeadline: "24px",
    smHeadline: "28px",
    mdHeadline: "32px",
    lgHeadline: "40px",
    xlHeadline: "60px",
  },
  //--mantine-line-height
  lineHeights: {
    xsBody: "18px",
    smBody: "20px",
    mdBody: "24px",
    lgBody: "26px",
    "2xsHeadline": "28px",
    xsHeadline: "32px",
    smHeadline: "36px",
    mdHeadline: "40px",
    lgHeadline: "48px",
    xlHeadline: "68px",
  },
  //--mantine-radius
  radius: {
    none: "0px",
    xs: "4px",
    sm: "8px",
    md: "12px",
    lg: "16px",
    xl: "20px",
  },
  defaultRadius: "none",
  //--mantine-spacing
  spacing: {
    none: "0px",
    1: "4px",
    2: "8px",
    3: "12px",
    4: "16px",
    5: "20px",
    6: "24px",
    7: "28px",
    8: "32px",
    9: "40px",
    10: "48px",
    full: "999px",
  },
  shadows: {
    xs: "0 2px 4px var(--shadow-1)",
    sm: "0px 4px 8px var(--shadow-2)",
    md: "0px 6px 12px var(--shadow-3)",
    lg: "0px 8px 16px var(--shadow-4)",
    xl: "0px 20px 40px var(--shadow-5)",
  },
  primaryColor: "orange",
  primaryShade: 0,
  colors: { ...mantineThemeColors },
  other: mantineThemeOthers,
});

export const cssResolver: CSSVariablesResolver = (theme) => ({
  variables: {
    //HV 2.2
    // Font Weights
    "--hv-font-weight-regular": theme.other.fontWeight.regular,
    "--hv-font-weight-medium": theme.other.fontWeight.medium,
    "--hv-font-weight-semibold": theme.other.fontWeight.semibold,
    "--hv-font-weight-bold": theme.other.fontWeight.bold,

    // Radius
    "--hv-radius-none": theme.radius.none,
    "--hv-radius-xs": theme.radius.xs,
    "--hv-radius-sm": theme.radius.sm,
    "--hv-radius-md": theme.radius.md,
    "--hv-radius-lg": theme.radius.lg,
    "--hv-radius-xl": theme.radius.xl,

    // Spacing
    "--hv-spacing-none": "0px",
    "--hv-spacing-1": "4px",
    "--hv-spacing-2": "8px",
    "--hv-spacing-3": "12px",
    "--hv-spacing-4": "16px",
    "--hv-spacing-5": "20px",
    "--hv-spacing-6": "24px",
    "--hv-spacing-7": "28px",
    "--hv-spacing-8": "32px",
    "--hv-spacing-9": "40px",
    "--hv-spacing-10": "48px",
    "--hv-spacing-full": "999px",

    // Font Sizes
    "--hv-fontsize-body-xs": theme.fontSizes.xsBody,
    "--hv-fontsize-body-sm": theme.fontSizes.smBody,
    "--hv-fontsize-body-md": theme.fontSizes.mdBody,
    "--hv-fontsize-body-lg": theme.fontSizes.lgBody,
    "--hv-fontsize-headline-2xs": theme.fontSizes["2xsHeadline"],
    "--hv-fontsize-headline-xs": theme.fontSizes.xsHeadline,
    "--hv-fontsize-headline-sm": theme.fontSizes.smHeadline,
    "--hv-fontsize-headline-md": theme.fontSizes.mdHeadline,
    "--hv-fontsize-headline-lg": theme.fontSizes.lgHeadline,
    "--hv-fontsize-headline-xl": theme.fontSizes.xlHeadline,

    // Line Height
    "--hv-lineheight-body-xs": theme.lineHeights.xsBody,
    "--hv-lineheight-body-sm": theme.lineHeights.smBody,
    "--hv-lineheight-body-md": theme.lineHeights.mdBody,
    "--hv-lineheight-body-lg": theme.lineHeights.lgBody,
    "--hv-lineheight-headline-2xs": theme.lineHeights["2xsHeadline"],
    "--hv-lineheight-headline-xs": theme.lineHeights.xsHeadline,
    "--hv-lineheight-headline-sm": theme.lineHeights.smHeadline,
    "--hv-lineheight-headline-md": theme.lineHeights.mdHeadline,
    "--hv-lineheight-headline-lg": theme.lineHeights.lgHeadline,
    "--hv-lineheight-headline-xl": theme.lineHeights.xlHeadline,
  },
  light: {
    //Whites
    "--hv-white": theme.colors.CDark[0],//#FFFFFF
    //Black
    "--hv-black": theme.colors.CLight[0],//#000000
    // Surface
    "--hv-surface": theme.colors.BGLight[3], // Color/Light/Background/BG 3: #EEEFF1
    "--hv-surface-widget": theme.colors.BGLight[0], // Color/Light/Background/BG 0: #FFFFFF
    "--hv-surface-popup": theme.colors.BGLight[0], // Color/Light/Background/BG 0: #FFFFFF
    "--hv-surface-popup-panel": theme.colors.BGLight[2], // Color/Light/Background/BG 2: #F6F7F8

    // Interaction
    "--hv-interaction-hover": theme.colors.CLight[9], // Color/Light/Contrast/C 9: #0000000A
    "--hv-interaction-press": theme.colors.CLight[8], // Color/Light/Contrast/C 8: #00000014
    "--hv-interaction-select-gray": theme.colors.CLight[8], // Color/Light/Contrast/C 8: #00000014
    "--hv-interaction-select-orange": theme.colors.BLight[0], // Color/Light/Brand/B 0: #F37321

    // Brand
    "--hv-brand-primary": theme.colors.BLight[0], // Color/Light/Brand/B 0: #F37321

    // Data
    "--hv-data-blue": theme.other.DataLight.blue, // Color/Light/Data/Blue: #5596E2
    "--hv-data-red": theme.other.DataLight.red, // Color/Light/Data/Red: #F65A5A
    "--hv-data-gray": theme.other.DataLight.gray, // Color/Light/Data/Gray: #747E87
    "--hv-data-green": theme.other.DataLight.green, // Color/Light/Data/Green: #8EC63F
    "--hv-data-yellow": theme.other.DataLight.yellow, // Color/Light/Data/Yellow: #FFA400
    "--hv-data-sapphire": theme.other.DataLight.sapphire, // Color/Light/Data/Sapphire: #556ED7
    "--hv-data-orange": theme.other.DataLight.orange, // Color/Light/Data/Orange: #F37321
    "--hv-data-emerald": theme.other.DataLight.emerald, // Color/Light/Data/Emerald: #56AB50
    "--hv-data-coral": theme.other.DataLight.coral, // Color/Light/Data/Coral: #FF816B
    "--hv-data-indigo": theme.other.DataLight.indigo, // Color/Light/Data/Indigo: #647AED
    "--hv-data-purple": theme.other.DataLight.purple, // Color/Light/Data/Purple: #9A57E5
    "--hv-data-violet": theme.other.DataLight.violet, // Color/Light/Data/Violet: #B95AE5
    "--hv-data-pink": theme.other.DataLight.pink, // Color/Light/Data/Pink: #FF598E

    // Primary Button
    "--hv-button-primary-default": theme.colors.BLight[0], // Light: Color/Light/Brand/B 0 #F37321
    "--hv-button-primary-hover": theme.colors.BLight[1], // Light: Color/Light/Brand/B 1 #F37321CC
    "--hv-button-primary-press": theme.colors.BLight[2], // Light: Color/Light/Brand/B 12 #F37321
    "--hv-button-primary-disabled": theme.colors.CLight[9], // Light: Color/Light/Contrast/C 9 #0000000A

    // Secondary Button
    "--hv-button-secondary-default": theme.colors.CLight[7], // Light: Color/Light/Contrast/C 7
    "--hv-button-secondary-hover": theme.colors.CLight[6], // Light: Color/Light/Contrast/C 6
    "--hv-button-secondary-press": theme.colors.CLight[5], // Light: Color/Light/Contrast/C 5
    "--hv-button-secondary-disabled": theme.colors.CLight[9], // Light: Color/Light/Contrast/C 9 #0000000A

    // Tertiary Button
    "--hv-button-tertiary-hover": theme.colors.CLight[9], // Light: Color/Light/Contrast/C 9 #0000000A
    "--hv-button-tertiary-press": theme.colors.CLight[8], // Light: Color/Light/Contrast/C 8 #00000014
    "--hv-button-tertiary-outline-default": theme.colors.CLight[6], // Light: Color/Light/Contrast/C 6 #00000021

    // Ghost Button
    "--hv-button-ghost-hover": theme.colors.CLight[9], // Light: Color/Light/Contrast/C 9 #0000000A
    "--hv-button-ghost-press": theme.colors.CLight[8], // Light: Color/Light/Contrast/C 8 #00000014
    "--hv-button-ghost-disabled": theme.colors.CLight[9], // Light: Color/Light/Contrast/C 9 #0000000A

    // Normal Icon
    "--hv-icon-normal-primary": theme.colors.CLight[0], // Light: Color/Light/Contrast/C 0 #000000
    "--hv-icon-normal-secondary": theme.colors.CLight[1], // Light: Color/Light/Contrast/C 1 #000000B2
    "--hv-icon-normal-tertiary": theme.colors.CLight[3], // Light: Color/Light/Contrast/C 3 #0000007C
    "--hv-icon-normal-disabled": theme.colors.CLight[5], // Light: Color/Light/Contrast/C 5 #0000004A

    // On-Brand Icon (not affected by color mode)
    "--hv-icon-on-brand-primary": theme.colors.CDark[0], // Light: Color/Light/Contrast/C 0 #000000
    "--hv-icon-on-brand-secondary": theme.colors.CDark[2], // Light: Color/Light/Contrast/C 2 #FFFFFF9B
    "--hv-icon-on-brand-tertiary": theme.colors.CDark[4], // Light: Color/Light/Contrast/C 4 #FFFFFF61
    "--hv-icon-on-brand-disabled": theme.colors.CLight[5], // Light: Color/Light/Contrast/C 5 #0000004A

    // Normal Text
    "--hv-text-normal-primary": theme.colors.CLight[0], // Light: Color/Light/Contrast/C 0 #000000
    "--hv-text-normal-secondary": theme.colors.CLight[1], // Light: Color/Light/Contrast/C 1 #000000C3
    "--hv-text-normal-tertiary": theme.colors.CLight[3], // Light: Color/Light/Contrast/C 3 #0000007D
    "--hv-text-normal-disabled": theme.colors.CLight[5], // Light: Color/Light/Contrast/C 5 #0000004A

    // On-Brand Text (not affected by color mode)
    "--hv-text-on-brand-primary": theme.colors.CDark[0], // Dark: Color/Dark/Contrast/C 0 #FFFFFF
    "--hv-text-on-brand-secondary": theme.colors.CDark[2], // Dark: Color/Dark/Contrast/C 2 #FFFFFF9B
    "--hv-text-on-brand-tertiary": theme.colors.CDark[4], // Dark: Color/Dark/Contrast/C 4 #FFFFFF61
    "--hv-text-on-brand-disabled": theme.colors.CLight[5], // Light: Color/Light/Contrast/C 5 #0000004A

    // Input Foreground Colors
    "--hv-input-foreground-default": theme.colors.CLight[0], // Light: Color/Light/Contrast/C 0 #000000
    "--hv-input-foreground-hover": theme.colors.CLight[9], // Light: Color/Light/Contrast/C 9 #000000C3
    "--hv-input-foreground-press": theme.colors.CLight[8], // Light: Color/Light/Contrast/C 8 #0000007D
    "--hv-input-foreground-disabled": theme.colors.CLight[9], // Light: Color/Light/Contrast/C 9 #0000004A

    // Input Outline Colors
    "--hv-input-outline-active": theme.colors.CLight[0], // Light: Color/Light/Contrast/C 0 #FFFFFF
    "--hv-input-outline-default": theme.colors.CLight[6], // Light: Color/Light/Contrast/C 6 #FFFFFF9B
    "--hv-input-outline-hover": theme.colors.CLight[5], // Light: Color/Light/Contrast/C 5 #FFFFFF61
    "--hv-input-outline-press": theme.colors.CLight[4], // Light: Color/Light/Contrast/C 4 #0000004A

    // Border Colors
    "--hv-border-default": theme.colors.CLight[8], // Light: Color/Light/Contrast/C 8 #00000014
    "--hv-border-emphasis": theme.colors.CLight[6], // Light: Color/Light/Contrast/C 6 #00000036

    // Scroll Colors
    "--hv-scroll-default": theme.colors.BGLight[7], // Light Mode: Color/Light/Contrast/C 7 #00000024
    "--hv-scroll-hover": theme.colors.BGLight[6], // Light Mode: Color/Light/Contrast/C 6 #00000036

    // Table Header Colors
    "--hv-table-header-default": theme.colors.BGLight[3], // Light Mode: Color/Light/Background/BG 3 #EEEFF1

    // Loading Colors
    "--hv-loading-circuit-ring-default": theme.colors.CLight[7], // Light Mode: Color/Light/Contrast/C 7 #00000024
    "--hv-loading-spinner-primary": theme.colors.BLight[0], // Light Mode: Color/Light/Brand/B 0 #F37321
    "--hv-loading-spinner-secondary": theme.colors.CLight[1], // Light Mode: Color/Light/Contrast/C 1 #000000C4

    // Alert colors
    "--hv-alert-text-default": theme.colors.CDark[0],
    "--hv-alert-icon-default": theme.colors.CDark[0],
    "--hv-alert-background-default": theme.colors.BGDark[0],

    // Snack Bar Colors
    "--hv-snack-bar-text-default": theme.colors.CDark[0], // Light Mode: Color/Dark/Contrast/C 0 #FFFFFF
    "--hv-snack-bar-icon-default": theme.colors.CDark[0], // Light Mode: Color/Dark/Contrast/C 0 #FFFFFF
    "--hv-snack-bar-icon-hover": theme.colors.CDark[9], // Light Mode: Color/Dark/Contrast/C 9 "#FFFFFF0A", // C 9 (4%)
    "--hv-snack-bar-icon-press": theme.colors.CDark[8], // Light Mode: Color/Dark/Contrast/C 8 "#FFFFFF14", // C 8 (8%)
    "--hv-snack-bar-background-default": theme.colors.BGDark[0], // Light Mode: Color/Dark/Background/BG 0 #000000

    // Tooltip Colors
    "--hv-tooltip-background": theme.colors.BGDark[6], // Dark Mode: Color/Dark/Background/BG 6 #303031

    // Dropdown colors
    "--hv-options-backgound-color": theme.colors.BGLight[0],
    "--hv-options-border-color": theme.colors.CLight[8],

    // Avatar Colors
    "--hv-avatar-background-primary-top": theme.colors.BLight[7], // Light Mode: Color/Light/Brand/B 7 #F3732124
    "--hv-avatar-background-primary-bottom": theme.colors.BGLight[0], // Light Mode: Color/Light/Background/BG 0 #FFFFFF
    "--hv-avatar-background-secondary-top": theme.colors.CLight[8], // Light Mode: Color/Light/Contrast/C 8 #00000014
    "--hv-avatar-background-secondary-bottom": theme.colors.BGLight[0], // Light Mode: Color/Light/Background/BG 0 #FFFFFF
    "--hv-avatar-text-primary": theme.colors.BLight[0], // Light Mode: Color/Light/Brand/B 0 #F37321
    "--hv-avatar-text-secondary": theme.colors.CLight[2], // Light Mode: Color/Light/Contrast/C 2 #0000009C

    // Toggle Colors
    "--hv-toggle-default": theme.colors.CLight[6], // Light Mode: Color/Light/Contrast/C 6 #F37321
    "--hv-toggle-selected-green": theme.other.DataLight.emerald, // Light Mode: Color/Light/Data/Emerald #56AB50
    "--hv-toggle-selected-orange": theme.colors.BLight[0], // Light Mode: Color/Light/Brand/B 0 #F37321
    "--hv-toggle-disabled": theme.colors.CLight[8], // Light Mode: Color/Light/Contrast/C 8 #EEEFF1
    "--hv-toggle-handle": theme.colors.BGLight[0], // Light Mode: Color/Light/Background/BG 0 #F37321

    // PI Logo Colors
    "--hv-pi-logo-orange": theme.colors.BLight[0], // Light Mode: #F37321
    "--hv-pi-logo-intersection": theme.colors.CLight[9], // Light Mode: #9B0000
    "--hv-pi-logo-gray": theme.colors.CLight[8], // Light Mode: #889199
    "--hv-pi-logo-left": theme.colors.CLight[9], // Light Mode: #A1A4A5
    "--hv-pi-logo-intersection-gray": theme.colors.CLight[5], // Light Mode: #515152
    "--hv-pi-logo-right": theme.colors.CLight[6], // Light Mode: #C9CDCF

    // Light Mode Shadows with Hex Alpha (Approximation)
    "--hv-shadow-1": `0px 2px 4px 0px #0000001F`, // Light Mode: Shadow/1 #000000 12% opacity
    "--hv-shadow-2": `0px 4px 8px 0px #0000001F`, // Light Mode: Shadow/2 #000000 12% opacity
    "--hv-shadow-3": `0px 12px 20px 0px #00000028`, // Light Mode: Shadow/3 #000000 16% opacity
    "--hv-shadow-4": `0px 16px 24px 0px #00000028`, // Light Mode: Shadow/4 #000000 16% opacity
    "--hv-shadow-5": `0px 24px 32px 0px #00000033`, // Light Mode: Shadow/5 #000000 20% opacity
  },
  dark: {
    // Surface
    "--hv-surface": theme.colors.BGDark[2], // Color/Dark/Background/BG 2: #101011
    "--hv-surface-widget": theme.colors.BGDark[4], // Color/Dark/Background/BG 4: #202021
    "--hv-surface-popup": theme.colors.BGDark[6], // Color/Dark/Background/BG 6: #303031
    "--hv-surface-popup-panel": theme.colors.BGDark[5], // Color/Dark/Background/BG 5: #282829

    // Interaction
    "--hv-interaction-hover": theme.colors.CDark[9], // Color/Dark/Contrast/C 9: #FFFFFF0A
    "--hv-interaction-press": theme.colors.CDark[8], // Color/Dark/Contrast/C 8: #FFFFFF14
    "--hv-interaction-select-gray": theme.colors.CDark[8], // Color/Dark/Contrast/C 8: #FFFFFF14
    "--hv-interaction-select-orange": theme.colors.BDark[0], // Color/Dark/Brand/B 0: #F37321

    // Brand
    "--hv-brand-primary": theme.colors.BDark[0], // Color/Dark/Brand/B 0: #F37321

    // Data
    "--hv-data-blue": theme.other.DataDark.blue, // Color/Dark/Data/Blue: #4891FF
    "--hv-data-red": theme.other.DataDark.red, // Color/Dark/Data/Red: #FA4D4D
    "--hv-data-gray": theme.other.DataDark.gray, // Color/Dark/Data/Gray: #56595B
    "--hv-data-green": theme.other.DataDark.green, // Color/Dark/Data/Green: #7DC519
    "--hv-data-yellow": theme.other.DataDark.yellow, // Color/Dark/Data/Yellow: #FFB400
    "--hv-data-sapphire": theme.other.DataDark.sapphire, // Color/Dark/Data/Sapphire: #5071F2
    "--hv-data-orange": theme.other.DataDark.orange, // Color/Dark/Data/Orange: #FF6D10
    "--hv-data-emerald": theme.other.DataDark.emerald, // Color/Dark/Data/Emerald: #00B960
    "--hv-data-coral": theme.other.DataDark.coral, // Color/Dark/Data/Coral: #FD6E55
    "--hv-data-indigo": theme.other.DataDark.indigo, // Color/Dark/Data/Indigo: #5A44EB
    "--hv-data-purple": theme.other.DataDark.purple, // Color/Dark/Data/Purple: #8333FC
    "--hv-data-violet": theme.other.DataDark.violet, // Color/Dark/Data/Violet: #B93CF0
    "--hv-data-pink": theme.other.DataDark.pink, // Color/Dark/Data/Pink: #FF5082

    // Primary Button
    "--hv-button-primary-default": theme.colors.BDark[0], // Dark: Color/Dark/Brand/B 0 #F37321
    "--hv-button-primary-hover": theme.colors.BDark[1], // Dark: Color/Dark/Brand/B 1 #F37321CC
    "--hv-button-primary-press": theme.colors.BDark[2], // Dark: Color/Dark/Brand/B 12 #F37321
    "--hv-button-primary-disabled": theme.colors.CDark[9], // Dark: Color/Dark/Contrast/C 9 #FFFFFF0A

    // Secondary Button
    "--hv-button-secondary-default": theme.colors.CDark[7], // Dark: Color/Dark/Contrast/C 7
    "--hv-button-secondary-hover": theme.colors.CDark[6], // Dark: Color/Dark/Contrast/C 6
    "--hv-button-secondary-press": theme.colors.CDark[5], // Dark: Color/Dark/Contrast/C 5
    "--hv-button-secondary-disabled": theme.colors.CDark[9], // Dark: Color/Dark/Contrast/C 9 #FFFFFF0A

    // Tertiary Button
    "--hv-button-tertiary-hover": theme.colors.CDark[9], // Dark: Color/Dark/Contrast/C 9 #FFFFFF0A
    "--hv-button-tertiary-press": theme.colors.CDark[8], // Dark: Color/Dark/Contrast/C 8 #FFFFFF14
    "--hv-button-tertiary-outline-default": theme.colors.CDark[7], // Dark: Color/Dark/Contrast/C 7 #FFFFFF14

    // Ghost Button
    "--hv-button-ghost-hover": theme.colors.CDark[9], // Dark: Color/Dark/Contrast/C 9 #FFFFFF0A
    "--hv-button-ghost-press": theme.colors.CDark[8], // Dark: Color/Dark/Contrast/C 8 #FFFFFF14
    "--hv-button-ghost-disabled": theme.colors.CDark[9], // Dark: Color/Dark/Contrast/C 9 #FFFFFF0A

    // Normal Icon
    "--hv-icon-normal-primary": theme.colors.CDark[0], // Dark: Color/Dark/Contrast/C 0 #FFFFFF
    "--hv-icon-normal-secondary": theme.colors.CDark[2], // Dark: Color/Dark/Contrast/C 2 #FFFFFF9B
    "--hv-icon-normal-tertiary": theme.colors.CDark[4], // Dark: Color/Dark/Contrast/C 4 #FFFFFF61
    "--hv-icon-normal-disabled": theme.colors.CDark[6], // Dark: Color/Dark/Contrast/C 6 #FFFFFF35

    // On-Brand Icon (not affected by color mode)
    "--hv-icon-on-brand-primary": theme.colors.CDark[0], // Dark: Color/Dark/Contrast/C 0 #FFFFFF
    "--hv-icon-on-brand-secondary": theme.colors.CDark[2], // Dark: Color/Dark/Contrast/C 2 #FFFFFF9B
    "--hv-icon-on-brand-tertiary": theme.colors.CDark[4], // Dark: Color/Dark/Contrast/C 4 #FFFFFF61
    "--hv-icon-on-brand-disabled": theme.colors.CDark[6], // Dark: Color/Dark/Contrast/C 6 #FFFFFF35

    // Normal Text
    "--hv-text-normal-primary": theme.colors.CDark[0], // Dark: Color/Dark/Contrast/C 0 #FFFFFF
    "--hv-text-normal-secondary": theme.colors.CDark[2], // Dark: Color/Dark/Contrast/C 2 #FFFFFF9B
    "--hv-text-normal-tertiary": theme.colors.CDark[4], // Dark: Color/Dark/Contrast/C 4 #FFFFFF61
    "--hv-text-normal-disabled": theme.colors.CDark[6], // Dark: Color/Dark/Contrast/C 6 #FFFFFF35

    // On-Brand Text (not affected by color mode)
    "--hv-text-on-brand-primary": theme.colors.CDark[0], // Dark: Color/Dark/Contrast/C 0 #FFFFFF
    "--hv-text-on-brand-secondary": theme.colors.CDark[2], // Dark: Color/Dark/Contrast/C 2 #FFFFFF9B
    "--hv-text-on-brand-tertiary": theme.colors.CDark[4], // Dark: Color/Dark/Contrast/C 4 #FFFFFF61
    "--hv-text-on-brand-disabled": theme.colors.CDark[6], // Dark: Color/Dark/Contrast/C 6 #FFFFFF35

    // Input Foreground Colors
    "--hv-input-foreground-default": theme.colors.CDark[9], // Dark: Color/Dark/Contrast/C 9 #FFFFFF
    "--hv-input-foreground-hover": theme.colors.CDark[8], // Dark: Color/Dark/Contrast/C 8 #FFFFFF9B
    "--hv-input-foreground-press": theme.colors.CDark[7], // Dark: Color/Dark/Contrast/C 7 #FFFFFF61
    "--hv-input-foreground-disabled": theme.colors.CDark[9], // Dark: Color/Dark/Contrast/C 9 #FFFFFF35

    // Input Outline Colors
    "--hv-input-outline-active": theme.colors.CDark[0], // Dark: Color/Dark/Contrast/C 0 #FFFFFF
    "--hv-input-outline-default": theme.colors.CDark[7], // Dark: Color/Dark/Contrast/C 7 #FFFFFF9B
    "--hv-input-outline-hover": theme.colors.CDark[6], // Dark: Color/Dark/Contrast/C 6 #FFFFFF61
    "--hv-input-outline-press": theme.colors.CDark[5], // Dark: Color/Dark/Contrast/C 5 #FFFFFF35

    // Border Colors
    "--hv-border-default": theme.colors.CDark[8], // Dark: Color/Dark/Contrast/C 8 #FFFFFF14
    "--hv-border-emphasis": theme.colors.CDark[6], // Light: Color/Dark/Contrast/C 6 #FFFFFF36

    // Scroll Colors
    "--hv-scroll-default": theme.colors.BGDark[7], // Dark Mode: Color/Dark/Contrast/C 7 #FFFFFF24
    "--hv-scroll-hover": theme.colors.BGDark[6], // Dark Mode: Color/Dark/Contrast/C 6 #FFFFFF36

    // Table Header Colors
    "--hv-table-header-default": theme.colors.BGDark[5], // Dark Mode: Color/Dark/Background/BG 5 #282829

    // Loading Colors
    "--hv-loading-circuit-ring-default": theme.colors.CDark[7], // Dark Mode: Color/Dark/Contrast/C 7 #FFFFFF24
    "--hv-loading-spinner-primary": theme.colors.BDark[0], // Dark Mode: Color/Dark/Brand/B 0 #F37321
    "--hv-loading-spinner-secondary": theme.colors.CDark[2], // Dark Mode: Color/Dark/Contrast/C 2 #FFFFFF9C

    // Alert colors
    "--hv-alert-text-default": theme.colors.CLight[0],
    "--hv-alert-icon-default": theme.colors.CLight[0],
    "--hv-alert-background-default": theme.colors.BGLight[0],

    // Snack Bar Colors
    "--hv-snack-bar-text-default": theme.colors.CLight[0], // Dark Mode: Color/Light/Contrast/C 0 #000000
    "--hv-snack-bar-icon-default": theme.colors.CLight[0], // Dark Mode: Color/Light/Contrast/C 0 #000000
    "--hv-snack-bar-icon-hover": theme.colors.CLight[9], // Dark Mode: Color/Light/Contrast/C 9 "#0000000A", // C 9 (4%)
    "--hv-snack-bar-icon-press": theme.colors.CLight[8], // Dark Mode: Color/CLight/Contrast/C 8 "#00000014", // C 8 (8%)
    "--hv-snack-bar-background-default": theme.colors.BGLight[0], // Dark Mode: Color/Light/Background/BG 0 #FFFFFF

    // Tooltip Colors
    "--hv-tooltip-background": theme.colors.BGDark[6], // Dark Mode: Color/Dark/Background/BG 6 #303031

    // Dropdown colors
    "--hv-options-backgound-color": theme.colors.BGDark[6],
    "--hv-options-border-color": theme.colors.CDark[8],

    // Avatar Colors
    "--hv-avatar-background-primary-top": theme.colors.BDark[7], // Dark Mode: Color/Dark/Brand/B 7 #F3732124
    "--hv-avatar-background-primary-bottom": theme.colors.BGDark[0], // Dark Mode: Color/Dark/Background/BG 0 #000000
    "--hv-avatar-background-secondary-top": theme.colors.CDark[6], // Dark Mode: Color/Dark/Contrast/C 6 #FFFFFF36
    "--hv-avatar-background-secondary-bottom": theme.colors.BGDark[0], // Dark Mode: Color/Dark/Background/BG 0 #000000
    "--hv-avatar-text-primary": theme.colors.BDark[0], // Dark Mode: Color/Dark/Brand/B 0 #F37321
    "--hv-avatar-text-secondary": theme.colors.CDark[2], // Dark Mode: Color/Dark/Contrast/C 2 #FFFFFF9C

    // Toggle Colors
    "--hv-toggle-default": theme.colors.CDark[6], // Dark Mode: Color/Dark/Contrast/C 6 #FFFFFF36
    "--hv-toggle-selected-green": theme.other.DataDark.emerald, // Dark Mode: Color/Dark/Data/Emerald #00B960
    "--hv-toggle-selected-orange": theme.colors.BDark[0], // Dark Mode: Color/Dark/Brand/B 0 #F37321
    "--hv-toggle-disabled": theme.colors.CDark[8], // Dark Mode: Color/Dark/Contrast/C 8 #FFFFFF14
    "--hv-toggle-handle": theme.colors.CDark[0], // Dark Mode: Color/Dark/Contrast/C 0 #FFFFFF

    // PI Logo Colors
    "--hv-pi-logo-orange": theme.colors.BDark[0], // Dark Mode: #F37321
    "--hv-pi-logo-intersection": theme.colors.CDark[9], // Dark Mode: #9B0000
    "--hv-pi-logo-gray": theme.colors.CDark[8], // Dark Mode: #889199
    "--hv-pi-logo-left": theme.colors.CDark[9], // Dark Mode: #A1A4A5
    "--hv-pi-logo-intersection-gray": theme.colors.CDark[5], // Dark Mode: #515152
    "--hv-pi-logo-right": theme.colors.CDark[6], // Dark Mode: #C9CDCF

    // Dark Mode Shadows with Hex Alpha (Approximation)
    "--hv-shadow-1": `0px 2px 4px 0px #00000052`, // Dark Mode: Shadow/1 #000000 32% opacity
    "--hv-shadow-2": `0px 4px 8px 0px #00000066`, // Dark Mode: Shadow/2 #000000 40% opacity
    "--hv-shadow-3": `0px 12px 20px 0px #0000007A`, // Dark Mode: Shadow/3 #000000 48% opacity
    "--hv-shadow-4": `0px 16px 24px 0px #0000008F`, // Dark Mode: Shadow/4 #000000 56% opacity
    "--hv-shadow-5": `0px 24px 32px 0px #000000B8`, // Dark Mode: Shadow/5 #000000 72% opacity
  },
});
