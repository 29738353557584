"use client";
import {
  HVDatePickerProps,
  SearchComparisonDateTime,
} from "./HVDatePicker.props";
import ScrollBar from "./ScrollBar/ScrollBar";
import { Calendar } from "./Calendar/Calendar";
import TimePicker from "./TimePicker/TimePicker";
import styles from "./HVDatePicker.module.css";
import "./DatePicker.css";
import { HVButton, HVFlex } from "../";
import { CALENDAR } from "./resources/constants/constants";
import { toDateWithTimezone } from "./resources/utils/utils";
import useDatePicker from "./useDatePicker";

const HVDatePicker = ({
  dateTime,
  setDateTime,
  availableModeList,
  showTimePicker = false,
  disabled = false,
  tabIndex,
  rangeSelectLimit = 90,
  usePreview = false,
  showModePicker = true,
  setShowDatePicker,
}: HVDatePickerProps<SearchComparisonDateTime>) => {
  const {
    onChangeDate,
    scrollBarChildren,
    onClickSubmit,
    timePicker,
    reRender,
    maxDate,
    selectedMode,
    selectedStart,
    selectedEnd,
    selectedTempDateTime,
    currentTimeZone,
    onClickOutside,
    formatMinuteSecond,
  } = useDatePicker({
    dateTime,
    setDateTime,
    availableModeList,
    showTimePicker,
    disabled,
    tabIndex,
    rangeSelectLimit,
    usePreview,
  });

  return (
    <div className={styles["date-range-popup"]}>
      <HVFlex direction={"row"}>
        {showModePicker && (
          <div className={styles["date-range-popup-sidebar"]}>
            <ScrollBar>{scrollBarChildren!()}</ScrollBar>
          </div>
        )}

        <HVFlex direction={"column"} className={styles["date-range-calendar"]}>
          <Calendar
            // to re-render calendar when user change mode to get focus on that month and date
            key={reRender!.toString()}
            inline
            selectsRange
            showPreviousMonths
            focusSelectedMonth
            today={new Date(toDateWithTimezone(currentTimeZone!, new Date()))}
            selected={
              new Date(
                toDateWithTimezone(currentTimeZone!, new Date(selectedStart!)),
              )
            }
            startDate={
              new Date(
                toDateWithTimezone(currentTimeZone!, new Date(selectedStart!)),
              )
            }
            endDate={
              selectedEnd
                ? new Date(
                    toDateWithTimezone(currentTimeZone!, new Date(selectedEnd)),
                  )
                : undefined
            }
            maxDate={
              new Date(
                toDateWithTimezone(
                  currentTimeZone!,
                  new Date(maxDate!.toISOString()),
                ),
              )
            }
            onChange={onChangeDate}
            onClickOutside={onClickOutside}
            {...(rangeSelectLimit
              ? {
                  minDate: new Date(
                    toDateWithTimezone(
                      currentTimeZone!,
                      new Date(
                        new Date().getTime() -
                          CALENDAR.MAX_SELECTABLE_DAY * 24 * 60 * 60 * 1000,
                      ),
                    ),
                  ),
                }
              : {})}
          />

          {showTimePicker && (
            <TimePicker
              timePicker={timePicker!}
              selectedMode={selectedMode!}
              selectedTempDateTime={selectedTempDateTime!}
              formatMinuteSecond={formatMinuteSecond!}
            />
          )}

          <div className={styles["button-submit"]}>
            <HVButton
              variant="Primary"
              onClick={() => {
                onClickSubmit();
                setShowDatePicker?.(false);
              }}
            >
              {"OK"}
            </HVButton>
          </div>
        </HVFlex>
      </HVFlex>
    </div>
  );
};

export default HVDatePicker;
