import { useEffect, useState, useRef } from "react";
import { IconSend, IconArrowDownLine } from "@resources/icons/Icons";
import _, { throttle } from "lodash";
import { useCurrentTimezone } from "@hooks/useCurrentTimezone";
import { AnalyticsPromptDto } from "@open-api";
import { HVButton, HVIcon } from "@repo/HVComponents";
import { useGetAiInsight } from "@queries/tanstack/ai-controller/AiController";
import * as S from "./GenerativeAI.styled";
import { Message } from "../message/Message";
import { Card } from "../card/Card";
import { ICON } from "../../../constants/icons";

interface GeneratvieAIProps {
  title: string;
  isShow: boolean;
  searchFilter?: {
    baseTime: { start: string; end: string } | undefined;
  };
  ruleGroup?: {
    [p: string]: _.Dictionary<
      {
        locationId: string;
        locationName: string;
        deviceId: string;
        ruleName: string;
        channel: number;
        ruleType: string;
      }[]
    >;
  };
  onChangeInsightChat: () => void;
}
export const GeneratvieAI = (props: GeneratvieAIProps) => {
  const { title, isShow, searchFilter, ruleGroup, onChangeInsightChat } = props;
  const [message, setMessage] = useState("");
  const [list, setList] = useState<
    Array<{ role: string; message: string; state: string }>
  >([]);
  const [isComposing, setIsComposing] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const { convertByTimezone } = useCurrentTimezone();
  const { mutateAsync: mutateChat, isLoading } = useGetAiInsight();
  const chatRef = useRef<HTMLDivElement>(null);
  const scrollHandlerRef = useRef<(() => void) | null>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!isShow) {
      setMessage("");
      setList([]);
      setShowScrollButton(false);
    }
  }, [isShow]);
  useEffect(() => {
    if (isLoading) {
      const loadingItem = {
        role: "assistant",
        message: "",
        state: "loading"
      };
      setList((prev) => [...prev, loadingItem]);
    }
  }, [isLoading]);

  useEffect(() => {
    scrollHandlerRef.current = throttle(() => {
      if (chatRef.current) {
        const { scrollTop, clientHeight, scrollHeight } = chatRef.current;

        const atBottom = scrollTop + clientHeight < scrollHeight - 10;
        setShowScrollButton(atBottom);
      }
    }, 300);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const chatEl = chatRef.current;
    const handleScroll = () => scrollHandlerRef.current?.();

    chatEl?.addEventListener("scroll", handleScroll);

    return () => {
      chatEl?.removeEventListener("scroll", handleScroll);
    };
  }, [list]);

  const scrollToBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTo({
        top: chatRef.current.scrollHeight,
        behavior: "smooth"
      });
    }
  };

  const autoResize = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const target = e.target as HTMLTextAreaElement;
    target.style.height = "auto";
    target.style.height =
      target.scrollHeight >= 192 ? "192px" : `${target.scrollHeight}px`;
    setMessage(target.value);
  };
  const handleSubmit = (msg: string, boolean: boolean) => {
    // 필터링 boolean타입이 true면 스킵, false면 필터링 추가
    if (isLoading || !msg.trim()) return;
    setList((prev) => [
      ...prev,
      { role: "user", message: msg.trim(), state: "done" }
    ]);
    setMessage("");
    mutateChat({
      start: convertByTimezone(searchFilter?.baseTime?.start as string),
      end: convertByTimezone(searchFilter?.baseTime?.end as string),
      body: {
        rules: ruleGroup?.PEOPLE_COUNT,
        prompt: {
          text: msg.trim(),
          skipValidation: boolean
        }
      } as AnalyticsPromptDto
    }).then((r) => {
      if (r) {
        setList((prev) =>
          prev.map((item) =>
            item.state === "loading" && item.role === "assistant"
              ? {
                  role: r.message.role,
                  message: r.message.content[0].text,
                  state: "done"
                }
              : item
          )
        );
      } else {
        setList((prev) =>
          prev.map((item) =>
            item.state === "loading" && item.role === "assistant"
              ? {
                  role: "assistant",
                  message:
                    "Sorry. Please ask a question related to chart analysis.",
                  state: "done"
                }
              : item
          )
        );
      }
      if (inputRef.current) {
        inputRef.current.focus();
      }
    });
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      if (isComposing) return;
      event.preventDefault(); // 줄바꿈 방지
      handleSubmit(message, false);
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!e.target.value) return;
    setMessage(e.target.value);
  };

  const onSendChat = (msg: string) => {
    handleSubmit(msg, true); // flag true is skip
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const onReset = () => {
    if (!list.some((item) => item.state === "loading")) {
      setMessage("");
      setList([]);
      setShowScrollButton(false);
    }
  };
  const onClose = () => {
    if (!list.some((item) => item.state === "loading")) {
      onChangeInsightChat();
    }
  };

  return (
    <S.Container>
      <S.GenHeader>
        <S.GenTitle>{title}</S.GenTitle>
        <HVButton
          variant="Ghost"
          w={32}
          h={32}
          p={0}
          data-testid="generativeAI-btn-reset"
          aria-label="Reset"
          onClick={onReset}
        >
          <HVIcon name="refresh" svgProps={{ ...ICON.SIZE_24 }} />
        </HVButton>
        <HVButton
          variant="Ghost"
          w={32}
          h={32}
          p={0}
          data-testid="generativeAI-btn-close"
          aria-label="Close"
          onClick={onClose}
        >
          <HVIcon name="close" svgProps={{ ...ICON.SIZE_24 }} />
        </HVButton>
      </S.GenHeader>
      <S.GenContent id="chat-container" ref={chatRef}>
        {list && list.length > 0 ? (
          list.map((item) => {
            return (
              <Message
                own={item.role === "user"}
                message={item.message}
                state={item.state}
              />
            );
          })
        ) : (
          <>
            <S.ContentTitle>
              Ask questions about <span>{title}</span>
            </S.ContentTitle>
            <S.CardContainer>
              <Card
                title="Visitor trend analysis"
                subtitle="Is there a consistent increase or decrease in the number of visitors during a specific period?"
                onSendChat={onSendChat}
              />
              <Card
                title="Peak traffic anaysis"
                subtitle="What are the busiest times and days for each location?"
                onSendChat={onSendChat}
              />
              <Card
                title="Trend prediction"
                subtitle="Can you predict the number of visitors for the next few weeks?"
                onSendChat={onSendChat}
              />
            </S.CardContainer>
          </>
        )}
      </S.GenContent>
      <S.ChatFooter
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(message, false);
        }}
      >
        <S.ChatInput
          ref={inputRef}
          placeholder="Message..."
          className="message-input"
          maxLength={500}
          rows={1}
          value={message}
          onInput={autoResize}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onCompositionStart={() => setIsComposing(true)}
          onCompositionEnd={() => setIsComposing(false)}
        />
        <S.SendIcon active={message.trim().length > 0} disabled={isLoading}>
          <IconSend />
        </S.SendIcon>
      </S.ChatFooter>
      {showScrollButton && (
        <S.ScrollButton onClick={scrollToBottom}>
          <IconArrowDownLine width={24} height={24} />
        </S.ScrollButton>
      )}
    </S.Container>
  );
};
