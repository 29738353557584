export const BUTTON_STYLE_TYPE = {
    FIRST: "first",
    SECOND: "second",
    THIRD: "third",
    FOURTH: "fourth",
    NORULE: "norule",
    RULE: "rule",
  } as const;
  
  export const CALENDAR = {
    MAX_SELECTABLE_DAY: 1824, // max selectable days
  };
  
  export const CALENDAR_MODE_RANGE_TYPE = {
    TODAY: "today",
    CUSTOM: "custom",
    LAST_24_HOURS: "last_24_hours",
    LAST_7_DAYS: "last_7_days",
    LAST_30_DAYS: "last_30_days",
    LAST_90_DAYS: "last_90_days",
    LAST_180_DAYS: "last_180_days",
    LAST_365_DAYS: "last_365_days",
    YESTERDAY: "yesterday",
    DAY_BEFORE_YESTERDAY: "day_before_yesterday",
  } as const;
  
  // Format
  export const FORMAT = {
    FULL_YEAR: "YYYY", //  2024
    TIME: "HH:mm:ss", //  14:25:36
    TIME_1: "HH:mm", //  14:25
    DATE: "MM-DD-YYYY", //  03-15-2024
    DATE2: "MM/DD/YYYY", // 03/15/2024
    DATE3: "MM/DD", //  03/15
    DATE4: "MM/DD/YYYY", // 03/15/2024
    DATE5: "MMM DD", // Mar 15
    DATE_MONTH: "YYYY-MMMM-DD", //  2024-April-26
    DATE_MONTH_TYPE: "MM/DD/YYYY", // 03/15/2024
    DATE_TIME_12H: "MM/DD/YYYY A hh:mm", // 03/15/2024 PM 02:30
    DATE_TIME_12H_FULL: "MM/DD/YYYY A hh:mm:ss", // 03/15/2024 PM 02:30:45
    DATE_TIME_12H_1: "MMM D, YYYY hh:mm A", // Apr 25, 2024 03:30 PM
    DATE_TIME_24H: "MM/DD/YYYY HH:mm", //   03/15/2024 14:30
    DATE_TIME2_24H: "MM/DD HH:mm", //   03/15 14:30
    DATE_TIME_24H_1: "YYYY-MM-DD HH:mm:ss", // 2024-01-01 14:30:45
    TIME_12H: "A hh:mm:ss", //  PM 02:30:45
    TIME_12H_1: "A hh:mm", //  PM 02:30
    TIME_12H_2: "hh:mm A", //   02:30 PM
    DATE_TIME_ISO: "YYYY-MM-DD[T]HH:mm:ssZ", //   2024-04-26T15:30:45Z
    MONTH_DATE_YEAR: "MMM/DD/yyyy", // Mar/15/2024
    MONTH_DATE_YEAR_1: "MMM DD, yyy", // Mar 15, 2024
    TIM12: "hh:mm:ss A", // 02:30:45 PM
    MONTH_DATE_YEAR_TIME: "MMM DD, yyyy hh:mm:ss A", //  Apr 25, 2024 03:30:15 PM
    MONTH_DATE_YEAR_TIME_2: "MMM DD, yyyy hh:mm A", //  Apr 25, 2024 03:30 PM
    MONTH_DATE_YEAR_TIME_3: "MMM DD, YYYY h:mm A", //  Apr 25, 2024 3:30 PM
    MONTH_DATE_TIME: "MMM DD, h:mm A", //  Apr 25, 3:30 PM
    DATE_MONTH_YEAR_TIME: "d MMM,YYYY A hh:mm", // 25 Apr, 2024 PM 03:30
    DATE_MONTH_YEAR: "D MMM, YYYY", // 25 Apr, 2024
  } as const;
  
  export const SESSION_STORAGE_KEY = {
    ACTIVE_DASHBOARD_ID: "ActiveDashboardID",
    SAVE_DEVICE_LOGIN_INFO: "SaveDeviceLoginInfo",
    ORG_ID: "org_id",
    TIME_ZONE: "TimeZone",
  } as const;
  
  export const LANG = {
    EN: "En",
    KO: "Ko",
  } as const;
  
  export const MONTH = {
    JAN: "January",
    FEB: "February",
    MAR: "March",
    APR: "April",
    MAY: "May",
    JUN: "June",
    JUL: "July",
    AUG: "August",
    SEP: "September",
    OCT: "October",
    NOV: "November",
    DEC: "December",
  } as const;
  